import { Action } from '@ngrx/store';
import { APIFindOptions, ApplicationLog, ApiQueryResponse } from '@doe/types';
import {
  DeleteAction,
  DeleteSelectedAction,
  DeleteSuccessAction,
  LoadAction,
  LoadSingleAction,
  LoadSingleSuccessAction,
  LoadSuccessAction,
  ResetSavedAction,
  SaveAction,
  SelectAction,
  StateErrorAction,
  ResetDeletedAction
} from '../../abstract/actions';

export enum AppLogsActionTypes {
  LoadAppLogs = '[AppLogs] Load AppLogs',
  LoadAppLogsSuccess = '[AppLogs] AppLogs Loaded',
  AppLogsError = '[AppLogs] AppLogs Load Error',
  LoadSingleAppLog = '[AppLogs] Load single AppLog',
  LoadSingleAppLogsuccess = '[AppLogs] Load single AppLog success',
  SaveAppLog = '[Proceses] Save AppLog',
  SaveAppLogsuccess = '[Proceses] Save AppLog success',
  SelectAppLogs = '[AppLogs] Select AppLogs',
  DeleteSelectedAppLogs = '[AppLogs] Delete selected',
  DeleteSelectedAppLogsSuccess = '[AppLogs] Delete selected success',
  DeleteAppLogs = '[AppLogs] Delete AppLogs based on conditions',
  DeleteAppLogsSuccess = '[AppLogs] Delete AppLogs success',
  ResetSavedAppLog = '[AppLogs] Reset saved state',
  ResetDeletedAppLog = '[AppLogs] Reset deleted state'
}

export class AppLogsError extends StateErrorAction {
  readonly type = AppLogsActionTypes.AppLogsError;
}

export class LoadAppLogs extends LoadAction {
  readonly type = AppLogsActionTypes.LoadAppLogs;
}

export class LoadAppLogsSuccess extends LoadSuccessAction<ApplicationLog> {
  readonly type = AppLogsActionTypes.LoadAppLogsSuccess;
}

export class LoadSingleAppLog extends LoadSingleAction<ApplicationLog> {
  readonly type = AppLogsActionTypes.LoadSingleAppLog;
}

export class LoadSingleAppLogsuccess extends LoadSingleSuccessAction<
  ApplicationLog
> {
  readonly type = AppLogsActionTypes.LoadSingleAppLogsuccess;
}

export class SaveAppLog extends SaveAction<ApplicationLog> {
  readonly type = AppLogsActionTypes.SaveAppLog;
}

export class SaveAppLogsuccess extends SaveAction<ApplicationLog> {
  readonly type = AppLogsActionTypes.SaveAppLogsuccess;
}

export class SelectAppLogs extends SelectAction<ApplicationLog> {
  readonly type = AppLogsActionTypes.SelectAppLogs;
}

export class DeleteSelectedAppLogs extends DeleteSelectedAction {
  readonly type = AppLogsActionTypes.DeleteSelectedAppLogs;
}

export class DeleteAppLogs extends DeleteAction {
  readonly type = AppLogsActionTypes.DeleteAppLogs;
}

export class DeleteAppLogsSuccess extends DeleteSuccessAction {
  readonly type = AppLogsActionTypes.DeleteAppLogsSuccess;
}

export class ResetSavedAppLog extends ResetSavedAction {
  readonly type = AppLogsActionTypes.ResetSavedAppLog;
}

export class ResetDeletedAppLog extends ResetDeletedAction {
  readonly type = AppLogsActionTypes.ResetDeletedAppLog;
}

export type AppLogsAction =
  | LoadAppLogs
  | LoadAppLogsSuccess
  | AppLogsError
  | LoadSingleAppLog
  | LoadSingleAppLogsuccess
  | SaveAppLog
  | SaveAppLogsuccess
  | SelectAppLogs
  | DeleteSelectedAppLogs
  | DeleteAppLogs
  | DeleteAppLogsSuccess
  | ResetSavedAppLog
  | ResetDeletedAppLog
  | ResetSavedAppLog;

export const fromAppLogsActions = {
  LoadAppLogs,
  LoadAppLogsSuccess,
  AppLogsError,
  LoadSingleAppLog,
  LoadSingleAppLogsuccess,
  SaveAppLog,
  SaveAppLogsuccess,
  SelectAppLogs,
  DeleteSelectedAppLogs,
  DeleteAppLogs,
  DeleteAppLogsSuccess,
  ResetSavedAppLog,
  ResetDeletedAppLog
};
