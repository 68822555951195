import { HttpClient } from '@angular/common/http';
import { Company } from '@doe/types';

import { DAOService } from '../dao-service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService extends DAOService<Company> {
  constructor(http: HttpClient) {
    super(http, '/api/companies');
  }
}
