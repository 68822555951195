import { Injectable } from '@angular/core';
import { NgrxFacadeDAO } from '@doe/client/dao';
import { APIFindOptions, ApiQueryResponse, Process } from '@doe/types';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import {
  DeleteProcesses,
  DeleteSelectedProcesses,
  LoadProcesses,
  LoadSingleProcess,
  SaveProcess,
  SelectProcesses
} from './processes.actions';
import { ProcessesPartialState } from './processes.reducer';
import { processesQuery } from './processes.selectors';

@Injectable()
export class ProcessesFacade implements NgrxFacadeDAO<Process> {
  constructor(private store: Store<ProcessesPartialState>) {}

  results$: Observable<ApiQueryResponse<Process>> = this.store.select(
    processesQuery.featureSelector
  );
  loaded$: Observable<boolean> = this.store.select(processesQuery.getLoaded);
  single$?: Observable<Process> = this.store.select(processesQuery.getSingle);
  singleLoaded$?: Observable<boolean> = this.store.select(
    processesQuery.getSingleLoaded
  );
  error$?: Observable<any> = this.store.select(processesQuery.getError);
  deleteSelected$?: Observable<any> = this.store.select(
    processesQuery.getDeleted
  );
  selected$: Observable<Process[]> = this.store.select(
    processesQuery.getSelected
  );
  saved$: Observable<boolean> = this.store.select(processesQuery.getSaved);

  load(conditions?: any, options?: APIFindOptions) {
    this.store.dispatch(new LoadProcesses({ conditions, options }));
  }

  loadSingle(conditions: any) {
    this.store.dispatch(new LoadSingleProcess({ conditions }));
  }

  save(data: Process) {
    this.store.dispatch(new SaveProcess(data));
  }

  delete(id: any) {
    this.store.dispatch(new DeleteProcesses({ id }));
  }

  deleteSelected() {
    this.store.dispatch(new DeleteSelectedProcesses());
  }

  setSelected(selected: Process[]) {
    this.store.dispatch(new SelectProcesses(selected));
  }
}
