import {
  DeleteAction,
  DeleteSelectedAction,
  DeleteSuccessAction,
  LoadAction,
  LoadSingleAction,
  LoadSingleSuccessAction,
  LoadSuccessAction,
  ResetSavedAction,
  SaveAction,
  SelectAction,
  StateErrorAction,
  ResetDeletedAction
} from '../../abstract/actions';
import { Catalog } from '@doe/types';

export enum CatalogsActionTypes {
  LoadCatalogs = '[Catalogs] Load Catalogs',
  LoadCatalogsSuccess = '[Catalogs] Catalogs Loaded',
  CatalogsError = '[Catalogs] Catalogs Load Error',
  LoadSingleCatalog = '[Catalogs] Load single Catalog',
  LoadSingleCatalogSuccess = '[Catalogs] Load single Catalog success',
  SaveCatalog = '[Proceses] Save Catalog',
  SaveCatalogSuccess = '[Proceses] Save Catalog success',
  SelectCatalogs = '[Catalogs] Select Catalogs',
  DeleteSelectedCatalogs = '[Catalogs] Delete selected',
  DeleteCatalogs = '[Catalogs] Delete Catalogs based on conditions',
  DeleteCatalogsSuccess = '[Catalogs] Delete Catalogs success',
  ResetSavedCatalog = '[Catalogs] Reset saved state',
  ResetDeletedCatalog = '[Catalogs] Reset deleted state'
}

export class CatalogsError extends StateErrorAction {
  readonly type = CatalogsActionTypes.CatalogsError;
}

export class LoadCatalogs extends LoadAction {
  readonly type = CatalogsActionTypes.LoadCatalogs;
}

export class LoadCatalogsSuccess extends LoadSuccessAction<Catalog> {
  readonly type = CatalogsActionTypes.LoadCatalogsSuccess;
}

export class LoadSingleCatalog extends LoadSingleAction<Catalog> {
  readonly type = CatalogsActionTypes.LoadSingleCatalog;
}

export class LoadSingleCatalogSuccess extends LoadSingleSuccessAction<Catalog> {
  readonly type = CatalogsActionTypes.LoadSingleCatalogSuccess;
}

export class SaveCatalog extends SaveAction<Catalog> {
  readonly type = CatalogsActionTypes.SaveCatalog;
}

export class SaveCatalogSuccess extends SaveAction<Catalog> {
  readonly type = CatalogsActionTypes.SaveCatalogSuccess;
}

export class SelectCatalogs extends SelectAction<Catalog> {
  readonly type = CatalogsActionTypes.SelectCatalogs;
}

export class DeleteSelectedCatalogs extends DeleteSelectedAction {
  readonly type = CatalogsActionTypes.DeleteSelectedCatalogs;
}

export class DeleteCatalogs extends DeleteAction {
  readonly type = CatalogsActionTypes.DeleteCatalogs;
}

export class DeleteCatalogsSuccess extends DeleteSuccessAction {
  readonly type = CatalogsActionTypes.DeleteCatalogsSuccess;
}

export class ResetSavedCatalog extends ResetSavedAction {
  readonly type = CatalogsActionTypes.ResetSavedCatalog;
}

export class ResetDeletedCatalog extends ResetDeletedAction {
  readonly type = CatalogsActionTypes.ResetDeletedCatalog;
}

export type CatalogsAction =
  | LoadCatalogs
  | LoadCatalogsSuccess
  | CatalogsError
  | LoadSingleCatalog
  | LoadSingleCatalogSuccess
  | SaveCatalog
  | SaveCatalogSuccess
  | SelectCatalogs
  | DeleteSelectedCatalogs
  | DeleteCatalogs
  | DeleteCatalogsSuccess
  | ResetSavedCatalog
  | ResetDeletedCatalog;

export const fromCatalogsActions = {
  LoadCatalogs,
  LoadCatalogsSuccess,
  CatalogsError,
  LoadSingleCatalog,
  LoadSingleCatalogSuccess,
  SaveCatalog,
  SaveCatalogSuccess,
  SelectCatalogs,
  DeleteSelectedCatalogs,
  DeleteCatalogs,
  DeleteCatalogsSuccess,
  ResetSavedCatalog,
  ResetDeletedCatalog
};
