import { ApplicationLog } from '@doe/types';

import { NgrxFacadeDAO } from '../../../ngrx-facade-dao.interface';
import { NgrxFacadeDataSource } from './ngrx-facade-dao-data-source';

export class AppLogsNgrxDataSource extends NgrxFacadeDataSource<
  ApplicationLog
> {
  constructor(facade: NgrxFacadeDAO<ApplicationLog>) {
    super(facade);
  }
}
