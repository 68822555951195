import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatSelectModule
} from '@angular/material';
import { AuthFeatureStoreModule, NgrxCustomersModule } from '@doe/client/ngrx';
import { TranslateModule } from '@ngx-translate/core';
import { SignaturePadModule } from 'angular2-signaturepad';
import { CountriesModule } from '../../countries/countries.module';

import { DynamicFieldsModule } from '../dynamic-fields/dynamic-fields.module';
import { FormDefaultComponent } from './form-default/form-default.component';
import { FormRendererComponent } from './form-renderer/form-renderer.component';
import { FormSimpleComponent } from './form-simple/form-simple.component';
import { FlexLayoutModule } from '@angular/flex-layout';

const MATERIAL_MODULES = [
  MatCardModule,
  MatGridListModule,
  MatButtonModule,
  MatInputModule,
  MatFormFieldModule,
  MatCheckboxModule,
  MatMenuModule,
  MatSelectModule,
  MatIconModule
];

@NgModule({
  declarations: [
    FormDefaultComponent,
    FormSimpleComponent,
    FormRendererComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    TranslateModule,
    SignaturePadModule,
    ...MATERIAL_MODULES,
    NgrxCustomersModule,
    AuthFeatureStoreModule,
    DynamicFieldsModule,
    CountriesModule
  ],
  exports: [FormDefaultComponent, FormSimpleComponent, FormRendererComponent]
})
export class FormsModule {}
