import { DataSource } from '@angular/cdk/table';
import { CollectionViewer, SelectionModel } from '@angular/cdk/collections';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { DAOService, NgrxFacadeDAO } from '@doe/client/dao';
import { APIFindOptions, ApiQueryResponse, MongoDBDocument } from '@doe/types';

/**
 * Material CDK DataSource implementation.
 * Use DAOService to get data
 */
export class DAODataSource<T extends MongoDBDocument> implements DataSource<T> {
  private dataSubject = new BehaviorSubject<T[]>(null);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private hasMoreSubject = new BehaviorSubject<boolean>(false);
  private totalCountSubject = new BehaviorSubject<number>(0);
  private maxQueryLimitSubject = new BehaviorSubject<number>(0);
  private sub: Subscription;

  get dao(): DAOService<T> {
    return this.daoService;
  }

  loading$ = this.loadingSubject.asObservable();
  hasMore$ = this.hasMoreSubject.asObservable();
  totalCount$ = this.totalCountSubject.asObservable();
  maxQueryLimit$ = this.maxQueryLimitSubject.asObservable();

  data: T[];

  constructor(private daoService: DAOService<T>) {}

  /**
   * DataSource connect
   * @param collectionViewer collectionviewer
   */
  connect(collectionViewer: CollectionViewer): Observable<T[]> {
    return this.dataSubject.asObservable();
  }

  /**
   * DataSource disconnect
   * @param collectionViewer collectionviewer
   */
  disconnect(collectionViewer: CollectionViewer): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.dataSubject.complete();
    this.loadingSubject.complete();
    this.hasMoreSubject.complete();
    this.totalCountSubject.complete();
    this.maxQueryLimitSubject.complete();
  }

  /**
   * Call DAO (client) to get data from server
   * @param conditions conditions
   * @param options options
   */
  protected find(
    conditions: any,
    options: APIFindOptions
  ): Observable<ApiQueryResponse<T>> {
    return this.dao.find(conditions, options);
  }

  /**
   * Load data source
   * @param conditions conditions
   * @param options
   */
  load(conditions: any, options: APIFindOptions) {
    this.loadingSubject.next(true);
    this.sub = this.find(conditions, options)
      .pipe(
        map(res => {
          if (!res) {
            return [];
          }
          this.totalCountSubject.next(res.totalCount);
          this.hasMoreSubject.next(res.hasMore);
          this.maxQueryLimitSubject.next(res.maxQueryLimit);
          this.data = res.list;
          return res.list;
        }),
        catchError(err => {
          console.log('load data source error', err);
          return of([]);
        })
      )
      .subscribe((data: T[]) => {
        this.loadingSubject.next(false);
        this.dataSubject.next(data);
      });
  }
}
