import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  MatCheckboxModule,
  MatIconModule,
  MatPaginatorModule,
  MatSortModule,
  MatTableModule,
  MatTooltipModule
} from '@angular/material';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { BasicTableCellComponent } from './basic-table-cell.component';
import { BasicTableComponent } from './basic-table.component';

@NgModule({
  declarations: [BasicTableComponent, BasicTableCellComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    FlexLayoutModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatIconModule,
    MatCheckboxModule,
    MatTooltipModule
  ],
  exports: [BasicTableComponent]
})
export class BasicTableModule {}
