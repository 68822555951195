import { createFeatureSelector, createSelector } from '@ngrx/store';

import { createDefaultQuerySelectors } from '../../abstract/selectors';
import { STORES_FEATURE_KEY, StoresState } from './stores.reducer';
import { customersQuery } from '../../customers/+state/customers.selectors';

const getStoresState = createFeatureSelector<StoresState>(STORES_FEATURE_KEY);

const csvCreated = createSelector(
  getStoresState,
  (state: StoresState) => state.csvCreated
);

export const storesQuery = createDefaultQuerySelectors(getStoresState);

storesQuery.csvCreated = csvCreated;
