import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LangService {
  private _lang: string = 'en';

  get currentLang() {
    return this._lang;
  }

  constructor(private translate: TranslateService) {}

  changeLang(lang: string) {
    this.translate.use(lang);
    this._lang = lang;
  }
}
