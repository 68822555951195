import { Company } from './company';
import { FieldBase } from './dynamic-field';
import { MongoDBDocument } from './mongodb-document';
import { User } from './user';

export enum FormStatus {
  Draft = 'Draft',
  Active = 'Active',
  Inactive = 'Inactive'
}

export class Form implements MongoDBDocument {
  _id: string;
  name: string;
  company: string | Company;
  status: FormStatus;
  fields: FieldBase<any>[];
  languages: string[];
  buttonPosition: string = 'start';
  createdAt?: number;
  createdBy?: string | User;
  updatedBy?: string | User;

  constructor() {
    this.status = FormStatus.Draft;
    this.fields = [];
    this.languages = ['en'];
  }
}
