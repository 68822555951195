import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { PermissionsEffects } from './+state/permissions.effects';
import { PermissionsFacade } from './+state/permissions.facade';
import {
  PERMISSIONS_FEATURE_KEY,
  permissionsInitialState,
  permissionsReducer
} from './+state/permissions.reducer';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(PERMISSIONS_FEATURE_KEY, permissionsReducer, {
      initialState: permissionsInitialState
    }),
    EffectsModule.forFeature([PermissionsEffects])
  ],
  exports: [],
  providers: [PermissionsFacade]
})
export class PermissionsFeatureStoreModule {}
