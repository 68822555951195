import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FieldBase, Form, FormStatus } from '@doe/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DAOService } from '../dao-service';

@Injectable({
  providedIn: 'root'
})
export class FormsService extends DAOService<Form> {
  constructor(http: HttpClient) {
    super(http, '/api/forms');
  }

  public static SupportedFormLanguages: string[] = [
    'en',
    'it',
    'fr',
    'de',
    'es',
    'jp',
    'ru',
    'zh',
    'ar',
    'pt',
    'kr'
  ];

  toFormGroup(formFields: FieldBase<any>[]) {
    const group: any = {};
    formFields.forEach(field => {
      if (field.key && !['empty', 'separator'].includes(field.controlType)) {
        group[field.key] = field.required
          ? new FormControl(field.value || '', Validators.required)
          : new FormControl(field.value || '');
      }
    });
    return new FormGroup(group);
  }

  findActiveForms(): Observable<Form[]> {
    return this.find({ status: FormStatus.Active }).pipe(map(res => res.list));
  }

  getRegions(country: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/regions`, {
      country: country
    });
  }

  getCountries(): Observable<any> {
    return this.http.get(`${this.baseUrl}/countries`);
  }

  //   getFormTemplates(conditions: any = {}): Observable<Form[]> {
  //     return this.http.post<BoardingForm[]>('/api/formtemplates/search', conditions);
  //   }
}
