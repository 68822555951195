import { Injectable } from '@angular/core';
import { CompaniesService } from '@doe/client/dao';
import { ApiQueryResponse, Company } from '@doe/types';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { AbstractEffects } from '../../abstract/effects';
import {
  DeleteCompaniesSuccess,
  DeleteSelectedCompanies,
  LoadCompanies,
  LoadCompaniesSuccess,
  LoadSingleCompany,
  LoadSingleCompaniesuccess,
  CompaniesActionTypes,
  CompaniesError,
  ResetDeletedCompany,
  ResetSavedCompany,
  SaveCompany,
  SaveCompaniesuccess
} from './companies.actions';
import {
  COMPANIES_FEATURE_KEY,
  CompaniesPartialState
} from './companies.reducer';

@Injectable()
export class CompaniesEffects extends AbstractEffects<
  CompaniesPartialState,
  Company
> {
  constructor(
    protected actions$: Actions,
    protected dataPersistence: DataPersistence<CompaniesPartialState>,
    protected CompaniesService: CompaniesService
  ) {
    super(actions$, dataPersistence, CompaniesService, COMPANIES_FEATURE_KEY);
  }

  @Effect()
  load$ = this.loadEffect(
    CompaniesActionTypes.LoadCompanies,
    (
      action: LoadCompanies,
      state: CompaniesPartialState,
      res: ApiQueryResponse<Company>
    ) => new LoadCompaniesSuccess(res),
    (action: LoadCompanies, error) => new CompaniesError(error)
  );

  @Effect()
  loadSingle$ = this.loadSingleEffect(
    CompaniesActionTypes.LoadSingleCompany,
    (
      action: LoadSingleCompany,
      state: CompaniesPartialState,
      Company: Company
    ) => new LoadSingleCompaniesuccess(Company),
    (action: LoadSingleCompany, error) => new CompaniesError(error)
  );

  @Effect()
  save$ = this.saveEffect(
    CompaniesActionTypes.SaveCompany,
    (action: SaveCompany, state: CompaniesPartialState, Company: Company) =>
      new SaveCompaniesuccess(Company),
    (action: SaveCompany, error) => new CompaniesError(error)
  );

  @Effect()
  saveSuccess$ = this.saveSuccessEffect(
    CompaniesActionTypes.SaveCompaniesuccess,
    (action: SaveCompaniesuccess) => new ResetSavedCompany(),
    (action: SaveCompaniesuccess, error) => new CompaniesError(error)
  );

  @Effect()
  deleteSelected$ = this.deleteSelectedEffect(
    CompaniesActionTypes.DeleteSelectedCompanies,
    (action: DeleteSelectedCompanies, state: CompaniesPartialState, res) =>
      new DeleteCompaniesSuccess(),
    (action: DeleteSelectedCompanies, error) => new CompaniesError(error)
  );

  @Effect()
  delete$ = this.deleteEffect(
    CompaniesActionTypes.DeleteCompanies,
    (action: DeleteSelectedCompanies, state: CompaniesPartialState, res) =>
      new DeleteCompaniesSuccess(),
    (action: DeleteSelectedCompanies, error) => new CompaniesError(error)
  );

  // @Effect()
  // deleteSuccess$ = this.deleteSuccessEffect(
  //   CompaniesActionTypes.DeleteCompaniesSuccess,
  //   (action: DeleteCompaniesSuccess) => new ResetDeletedCompany(),
  //   (action: DeleteCompaniesSuccess, error) => new CompaniesError(error)
  // );
}
