import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  PERMISSIONS_FEATURE_KEY,
  PermissionsState
} from './permissions.reducer';

// Lookup the 'Permissions' feature state managed by NgRx
const getPermissionsState = createFeatureSelector<PermissionsState>(
  PERMISSIONS_FEATURE_KEY
);

const getLoaded = createSelector(
  getPermissionsState,
  (state: PermissionsState) => state.loaded
);
const getError = createSelector(
  getPermissionsState,
  (state: PermissionsState) => state.error
);

const getRoles = createSelector(
  getPermissionsState,
  getLoaded,
  (state: PermissionsState, isLoaded) => {
    return isLoaded ? state.roles : [];
  }
);

const getPermissions = createSelector(
  getPermissionsState,
  getLoaded,
  (state: PermissionsState, isLoaded) => {
    return isLoaded ? state.permissions : [];
  }
);

const getAll = createSelector(
  getPermissionsState,
  getLoaded,
  (state: PermissionsState, isLoaded) => {
    return isLoaded ? state : undefined;
  }
);

const isSystemAdmin = createSelector(
  getPermissionsState,
  getLoaded,
  getRoles,
  (state: PermissionsState, isLoaded, roles) => {
    return isLoaded ? roles.includes('system') : false;
  }
);

const isAdmin = createSelector(
  getPermissionsState,
  getLoaded,
  isSystemAdmin,
  getRoles,
  (state: PermissionsState, isLoaded, isSysAdmin, roles) => {
    return isLoaded ? isSysAdmin || roles.includes('admin') : false;
  }
);

export const permissionsQuery = {
  getLoaded,
  getError,
  getPermissions,
  getRoles,
  getAll,
  isSystemAdmin,
  isAdmin
};
