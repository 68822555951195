import { HttpClient } from '@angular/common/http';
import {
  TranslateLoader,
  MissingTranslationHandler,
  MissingTranslationHandlerParams
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

export function createHttpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

// export class MultiTranslateHttpLoader implements TranslateLoader {
//     constructor(
//         private http: HttpClient,
//         public resources: { prefix: string, suffix: string }[] = [{
//             prefix: '/assets/i18n/',
//             suffix: '.json'
//         }]
//     ) { }

//     public getTranslation(lang: string): any {
//         return forkJoin(this.resources.map(config => {
//             return this.http.get(`${config.prefix}${lang}${config.suffix}`);
//         })).pipe(
//             map(response => {
//                 return response.reduce((a, b) => {
//                     return Object.assign(a, b);
//                 });
//             })
//         );
//     }
// }

export class DefaultMissingTranslationHandler
  implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    return params.key;
  }
}
