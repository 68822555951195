import { createFeatureSelector } from '@ngrx/store';

import { createDefaultQuerySelectors } from '../../abstract/selectors';
import { CATALOGS_FEATURE_KEY, CatalogsState } from './catalogs.reducer';

const getCatalogsState = createFeatureSelector<CatalogsState>(
  CATALOGS_FEATURE_KEY
);

export const catalogsQuery = createDefaultQuerySelectors(getCatalogsState);
