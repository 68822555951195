import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@doe/types';

import { DAOService } from '../dao-service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends DAOService<User> {
  constructor(http: HttpClient) {
    super(http, '/api/users');
  }

  generateChangePasswordLink(userId: any): Observable<boolean> {
    return this.http.get<boolean>(
      `${this.baseUrl}/${userId}/generateChangePasswordLink`
    );
  }

  changePassword(
    userId: any,
    oldPassword: string,
    newPassword: string
  ): Observable<boolean> {
    return this.http.post<boolean>(`${this.baseUrl}/${userId}/changePassword`, {
      oldPassword,
      newPassword
    });
  }
}
