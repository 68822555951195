import { NgModule, InjectionToken, ModuleWithProviders } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule } from '@angular/router';

export const externalUrlRedirect = new InjectionToken(
  'externalUrlRedirectResolver'
);

export function extUrlRedirect(route: ActivatedRouteSnapshot) {
  const externalUrl = route.paramMap.get('externalUrl');
  window.open(externalUrl, '_self');
}

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'externalUrlRedirect',
        resolve: { url: externalUrlRedirect },
        children: [], // We need a component or children here because we cannot define the route otherwise
        canActivate: [externalUrlRedirect]
      }
    ])
  ]
})
export class ExternalUrlRoutingModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ExternalUrlRoutingModule,
      providers: [
        {
          provide: externalUrlRedirect,
          useValue: extUrlRedirect
          // useValue: (route: ActivatedRouteSnapshot) => {
          //   const externalUrl = route.paramMap.get('externalUrl');
          //   window.open(externalUrl, '_self');
          // },
        }
      ]
    };
  }

  static forChild(): ModuleWithProviders {
    return {
      ngModule: ExternalUrlRoutingModule,
      providers: [
        {
          provide: externalUrlRedirect,
          useValue: extUrlRedirect
          // useValue: (route: ActivatedRouteSnapshot) => {
          //   const externalUrl = route.paramMap.get('externalUrl');
          //   window.open(externalUrl, '_self');
          // },
        }
      ]
    };
  }
}
