import { Company } from '@doe/types';

import {
  AbstractState,
  setDeleteSelectedState,
  setLoadSingleSuccessState,
  setLoadSuccessState,
  setSaveState,
  setSelectedState,
  setResetSavedState,
  setResetDeletedState
} from '../../abstract/reducers';
import { CompaniesAction, CompaniesActionTypes } from './companies.actions';

export const COMPANIES_FEATURE_KEY = 'Companies';

export interface CompaniesState extends AbstractState<Company> {}

export interface CompaniesPartialState {
  readonly [COMPANIES_FEATURE_KEY]: CompaniesState;
}

export const companiesInitialState: CompaniesState = {
  list: [],
  loaded: false
};

export function companiesReducer(
  state: CompaniesState = companiesInitialState,
  action: CompaniesAction
): CompaniesState {
  switch (action.type) {
    case CompaniesActionTypes.LoadCompaniesSuccess: {
      state = setLoadSuccessState(state, action);
      break;
    }

    case CompaniesActionTypes.LoadSingleCompaniesuccess: {
      state = setLoadSingleSuccessState(state, action);
      break;
    }

    case CompaniesActionTypes.SaveCompaniesuccess: {
      state = setSaveState(state, action);
      break;
    }

    case CompaniesActionTypes.SelectCompanies: {
      state = setSelectedState(state, action);
      break;
    }

    case CompaniesActionTypes.DeleteCompaniesSuccess: {
      state = setDeleteSelectedState(state, action);
      break;
    }

    case CompaniesActionTypes.ResetSavedCompany: {
      state = setResetSavedState(state, action);
      break;
    }

    case CompaniesActionTypes.ResetDeletedCompany: {
      state = setResetDeletedState(state, action);
      break;
    }
  }
  return state;
}
