import { Injectable } from '@angular/core';
import { CustomersService } from '@doe/client/dao';
import { ApiQueryResponse, Customer } from '@doe/types';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { AbstractEffects } from '../../abstract/effects';
import {
  DeleteCustomersSuccess,
  DeleteSelectedCustomers,
  LoadCustomers,
  LoadCustomersSuccess,
  LoadSingleCustomer,
  LoadSingleCustomerSuccess,
  CustomersActionTypes,
  CustomersError,
  ResetDeletedCustomer,
  ResetSavedCustomer,
  SaveCustomer,
  SaveCustomerSuccess,
  CreateCSV,
  CSVCreated
} from './customers.actions';
import {
  CUSTOMERS_FEATURE_KEY,
  CustomersPartialState
} from './customers.reducer';
import { map } from 'rxjs/operators';

@Injectable()
export class CustomersEffects extends AbstractEffects<
  CustomersPartialState,
  Customer
> {
  constructor(
    protected actions$: Actions,
    protected dataPersistence: DataPersistence<CustomersPartialState>,
    protected customersService: CustomersService
  ) {
    super(actions$, dataPersistence, customersService, CUSTOMERS_FEATURE_KEY);
  }

  @Effect()
  load$ = this.loadEffect(
    CustomersActionTypes.LoadCustomers,
    (
      action: LoadCustomers,
      state: CustomersPartialState,
      res: ApiQueryResponse<Customer>
    ) => new LoadCustomersSuccess(res),
    (action: LoadCustomers, error) => new CustomersError(error)
  );

  @Effect()
  loadSingle$ = this.loadSingleEffect(
    CustomersActionTypes.LoadSingleCustomer,
    (
      action: LoadSingleCustomer,
      state: CustomersPartialState,
      Customer: Customer
    ) => new LoadSingleCustomerSuccess(Customer),
    (action: LoadSingleCustomer, error) => new CustomersError(error)
  );

  @Effect()
  save$ = this.saveEffect(
    CustomersActionTypes.SaveCustomer,
    (action: SaveCustomer, state: CustomersPartialState, Customer: Customer) =>
      new SaveCustomerSuccess(Customer),
    (action: SaveCustomer, error) => new CustomersError(error)
  );

  @Effect()
  saveSuccess$ = this.saveSuccessEffect(
    CustomersActionTypes.SaveCustomerSuccess,
    (action: SaveCustomerSuccess) => new ResetSavedCustomer(),
    (action: SaveCustomerSuccess, error) => new CustomersError(error)
  );

  @Effect()
  deleteSelected$ = this.deleteSelectedEffect(
    CustomersActionTypes.DeleteSelectedCustomers,
    (action: DeleteSelectedCustomers, state: CustomersPartialState, res) =>
      new DeleteCustomersSuccess(),
    (action: DeleteSelectedCustomers, error) => new CustomersError(error)
  );

  @Effect()
  delete$ = this.deleteEffect(
    CustomersActionTypes.DeleteCustomers,
    (action: DeleteSelectedCustomers, state: CustomersPartialState, res) =>
      new DeleteCustomersSuccess(),
    (action: DeleteSelectedCustomers, error) => new CustomersError(error)
  );

  // @Effect()
  // deleteSuccess$ = this.deleteSuccessEffect(
  //   CustomersActionTypes.DeleteCustomersSuccess,
  //   (action: DeleteCustomersSuccess) => new ResetDeletedCustomer(),
  //   (action: DeleteCustomersSuccess, error) => new CustomersError(error)
  // );

  @Effect()
  createCsv$ = this.dataPersistence.fetch(CustomersActionTypes.CreateCSV, {
    run: (action: CreateCSV, state: CustomersPartialState) => {
      return this.customersService
        .createCsv(action.payload.conditions, action.payload.filename)
        .pipe(map(res => new CSVCreated()));
    },
    onError: (action: CreateCSV, error) => {
      console.log('Error', error);
      return new CustomersError(error);
    }
  });
}
