import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthFacade, PermissionsFacade } from '@doe/client/ngrx';

@Component({
  selector: 'doe-logout-button',
  templateUrl: './logout-button.component.html',
  styleUrls: ['./logout-button.component.scss']
})
export class LogoutButtonComponent implements OnInit {
  /**
   * Router link to navigate on logout (default '/login')
   */
  @Input() navTo = '/login';

  constructor(
    private router: Router,
    private authFacade: AuthFacade,
    private permissionsFacade: PermissionsFacade
  ) {}

  ngOnInit() {}

  logout() {
    this.authFacade.logout();
    this.permissionsFacade.reset();
    this.router.navigate([this.navTo]);
  }
}
