import { Process } from '@doe/types';

import {
  AbstractState,
  setDeleteSelectedState,
  setLoadSingleSuccessState,
  setLoadSuccessState,
  setSaveState,
  setSelectedState,
  setResetSavedState,
  setResetDeletedState
} from '../../abstract/reducers';
import { ProcessesAction, ProcessesActionTypes } from './processes.actions';

export const PROCESSES_FEATURE_KEY = 'processes';

export interface ProcessesState extends AbstractState<Process> {}

export interface ProcessesPartialState {
  readonly [PROCESSES_FEATURE_KEY]: ProcessesState;
}

export const processesInitialState: ProcessesState = {
  list: [],
  loaded: false
};

export function processesReducer(
  state: ProcessesState = processesInitialState,
  action: ProcessesAction
): ProcessesState {
  switch (action.type) {
    case ProcessesActionTypes.LoadProcessesSuccess: {
      state = setLoadSuccessState(state, action);
      break;
    }

    case ProcessesActionTypes.LoadSingleProcessSuccess: {
      state = setLoadSingleSuccessState(state, action);
      break;
    }

    case ProcessesActionTypes.SaveProcessSuccess: {
      state = setSaveState(state, action);
      break;
    }

    case ProcessesActionTypes.SelectProcesses: {
      state = setSelectedState(state, action);
      break;
    }

    case ProcessesActionTypes.DeleteProcessesSuccess: {
      state = setDeleteSelectedState(state, action);
      break;
    }

    case ProcessesActionTypes.ResetSavedProcess: {
      state = setResetSavedState(state, action);
      break;
    }

    case ProcessesActionTypes.ResetDeletedProcess: {
      state = setResetDeletedState(state, action);
      break;
    }
  }
  return state;
}
