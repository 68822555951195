import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ObservableMedia } from '@angular/flex-layout';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators
} from '@angular/forms';
import { ErrorStateMatcher, MatDialog } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthFacade, CustomersFacade } from '@doe/client/ngrx';
import { Customer, Store, User } from '@doe/types';
import { TranslateService } from '@ngx-translate/core';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { Subscription, Observable, of, BehaviorSubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SnackService } from '../../../snack/snack.service';

export class FieldErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    const errorState = !!(
      control &&
      control.invalid &&
      !isSubmitted &&
      (control.dirty || control.touched)
    );
    return errorState;
  }
}

@Component({
  selector: 'doe-form-default',
  templateUrl: './form-default.component.html',
  styleUrls: ['./form-default.component.scss']
})
export class FormDefaultComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() customer: Customer;

  form: FormGroup;
  lang: string;
  me: User;
  store: Store;
  matcher = new FieldErrorStateMatcher();

  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  signaturePadOptions = {
    minWidth: 1,
    canvasWidth: 700,
    canvasHeight: 300
  };

  sanitizedHeader: any;
  sanitizedFooter: any;
  sanitizedGeneralAgreementConsent: any;
  direction = '';
  companyId: string;
  storeId: string;
  company$ = this.authFacade.user$.pipe(map(user => user.company));
  store$: Observable<string> = this.authFacade.auth$.pipe(
    map(auth => auth.store),
    switchMap(store => {
      if (store) {
        if (typeof store === 'string') {
          return of(store);
        } else {
          return of((<Store>store)._id);
        }
      } else {
        return this.route.queryParamMap.pipe(
          map(params => params.get('store'))
        );
      }
    })
  );

  subCompany: Subscription;
  subStore: Subscription;
  mediaSub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private translate: TranslateService,
    private snack: SnackService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private dialog: MatDialog,
    private mediaObserver: ObservableMedia,
    private authFacade: AuthFacade,
    private customersFacade: CustomersFacade
  ) {
    this.form = this.fb.group({
      _id: '',
      header: '',
      footer: '',
      firstname: '',
      lastname: '',
      address: '',
      postalCode: '',
      city: '',
      region: '',
      country: '',
      telephone: ['', this.checkTelephone],
      email: '',
      signature: ['', Validators.required],
      fullname: '',
      title: '',
      birthDate: '',
      passportNumber: '',
      membershipCardNumber: '',
      store: '',
      consent: this.fb.group({
        agreementAcceptance: false,
        email: false,
        sms: false,
        telephone: false,
        dataProfiling: false,
        usePersonalData: false,
        postmail: false
      })
    });

    this.mediaSub = this.mediaObserver.subscribe(media => {
      if (media.mqAlias === 'sm' || media.mqAlias === 'xs') {
        this.signaturePadOptions.canvasWidth = 400;
      }
    });

    this.consents.get('email').disable();
    this.consents.get('sms').disable();
    this.consents.get('telephone').disable();
    this.consents.get('dataProfiling').disable();
    this.consents.get('usePersonalData').disable();
    this.consents.get('postmail').disable();

    this.consents.get('agreementAcceptance').valueChanges.subscribe(checked => {
      if (checked) {
        this.consents.get('email').enable();
        this.consents.get('sms').enable();
        this.consents.get('telephone').enable();
        this.consents.get('dataProfiling').enable();
        this.consents.get('usePersonalData').enable();
        this.consents.get('postmail').enable();
      } else {
        this.consents.get('email').setValue(false);
        this.consents.get('sms').setValue(false);
        this.consents.get('telephone').setValue(false);
        this.consents.get('dataProfiling').setValue(false);
        this.consents.get('usePersonalData').setValue(false);
        this.consents.get('postmail').setValue(false);
        this.consents.get('email').disable();
        this.consents.get('sms').disable();
        this.consents.get('telephone').disable();
        this.consents.get('dataProfiling').disable();
        this.consents.get('usePersonalData').disable();
        this.consents.get('postmail').disable();
      }
    });

    this.consents.get('email').valueChanges.subscribe(checked => {
      this.consents.get('sms').setValue(checked);
      this.consents.get('postmail').setValue(checked);
      this.consents.get('telephone').setValue(checked);
    });
  }

  get formLangs(): string[] {
    try {
      return this.store.simpleForm.languages;
    } catch (error) {
      return [];
    }
  }

  get consents(): FormGroup {
    return this.form.get('consent') as FormGroup;
  }

  ngOnInit() {
    if (!this.lang) {
      this.lang = this.translate.currentLang;
    }
    this.getTranslations();
    if (this.customer) {
      this.customer.signature = null;
      this.form.patchValue(this.customer);
    }
    this.subCompany = this.company$.subscribe(comp => (this.companyId = comp));
    this.subStore = this.store$.subscribe(store => (this.storeId = store));
  }

  ngAfterViewInit() {
    this.onResize();
  }

  ngOnDestroy() {
    if (this.mediaSub) {
      this.mediaSub.unsubscribe();
    }

    if (this.subCompany) {
      this.subCompany.unsubscribe();
    }

    if (this.subStore) {
      this.subStore.unsubscribe();
    }
  }

  getTranslations() {
    this.translate
      .get('RegistrationForm.Header')
      .subscribe(
        text =>
          (this.sanitizedHeader = this.sanitizer.bypassSecurityTrustHtml(text))
      );
    this.translate
      .get('RegistrationForm.Footer')
      .subscribe(
        text =>
          (this.sanitizedFooter = this.sanitizer.bypassSecurityTrustHtml(text))
      );
    const url =
      this.store && this.store.simpleForm
        ? this.store.simpleForm.linkRegulation
        : '';
    this.translate
      .get('RegistrationForm.Fields.Consent.generalAgreement', { url: url })
      .subscribe(
        text =>
          (this.sanitizedGeneralAgreementConsent = this.sanitizer.bypassSecurityTrustHtml(
            text
          ))
      );
  }

  onChangeLang(lang) {
    if (lang) {
      this.lang = lang;
      this.translate.use(lang);
      this.getTranslations();
      if (lang === 'ar') {
        this.direction = 'rtl';
      } else {
        this.direction = '';
      }
    }
  }

  checkTelephone(control: AbstractControl) {
    const valid = new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/);
    if (!valid.test(control.value)) {
      return { invalidTelephone: true };
    } else {
      return false;
    }
  }

  drawComplete() {
    const signature = this.signaturePad.toDataURL();
    this.form.get('signature').setValue(signature);
  }

  drawStart() {
    // console.log('begin drawing');
  }

  submit() {
    const customer = this.form.value;
    if (this.form.valid) {
      customer['company'] = this.companyId;
      //  customer['store'] = this.storeId;
      delete customer['header'];
      delete customer['footer'];
      if (this.customer && this.customer._id) {
        this.customersFacade.save(customer);
        this.customersFacade.saved$.subscribe(doc => {
          if (doc) {
            this.snack.open("You've been updated!").subscribe();
          }
        }),
          err =>
            this.snack
              .open('Error: ' + err.error ? err.error.message : err.message, {
                translateMessage: false
              })
              .subscribe();
      } else {
        this.customersFacade.save(this.form.value);
        this.customersFacade.saved$.subscribe(
          created => {
            if (created === true) {
              this.snack.open("You've been registered!").subscribe();
            }
          },
          err => {
            this.snack
              .open(
                'Ops... an error occured when trying to register the customer !'
              )
              .subscribe();
          }
        );
      }
    } else {
      this.snack
        .open(
          'Something is still missing! Please check the form is correctly filled'
        )
        .subscribe();
    }
  }

  resetFormAndSignature() {
    this.signaturePad.clear();
    this.form.markAsPristine();
    this.form.markAsUntouched();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.signaturePad.set('canvasWidth', window.innerWidth - 50);
  }

  clearSignature() {
    this.form.get('signature').setValue(null);
    this.signaturePad.clear();
  }
}
