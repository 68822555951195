import { Pipe, PipeTransform } from '@angular/core';
import { CountriesService } from './countries.service';

@Pipe({
  name: 'countryName'
})
export class CountryNamePipe implements PipeTransform {
  constructor(private countries: CountriesService) {}

  transform(cca2: string): string {
    return this.countries.getNameByCountryCode(cca2);
  }
}
