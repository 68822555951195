import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatCardModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatRadioModule,
  MatSelectModule,
  MatToolbarModule,
  MatTooltipModule,
  MatCheckboxModule,
  MatListModule,
  MatDividerModule
} from '@angular/material';
import { RouterModule } from '@angular/router';
import { ClientDAOModule } from '@doe/client/dao';
import { NgrxUsersModule } from '@doe/client/ngrx';
import { TranslateModule } from '@ngx-translate/core';
import { SignaturePadModule } from 'angular2-signaturepad';
import { NgxSummernoteModule } from 'ngx-summernote';

import { BasicSearchModule } from './components/basic-search/basic-search.module';
import { BasicCardModule } from './components/basic-card/basic-card.module';
import { BasicTableModule } from './components/basic-table/basic-table.module';
import { HeaderComponent } from './components/header/header.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutButtonComponent } from './components/logout-button/logout-button.component';
import { PasswordChangeComponent } from './components/password-change/password-change.component';
import { CatalogSearchComponent } from './components/catalog-search/catalog-search.component';
import { CatalogSearchModule } from './components/catalog-search/catalog-search.module';
import { ModalsModule } from './components/modals/modals.module';
import { SanitizePipe } from './pipes/sanitize.pipe';
import { PipesModule } from './pipes/pipes.module';
import { UserResumeModule } from './components/user-resume/user-resume.module';
import { SideNavListComponent } from './components/side-nav-list/side-nav-list.component';

const MATERIAL_MODULES = [
  MatToolbarModule,
  MatButtonModule,
  MatTooltipModule,
  MatIconModule,
  MatFormFieldModule,
  MatInputModule,
  MatCardModule,
  MatSelectModule,
  MatMenuModule,
  MatCheckboxModule,
  MatRadioModule,
  MatListModule,
  MatDividerModule
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    TranslateModule,
    ...MATERIAL_MODULES,
    SignaturePadModule,
    NgxSummernoteModule,
    ClientDAOModule,
    BasicTableModule,
    BasicCardModule,
    BasicSearchModule,
    UserResumeModule,
    NgrxUsersModule,
    CatalogSearchModule,
    ModalsModule,
    PipesModule
  ],
  declarations: [
    LoginComponent,
    HeaderComponent,
    LogoutButtonComponent,
    PasswordChangeComponent,
    SideNavListComponent
  ],
  exports: [
    LoginComponent,
    LogoutButtonComponent,
    HeaderComponent,
    SideNavListComponent
  ]
})
export class ClientCoreModule {}
