import { Store } from '@doe/types';

import {
  AbstractState,
  setDeleteSelectedState,
  setLoadSingleSuccessState,
  setLoadSuccessState,
  setSaveState,
  setSelectedState,
  setResetSavedState,
  setResetDeletedState
} from '../../abstract/reducers';
import { StoresAction, StoresActionTypes } from './stores.actions';

export const STORES_FEATURE_KEY = 'Stores';

export interface StoresState extends AbstractState<Store> {
  csvCreated?: boolean;
}

export interface StoresPartialState {
  readonly [STORES_FEATURE_KEY]: StoresState;
}

export const storesInitialState: StoresState = {
  list: [],
  loaded: false
};

export function storesReducer(
  state: StoresState = storesInitialState,
  action: StoresAction
): StoresState {
  switch (action.type) {
    case StoresActionTypes.LoadStoresSuccess: {
      state = setLoadSuccessState(state, action);
      break;
    }

    case StoresActionTypes.LoadSingleStoresuccess: {
      state = setLoadSingleSuccessState(state, action);
      break;
    }

    case StoresActionTypes.SaveStoresuccess: {
      state = setSaveState(state, action);
      break;
    }

    case StoresActionTypes.SelectStores: {
      state = setSelectedState(state, action);
      break;
    }

    case StoresActionTypes.DeleteStoresSuccess: {
      state = setDeleteSelectedState(state, action);
      break;
    }

    case StoresActionTypes.ResetSavedStore: {
      state = setResetSavedState(state, action);
      break;
    }

    case StoresActionTypes.ResetDeletedStore: {
      state = setResetDeletedState(state, action);
      break;
    }

    case StoresActionTypes.CSVCreated: {
      state = {
        ...state,
        csvCreated: true
      };
      break;
    }
  }
  return state;
}
