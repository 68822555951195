import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  APPLOGS_FEATURE_KEY,
  appLogsInitialState,
  appLogsReducer
} from './+state/app-logs.reducer';
import { AppLogsEffects } from './+state/app-logs.effects';
import { AppLogsFacade } from './+state/app-logs.facade';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(APPLOGS_FEATURE_KEY, appLogsReducer, {
      initialState: appLogsInitialState
    }),
    EffectsModule.forFeature([AppLogsEffects])
  ],
  providers: [AppLogsFacade]
})
export class NgrxApplicationLogsModule {}
