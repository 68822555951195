import { createFeatureSelector, createSelector } from '@ngrx/store';

import { createDefaultQuerySelectors } from '../../abstract/selectors';
import {
  SERVERFILES_FEATURE_KEY,
  ServerFilesState
} from './server-files.reducer';

const getServerFilesState = createFeatureSelector<ServerFilesState>(
  SERVERFILES_FEATURE_KEY
);

const getFileToDownload = createSelector(
  getServerFilesState,
  (state: ServerFilesState) => state.fileToDownload
);

export const serverFilesQuery = createDefaultQuerySelectors(
  getServerFilesState
);

serverFilesQuery.getFileToDownload = getFileToDownload;
