import { Company } from './company';
import { MongoDBDocument } from './mongodb-document';
import { Observable, Subject } from 'rxjs';
import { User } from './user';
import { DAO } from '@doe/server/dao/dao';
import { Customer } from './customer';
import { Store } from './store';
import { SKU } from './sku';
import { Catalog } from './catalog';

export interface Process extends MongoDBDocument {
  name?: string;
  type?: string;
  options?: any;
  enabled?: boolean;
  createdAt?: number;
  createdBy?: string | User;
  company?: string | Company;
}

export enum AppProcessRuntimeStatus {
  started = 'started',
  stopped = 'stopped',
  error = 'error'
}
export interface ImporterProcessOptions {
  path: string; // folder path
  pattern: string; // filename pattern,
  caseSensitive?: boolean;
}

export abstract class AbstractAppRuntimeProcess {
  _id: string;
  type: string;
  name: string;
  status: string;
  company: string;

  protected _events = new Subject<AppRuntimeProcessEvent>();

  constructor(appProcess: Process, type) {
    this._id = appProcess._id;
    this.name = appProcess.name;
    this.company = appProcess.company as string;
    this.type = type;
    this.status = AppProcessRuntimeStatus.stopped;
  }

  abstract start();
  abstract stop();

  public events(): Observable<AppRuntimeProcessEvent> {
    return this._events.asObservable();
  }

  public emitEvent(event: AppRuntimeProcessEvent) {
    event.procId = this._id;
    this._events.next(event);
  }
}
export class AppRuntimeProcessEvent {
  procId: string;
  name: string;
  data: any;

  constructor(name: string, data?: any) {
    this.name = name;
    this.data = data;
  }
}

export enum AppProcessType {
  catalogImport = 'catalog-import',
  customerImport = 'customers-import'
}
