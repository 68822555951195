import { DAOService } from '@doe/client/dao';
import { ApiQueryResponse } from '@doe/types';
import { Actions, ofType } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  DeleteAction,
  DeleteSelectedAction,
  DeleteSuccessAction,
  LoadAction,
  LoadSingleAction,
  LoadSingleSuccessAction,
  LoadSuccessAction,
  ResetDeletedAction,
  ResetSavedAction,
  SaveAction,
  SaveSuccessAction,
  StateErrorAction
} from './actions';

export abstract class AbstractEffects<S, T> {
  constructor(
    protected actions$: Actions,
    protected dataPersistence: DataPersistence<S>,
    protected daoService: DAOService<T>,
    protected featureKey: string
  ) {}

  abstract load$: Observable<LoadSuccessAction<T>>;
  abstract loadSingle$: Observable<LoadSingleSuccessAction<T>>;
  abstract save$: Observable<SaveAction<T>>;
  abstract deleteSelected$: Observable<DeleteSelectedAction>;
  abstract delete$: Observable<DeleteAction>;

  loadEffect(
    type: string,
    onSuccess: (
      action: LoadAction,
      state: any,
      res: ApiQueryResponse<T>
    ) => LoadSuccessAction<T>,
    onError: (action: LoadAction, error: any) => StateErrorAction
  ) {
    return this.dataPersistence.fetch(type, {
      run: (action: LoadAction, state: any) => {
        return this.daoService
          .find(action.payload.conditions, action.payload.options)
          .pipe(map(res => onSuccess(action, state, res)));
      },
      onError: (action: LoadAction, error) => {
        console.error('Error', error);
        return onError(action, error);
      },
      id: (action: LoadAction, state: any) => {
        // TODO: should it be a callback??
        return '' + action.payload.conditions + action.payload.options;
      }
    });
  }

  loadSingleEffect(
    type: string,
    onSuccess: (
      action: LoadSingleAction<T>,
      state: any,
      res: T
    ) => LoadSingleSuccessAction<T>,
    onError: (action: LoadSingleAction<T>, error: any) => StateErrorAction
  ) {
    return this.dataPersistence.fetch(type, {
      run: (action: LoadSingleAction<T>, state: any) => {
        return this.daoService.findOneBy(action.payload.conditions).pipe(
          map(res => {
            console.log(res);
            return onSuccess(action, state, res);
          })
        );
      },
      onError: (action: LoadSingleAction<T>, error) => {
        console.error('Error', error);
        return onError(action, error);
      },
      id: (action: LoadSingleAction<T>, state: any) => {
        return action.payload.conditions;
      }
    });
  }

  saveEffect(
    type: string,
    onSuccess: (
      action: SaveAction<T>,
      state: any,
      res: any
    ) => SaveSuccessAction<T>,
    onError: (action: SaveAction<T>, error: any) => StateErrorAction
  ) {
    return this.dataPersistence.pessimisticUpdate(type, {
      run: (action: SaveAction<T>, state: any) => {
        return this.daoService
          .save(action.payload)
          .pipe(map(res => onSuccess(action, state, res)));
      },
      onError: (action: SaveAction<T>, error) => {
        console.log('Error', error);
        return onError(action, error);
      }
    });
  }

  saveSuccessEffect(
    type: string,
    onSuccess: (action: SaveSuccessAction<T>) => ResetSavedAction,
    onError: (action: SaveSuccessAction<T>, error: any) => StateErrorAction
  ): Observable<ResetSavedAction> {
    return this.actions$.pipe(
      ofType(type),
      map((action: SaveSuccessAction<T>) => onSuccess(action)),
      catchError(err => {
        console.log('Error', err);
        return of(onError(null, err));
      })
    );
  }

  deleteEffect(
    type: string,
    onSuccess: (
      action: DeleteAction,
      state: any,
      res: any
    ) => DeleteSuccessAction,
    onError: (action: DeleteAction, error: any) => StateErrorAction
  ) {
    return this.dataPersistence.fetch(type, {
      run: (action: DeleteAction, state: any) => {
        return this.daoService
          .delete(action.payload.id)
          .pipe(map(res => onSuccess(action, state, res)));
      },
      onError: (action: DeleteAction, error) => {
        console.log('Error', error);
        return onError(action, error);
      },
      id: (action: DeleteAction, state: any) => {
        return action.payload.id;
      }
    });
  }

  deleteSelectedEffect(
    type: string,
    onSuccess: (
      action: DeleteSelectedAction,
      state: any,
      res: any
    ) => DeleteSuccessAction,
    onError: (action: DeleteSelectedAction, error: any) => StateErrorAction
  ) {
    return this.dataPersistence.fetch(type, {
      run: (action: DeleteSelectedAction, state: any) => {
        const ids = state[this.featureKey].selected.map(u => u._id);
        return this.daoService
          .deleteMany(ids)
          .pipe(map(res => onSuccess(action, state, res)));
      },
      onError: (action: DeleteSelectedAction, error) => {
        console.log('Error', error);
        return onError(action, error);
      },
      id: (action: DeleteSelectedAction, state: any) => {
        const ids = state[this.featureKey].selected.map(u => u._id);
        return ids;
      }
    });
  }

  deleteSuccessEffect(
    type: string,
    onSuccess: (action: DeleteSuccessAction) => ResetDeletedAction,
    onError: (action: DeleteSuccessAction, error: any) => StateErrorAction
  ) {
    return this.actions$.pipe(
      ofType(type),
      map((action: DeleteSuccessAction) => onSuccess(action)),
      catchError(err => {
        console.log('Error', err);
        return of(onError(null, err));
      })
    );
  }
}
