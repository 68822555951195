import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { AppLogsPartialState } from './app-logs.reducer';
import { appLogsQuery } from './app-logs.selectors';
import {
  DeleteAppLogs,
  DeleteSelectedAppLogs,
  LoadAppLogs,
  LoadSingleAppLog,
  SaveAppLog,
  SelectAppLogs
} from './app-logs.actions';
import { NgrxFacadeDAO } from '@doe/client/dao';
import { ApplicationLog, APIFindOptions, ApiQueryResponse } from '@doe/types';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class AppLogsFacade implements NgrxFacadeDAO<ApplicationLog> {
  results$: Observable<ApiQueryResponse<ApplicationLog>> = this.AppLog.select(
    appLogsQuery.featureSelector
  );
  loaded$: Observable<boolean> = this.AppLog.select(appLogsQuery.getLoaded);
  single$?: Observable<ApplicationLog> = this.AppLog.select(
    appLogsQuery.getSingle
  );
  singleLoaded$?: Observable<boolean> = this.AppLog.select(
    appLogsQuery.getSingleLoaded
  );
  error$?: Observable<any> = this.AppLog.select(appLogsQuery.getError);
  deleteSelected$?: Observable<any> = this.AppLog.select(
    appLogsQuery.getDeleted
  );
  selected$: Observable<ApplicationLog[]> = this.AppLog.select(
    appLogsQuery.getSelected
  );
  saved$: Observable<boolean> = this.AppLog.select(appLogsQuery.getSaved);
  ////

  constructor(private AppLog: Store<AppLogsPartialState>) {}

  load(conditions?: any, options?: APIFindOptions) {
    this.AppLog.dispatch(
      new LoadAppLogs({
        conditions,
        options
      })
    );
  }

  loadSingle(conditions: any) {
    this.AppLog.dispatch(
      new LoadSingleAppLog({
        conditions
      })
    );
  }

  save(data: ApplicationLog) {
    this.AppLog.dispatch(new SaveAppLog(data));
  }

  delete(id: any) {
    this.AppLog.dispatch(
      new DeleteAppLogs({
        id
      })
    );
  }

  deleteSelected() {
    this.AppLog.dispatch(new DeleteSelectedAppLogs());
  }

  setSelected(selected: ApplicationLog[]) {
    this.AppLog.dispatch(new SelectAppLogs(selected));
  }
  ////
}
