import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { NgModule } from '@angular/core';
import {
  MissingTranslationHandler,
  TranslateCompiler,
  TranslateLoader,
  TranslateModule
} from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';

import {
  createHttpTranslateLoader,
  DefaultMissingTranslationHandler
} from './helpers';

@NgModule({
  imports: [HttpClientModule, TranslateModule.forRoot()],
  exports: [TranslateModule]
})
export class I18nRootModule {
  static forRoot(
    config: {
      compiler?: any;
      loaderFactory?: Function;
      missingTranslationHandler?: any;
    } = {}
  ): ModuleWithProviders {
    return {
      ngModule: I18nRootModule,
      providers: [
        {
          provide: TranslateCompiler,
          useClass: config.compiler || TranslateMessageFormatCompiler
        },
        {
          provide: MissingTranslationHandler,
          useClass:
            config.missingTranslationHandler || DefaultMissingTranslationHandler
        },
        {
          provide: TranslateLoader,
          useFactory: config.loaderFactory
            ? config.loaderFactory
            : createHttpTranslateLoader,
          deps: [HttpClient]
        }
      ]
    };
  }
}
