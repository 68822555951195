import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  AuthGuardModule,
  ClientCoreModule,
  JwtInterceptor
} from '@doe/client/core';
import {
  I18nRootModule,
  DefaultMissingTranslationHandler,
  createHttpTranslateLoader
} from '@doe/client/i18n';
import {
  AuthEffects,
  AuthFacade,
  authInitialState,
  authReducer,
  PermissionsEffects,
  PermissionsFacade,
  permissionsInitialState,
  permissionsReducer
} from '@doe/client/ngrx';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
  TranslateService,
  TranslateModule,
  TranslateCompiler,
  MissingTranslationHandler,
  TranslateLoader
} from '@ngx-translate/core';
import { NxModule } from '@nrwl/angular';
import * as countries from 'i18n-iso-countries';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';

declare function require(module);
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/it.json'));
countries.registerLocale(require('i18n-iso-countries/langs/fr.json'));
countries.registerLocale(require('i18n-iso-countries/langs/de.json'));
countries.registerLocale(require('i18n-iso-countries/langs/es.json'));
countries.registerLocale(require('i18n-iso-countries/langs/ja.json'));
countries.registerLocale(require('i18n-iso-countries/langs/ru.json'));
countries.registerLocale(require('i18n-iso-countries/langs/ar.json'));
countries.registerLocale(require('i18n-iso-countries/langs/zh.json'));
countries.registerLocale(require('i18n-iso-countries/langs/pt.json'));
countries.registerLocale(require('i18n-iso-countries/langs/ko.json'));

@NgModule({
  declarations: [AppComponent, HomeComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NxModule.forRoot(),
    AppRoutingModule,
    ClientCoreModule,
    // I18nRootModule.forRoot(),
    TranslateModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: DefaultMissingTranslationHandler
      },
      loader: {
        provide: TranslateLoader,
        useFactory: createHttpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    StoreModule.forRoot({
      auth: authReducer,
      permissions: permissionsReducer
    }),
    EffectsModule.forRoot([AuthEffects, PermissionsEffects]),
    AuthGuardModule.forRoot({
      loginRouterLink: '/login',
      localStorageAuthTokenName: environment.localStorageAuthTokenName
    })
  ],
  providers: [
    AuthFacade,
    PermissionsFacade,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(translate: TranslateService) {
    translate.use('en');
  }
}
