import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  LoginComponent,
  AuthGuard,
  UserResumeComponent,
  PasswordChangeComponent
} from '@doe/client/core';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'admin',
    loadChildren: '@doe/client/admin-ui#ClientAdminUiModule',
    canActivate: [AuthGuard]
  },
  { path: 'store', loadChildren: '@doe/client/store-ui#ClientStoreUiModule' },
  { path: 'login', component: LoginComponent },
  { path: 'change-password', component: PasswordChangeComponent },
  { path: 'account/:id', component: UserResumeComponent }
  // { path: 'change-password-timeout', component: PasswordChangeFailureLinkComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
