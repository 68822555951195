import { Injectable } from '@angular/core';
import { CatalogsService } from '@doe/client/dao';
import { ApiQueryResponse, Catalog } from '@doe/types';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { AbstractEffects } from '../../abstract/effects';
import {
  DeleteCatalogsSuccess,
  DeleteSelectedCatalogs,
  LoadCatalogs,
  LoadCatalogsSuccess,
  LoadSingleCatalog,
  LoadSingleCatalogSuccess,
  CatalogsActionTypes,
  CatalogsError,
  ResetSavedCatalog,
  SaveCatalog,
  SaveCatalogSuccess
} from './catalogs.actions';
import { CatalogsPartialState, CATALOGS_FEATURE_KEY } from './catalogs.reducer';

@Injectable()
export class CatalogsEffects extends AbstractEffects<
  CatalogsPartialState,
  Catalog
> {
  constructor(
    protected actions$: Actions,
    protected dataPersistence: DataPersistence<CatalogsPartialState>,
    protected catalogsService: CatalogsService
  ) {
    super(actions$, dataPersistence, catalogsService, CATALOGS_FEATURE_KEY);
  }

  @Effect()
  load$ = this.loadEffect(
    CatalogsActionTypes.LoadCatalogs,
    (
      action: LoadCatalogs,
      state: CatalogsPartialState,
      res: ApiQueryResponse<Catalog>
    ) => new LoadCatalogsSuccess(res),
    (action: LoadCatalogs, error) => new CatalogsError(error)
  );

  @Effect()
  loadSingle$ = this.loadSingleEffect(
    CatalogsActionTypes.LoadSingleCatalog,
    (
      action: LoadSingleCatalog,
      state: CatalogsPartialState,
      Catalog: Catalog
    ) => new LoadSingleCatalogSuccess(Catalog),
    (action: LoadSingleCatalog, error) => new CatalogsError(error)
  );

  @Effect()
  save$ = this.saveEffect(
    CatalogsActionTypes.SaveCatalog,
    (action: SaveCatalog, state: CatalogsPartialState, Catalog: Catalog) =>
      new SaveCatalogSuccess(Catalog),
    (action: SaveCatalog, error) => new CatalogsError(error)
  );

  @Effect()
  saveSuccess$ = this.saveSuccessEffect(
    CatalogsActionTypes.SaveCatalogSuccess,
    (action: SaveCatalogSuccess) => new ResetSavedCatalog(),
    (action: SaveCatalogSuccess, error) => new CatalogsError(error)
  );

  @Effect()
  deleteSelected$ = this.deleteSelectedEffect(
    CatalogsActionTypes.DeleteSelectedCatalogs,
    (action: DeleteSelectedCatalogs, state: CatalogsPartialState, res) =>
      new DeleteCatalogsSuccess(),
    (action: DeleteSelectedCatalogs, error) => new CatalogsError(error)
  );

  @Effect()
  delete$ = this.deleteEffect(
    CatalogsActionTypes.DeleteCatalogs,
    (action: DeleteSelectedCatalogs, state: CatalogsPartialState, res) =>
      new DeleteCatalogsSuccess(),
    (action: DeleteSelectedCatalogs, error) => new CatalogsError(error)
  );
}
