import { createFeatureSelector } from '@ngrx/store';

import { createDefaultQuerySelectors } from '../../abstract/selectors';
import { PROCESSES_FEATURE_KEY, ProcessesState } from './processes.reducer';

const getProcessesState = createFeatureSelector<ProcessesState>(
  PROCESSES_FEATURE_KEY
);

export const processesQuery = createDefaultQuerySelectors(getProcessesState);
