import { createFeatureSelector, createSelector } from '@ngrx/store';

import { createDefaultQuerySelectors } from '../../abstract/selectors';
import { APPLOGS_FEATURE_KEY, AppLogsState } from './app-logs.reducer';

const getAppLogsState = createFeatureSelector<AppLogsState>(
  APPLOGS_FEATURE_KEY
);

const usersQueryDef = createDefaultQuerySelectors(getAppLogsState);

export const appLogsQuery = {
  ...usersQueryDef
};
