import { Injectable } from '@angular/core';
import { DAOService } from '../dao-service';
import { Catalog } from '@doe/types';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProcessesService extends DAOService<Catalog> {
  constructor(http: HttpClient) {
    super(http, '/api/processes');
  }
}
