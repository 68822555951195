import {
  Component,
  Input,
  SimpleChanges,
  OnInit,
  OnDestroy
} from '@angular/core';
import { FormsService } from '@doe/client/dao';
import { Observable, Subscription } from 'rxjs';
import { ErrorStateMatcher, MatSnackBar } from '@angular/material';
import {
  ControlContainer,
  FormGroupDirective,
  FormGroup,
  Validators
} from '@angular/forms';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'doe-sysdat-regions',
  templateUrl: './sysdat-regions.component.html',
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class SysDatRegionsComponent implements OnInit, OnDestroy {
  @Input() controlName: string;
  @Input() placeholder;
  @Input() required = false;
  @Input() errorStateMatcher: any;
  @Input() appearance: string;

  parentForm: FormGroup;
  selectedCountry: string;

  constructor(
    private formService: FormsService,
    private parent: FormGroupDirective,
    private snack: MatSnackBar
  ) {}

  regions: any[] = [];
  regions$: Observable<any>;
  sub: Subscription;
  formSub: Subscription;

  ngOnInit() {
    this.parentForm = this.parent.form;
    this.parentForm.get('region').disable();
    if (this.parentForm.get('country').value) {
      this.selectedCountry = this.parentForm.get('country').value;
      this.validateCountryValue();
      this.loadRegions();
    }

    this.sub = this.parentForm
      .get('country')
      .valueChanges.subscribe(country => {
        if (country) {
          this.parentForm.get('region').reset();
          this.selectedCountry = country;
          this.validateCountryValue();
          this.loadRegions();
        }
      });
  }

  ngOnDestroy() {
    this.sub ? this.sub.unsubscribe() : null;
    this.formSub ? this.formSub.unsubscribe() : null;
  }

  displayFn(code: string): string {
    if (code && this.regions && this.regions.length > 0) {
      return this.regions.find(region => region.code === code).name;
    }
  }

  validateCountryValue() {
    if (this.selectedCountry === 'IT') {
      this.parentForm.get('region').setValidators(Validators.required);
      this.required = true;
    } else {
      this.parentForm.get('region').clearValidators();
      this.required = false;
    }
    this.parentForm.get('region').updateValueAndValidity();
  }

  loadRegions() {
    this.formSub = this.formService.getRegions(this.selectedCountry).subscribe(
      res => {
        this.regions = res;
        if (res && res.length > 0) {
          this.regions = res;
          this.parentForm.get('region').enable();

          const regionControl = this.parentForm.get('region');
          this.regions$ = regionControl.valueChanges.pipe(
            startWith(''),
            map(searchText =>
              searchText
                ? this.filterObservable(searchText)
                : this.regions.slice()
            )
          );
        }
      },
      err => {
        this.snack.open('Error while retrieving regions', 'Ok');
      }
    );
  }

  filterObservable(searchText: string): string[] {
    const filterValue = searchText.toLowerCase();
    return this.regions.filter(
      region => region.name.toLowerCase().indexOf(filterValue) === 0
    );
  }
}
