import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Customer } from '@doe/types';

@Component({
  selector: 'doe-form-simple',
  templateUrl: './form-simple.component.html',
  styleUrls: ['./form-simple.component.scss']
})
export class FormSimpleComponent implements OnInit, OnDestroy {
  @Input() customer: Customer;
  @Input() formId = 'default';

  sub: Subscription;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.sub = this.route.queryParamMap.subscribe(params => {
      if (params.get('form')) {
        this.formId = params.get('form');
      }
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
