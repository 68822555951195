import { Injectable } from '@angular/core';
import { SkuService } from '@doe/client/dao';
import { ApiQueryResponse, SKU } from '@doe/types';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { AbstractEffects } from '../../abstract/effects';
import {
  DeleteSkusSuccess,
  DeleteSelectedSkus,
  LoadSkus,
  LoadSkusSuccess,
  LoadSingleCustomer,
  LoadSingleCustomerSuccess,
  SkusActionTypes,
  SkusError,
  ResetDeletedCustomer,
  ResetSavedCustomer,
  SaveCustomer,
  SaveCustomerSuccess
} from './skus.actions';
import { SKUS_FEATURE_KEY, SkusPartialState } from './skus.reducer';
import { map } from 'rxjs/operators';

@Injectable()
export class SkusEffects extends AbstractEffects<SkusPartialState, SKU> {
  constructor(
    protected actions$: Actions,
    protected dataPersistence: DataPersistence<SkusPartialState>,
    protected skusService: SkuService
  ) {
    super(actions$, dataPersistence, skusService, SKUS_FEATURE_KEY);
  }

  @Effect()
  load$ = this.loadEffect(
    SkusActionTypes.LoadSkus,
    (action: LoadSkus, state: SkusPartialState, res: ApiQueryResponse<SKU>) =>
      new LoadSkusSuccess(res),
    (action: LoadSkus, error) => new SkusError(error)
  );

  @Effect()
  loadSingle$ = this.loadSingleEffect(
    SkusActionTypes.LoadSingleCustomer,
    (action: LoadSingleCustomer, state: SkusPartialState, Sku: SKU) =>
      new LoadSingleCustomerSuccess(Sku),
    (action: LoadSingleCustomer, error) => new SkusError(error)
  );

  @Effect()
  save$ = this.saveEffect(
    SkusActionTypes.SaveCustomer,
    (action: SaveCustomer, state: SkusPartialState, Sku: SKU) =>
      new SaveCustomerSuccess(Sku),
    (action: SaveCustomer, error) => new SkusError(error)
  );

  @Effect()
  saveSuccess$ = this.saveSuccessEffect(
    SkusActionTypes.SaveCustomerSuccess,
    (action: SaveCustomerSuccess) => new ResetSavedCustomer(),
    (action: SaveCustomerSuccess, error) => new SkusError(error)
  );

  @Effect()
  deleteSelected$ = this.deleteSelectedEffect(
    SkusActionTypes.DeleteSelectedSkus,
    (action: DeleteSelectedSkus, state: SkusPartialState, res) =>
      new DeleteSkusSuccess(),
    (action: DeleteSelectedSkus, error) => new SkusError(error)
  );

  @Effect()
  delete$ = this.deleteEffect(
    SkusActionTypes.DeleteSkus,
    (action: DeleteSelectedSkus, state: SkusPartialState, res) =>
      new DeleteSkusSuccess(),
    (action: DeleteSelectedSkus, error) => new SkusError(error)
  );

  // @Effect()
  // deleteSuccess$ = this.deleteSuccessEffect(
  //   SkusActionTypes.DeleteSkusSuccess,
  //   (action: DeleteSkusSuccess) => new ResetDeletedCustomer(),
  //   (action: DeleteSkusSuccess, error) => new SkusError(error)
  // );
}
