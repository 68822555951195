import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  StoresFacade,
  UsersFacade,
  CompaniesFacade,
  CompanyLoaded
} from '@doe/client/ngrx';
import { FieldBase } from '@doe/types';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'doe-search',
  templateUrl: './basic-search.component.html',
  styleUrls: ['./basic-search.component.scss']
})
export class BasicSearchComponent implements OnInit, OnDestroy {
  @Input() fields: FieldBase<any>[];
  // @Input() genericField: boolean = false;
  @Input() title: string = 'Search';
  @Input() expansionPanelOn: boolean;
  @Output() event: EventEmitter<any> = new EventEmitter();

  lang: string;
  form: FormGroup;
  storesOptions: any[];
  usersOptions: any[];
  companiesOptions: any[];

  private commonSub_1: Subscription;
  private commonSub_2: Subscription;
  private commonSub_3: Subscription;

  constructor(
    private storesFacade: StoresFacade,
    private usersFacade: UsersFacade,
    private companiesFacade: CompaniesFacade,
    private translate: TranslateService
  ) {
    this.form = new FormGroup({
      firstname: new FormControl(''),
      lastname: new FormControl(''),
      email: new FormControl(''),
      store: new FormControl(''),
      createdBy: new FormControl(''),
      name: new FormControl(''),
      city: new FormControl(''),
      message: new FormControl(''),
      objectType: new FormControl(''),
      action: new FormControl(''),
      company: new FormControl('')
    });

    this.lang = this.translate.currentLang || 'en';
  }

  get firstnameControl() {
    return this.form.get('firstname');
  }
  get lastnameControl() {
    return this.form.get('lastname');
  }
  get emailControl() {
    return this.form.get('email');
  }
  get storeControl() {
    return this.form.get('store');
  }
  get createdByControl() {
    return this.form.get('createdBy');
  }
  get nameControl() {
    return this.form.get('name');
  }
  get cityControl() {
    return this.form.get('city');
  }
  get messageControl() {
    return this.form.get('message');
  }
  get typeControl() {
    return this.form.get('objectType');
  }
  get actionControl() {
    return this.form.get('action');
  }
  get companyControl() {
    return this.form.get('company');
  }

  ngOnInit() {
    if (this.fields) {
      if (this.fields.find(d => d.key === 'store')) {
        this.storesFacade.load({});
        this.commonSub_1 = this.storesFacade.results$.subscribe(s => {
          if (s) {
            this.storesOptions = s.list.map(store => {
              return { key: store._id, value: { en: store.name } };
            });
            for (var i in this.fields) {
              if (this.fields[i].key == 'store') {
                this.fields[i].options = this.storesOptions;
                break;
              }
            }
          }
        });
      }

      if (this.fields.find(d => d.key === 'createdBy')) {
        this.usersFacade.load({});
        this.commonSub_2 = this.usersFacade.results$.subscribe(u => {
          if (u) {
            this.usersOptions = u.list.map(user => {
              return { key: user._id, value: { en: user.email } };
            });
            for (var i in this.fields) {
              if (this.fields[i].key == 'createdBy') {
                this.fields[i].options = this.usersOptions;
                break;
              }
            }
          }
        });
      }

      if (this.fields.find(d => d.key === 'company')) {
        this.companiesFacade.load({});
        this.commonSub_3 = this.companiesFacade.results$.subscribe(c => {
          if (c) {
            this.companiesOptions = c.list.map(company => {
              return { key: company._id, value: { en: company.name } };
            });
            for (var i in this.fields) {
              if (this.fields[i].key == 'company') {
                this.fields[i].options = this.companiesOptions;
              }
            }
          }
        });
      }
    }
  }

  async createFilter() {
    if (this.form.valid) {
      const conditions = await this.createConditions();
      this.event.emit(conditions);
    }
  }

  createConditions() {
    const reFirstname = new RegExp(this.firstnameControl.value, 'gi');
    const reLastname = new RegExp(this.lastnameControl.value, 'gi');
    const reEmail = new RegExp(this.emailControl.value, 'gi');
    const reName = new RegExp(this.nameControl.value, 'gi');
    const reCity = new RegExp(this.cityControl.value, 'gi');
    const reMess = new RegExp(this.messageControl.value, 'gi');
    const reType = new RegExp(this.typeControl.value, 'gi');
    const reAction = new RegExp(this.actionControl.value, 'gi');

    const conditions: any = {
      $and: []
    };

    if (this.lastnameControl.value) {
      conditions.$and.push({
        lastname: { $regex: reLastname.source, $options: 'gi' }
      });
    }
    if (this.firstnameControl.value) {
      conditions.$and.push({
        firstname: { $regex: reFirstname.source, $options: 'gi' }
      });
    }
    if (this.emailControl.value) {
      conditions.$and.push({
        email: { $regex: reEmail.source, $options: 'gi' }
      });
    }
    if (this.storeControl.value) {
      conditions.$and.push({ store: this.storeControl.value });
    }
    if (this.createdByControl.value) {
      conditions.$and.push({ createdBy: this.createdByControl.value });
    }
    if (this.nameControl.value) {
      conditions.$and.push({ name: { $regex: reName.source, $options: 'gi' } });
    }
    if (this.cityControl.value) {
      conditions.$and.push({ city: { $regex: reCity.source, $options: 'gi' } });
    }
    if (this.messageControl.value) {
      conditions.$and.push({
        message: { $regex: reMess.source, $options: 'gi' }
      });
    }
    if (this.typeControl.value) {
      conditions.$and.push({
        objectType: { $regex: reType.source, $options: 'gi' }
      });
    }
    if (this.actionControl.value) {
      conditions.$and.push({ action: this.actionControl.value });
    }
    if (this.companyControl.value) {
      conditions.$and.push({ company: this.companyControl.value });
    }
    if (conditions.$and.length > 0) {
      return conditions;
    }

    return {};
  }

  resetForm() {
    this.form.reset();
    this.event.emit({});
  }

  ngOnDestroy(): void {
    if (this.commonSub_1) {
      this.commonSub_1.unsubscribe();
    }
    if (this.commonSub_2) {
      this.commonSub_2.unsubscribe();
    }
    if (this.commonSub_3) {
      this.commonSub_3.unsubscribe();
    }
  }
}
