import { createFeatureSelector, createSelector } from '@ngrx/store';

import { createDefaultQuerySelectors } from '../../abstract/selectors';
import { CUSTOMERS_FEATURE_KEY, CustomersState } from './customers.reducer';

const getCustomersState = createFeatureSelector<CustomersState>(
  CUSTOMERS_FEATURE_KEY
);

const csvCreated = createSelector(
  getCustomersState,
  (state: CustomersState) => state.csvCreated
);

export const customersQuery = createDefaultQuerySelectors(getCustomersState);

customersQuery.csvCreated = csvCreated;
