import { Customer } from '@doe/types';

import {
  DeleteAction,
  DeleteSelectedAction,
  DeleteSuccessAction,
  LoadAction,
  LoadSingleAction,
  LoadSingleSuccessAction,
  LoadSuccessAction,
  ResetSavedAction,
  SaveAction,
  SelectAction,
  StateErrorAction,
  ResetDeletedAction
} from '../../abstract/actions';
import { Action } from '@ngrx/store';

export enum CustomersActionTypes {
  LoadCustomers = '[Customers] Load Customers',
  LoadCustomersSuccess = '[Customers] Customers Loaded',
  CustomersError = '[Customers] Customers Load Error',
  LoadSingleCustomer = '[Customers] Load single Customer',
  LoadSingleCustomerSuccess = '[Customers] Load single Customer success',
  SaveCustomer = '[Proceses] Save Customer',
  SaveCustomerSuccess = '[Proceses] Save Customer success',
  SelectCustomers = '[Customers] Select Customers',
  DeleteSelectedCustomers = '[Customers] Delete selected',
  DeleteSelectedCustomersSuccess = '[Customers] Delete selected success',
  DeleteCustomers = '[Customers] Delete Customers based on conditions',
  DeleteCustomersSuccess = '[Customers] Delete Customers success',
  ResetSavedCustomer = '[Customers] Reset saved state',
  ResetDeletedCustomer = '[Customers] Reset deleted state',
  CreateCSV = '[Customers] Create CSV (server)',
  CSVCreated = '[Customers] CSV (server) created'
}

export class CustomersError extends StateErrorAction {
  readonly type = CustomersActionTypes.CustomersError;
}

export class LoadCustomers extends LoadAction {
  readonly type = CustomersActionTypes.LoadCustomers;
}

export class LoadCustomersSuccess extends LoadSuccessAction<Customer> {
  readonly type = CustomersActionTypes.LoadCustomersSuccess;
}

export class LoadSingleCustomer extends LoadSingleAction<Customer> {
  readonly type = CustomersActionTypes.LoadSingleCustomer;
}

export class LoadSingleCustomerSuccess extends LoadSingleSuccessAction<
  Customer
> {
  readonly type = CustomersActionTypes.LoadSingleCustomerSuccess;
}

export class SaveCustomer extends SaveAction<Customer> {
  readonly type = CustomersActionTypes.SaveCustomer;
}

export class SaveCustomerSuccess extends SaveAction<Customer> {
  readonly type = CustomersActionTypes.SaveCustomerSuccess;
}

export class SelectCustomers extends SelectAction<Customer> {
  readonly type = CustomersActionTypes.SelectCustomers;
}

export class DeleteSelectedCustomers extends DeleteSelectedAction {
  readonly type = CustomersActionTypes.DeleteSelectedCustomers;
}

export class DeleteCustomers extends DeleteAction {
  readonly type = CustomersActionTypes.DeleteCustomers;
}

export class DeleteCustomersSuccess extends DeleteSuccessAction {
  readonly type = CustomersActionTypes.DeleteCustomersSuccess;
}

export class ResetSavedCustomer extends ResetSavedAction {
  readonly type = CustomersActionTypes.ResetSavedCustomer;
}

export class ResetDeletedCustomer extends ResetDeletedAction {
  readonly type = CustomersActionTypes.ResetDeletedCustomer;
}

export class CreateCSV implements Action {
  readonly type = CustomersActionTypes.CreateCSV;
  constructor(public payload: { conditions: any; filename: string }) {}
}

export class CSVCreated implements Action {
  readonly type = CustomersActionTypes.CSVCreated;
}

export type CustomersAction =
  | LoadCustomers
  | LoadCustomersSuccess
  | CustomersError
  | LoadSingleCustomer
  | LoadSingleCustomerSuccess
  | SaveCustomer
  | SaveCustomerSuccess
  | SelectCustomers
  | DeleteSelectedCustomers
  | DeleteCustomers
  | DeleteCustomersSuccess
  | ResetSavedCustomer
  | ResetDeletedCustomer
  | CreateCSV
  | CSVCreated;

export const fromCustomersActions = {
  LoadCustomers,
  LoadCustomersSuccess,
  CustomersError,
  LoadSingleCustomer,
  LoadSingleCustomerSuccess,
  SaveCustomer,
  SaveCustomerSuccess,
  SelectCustomers,
  DeleteSelectedCustomers,
  DeleteCustomers,
  DeleteCustomersSuccess,
  ResetSavedCustomer,
  ResetDeletedCustomer,
  CreateCSV,
  CSVCreated
};
