import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  CUSTOMERS_FEATURE_KEY,
  customersInitialState,
  customersReducer
} from './+state/customers.reducer';
import { CustomersEffects } from './+state/customers.effects';
import { CustomersFacade } from './+state/customers.facade';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(CUSTOMERS_FEATURE_KEY, customersReducer, {
      initialState: customersInitialState
    }),
    EffectsModule.forFeature([CustomersEffects])
  ],
  providers: [CustomersFacade]
})
export class NgrxCustomersModule {}
