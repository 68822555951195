import { SKU } from '@doe/types';

import {
  AbstractState,
  setDeleteSelectedState,
  setLoadSingleSuccessState,
  setLoadSuccessState,
  setSaveState,
  setSelectedState,
  setResetSavedState,
  setResetDeletedState
} from '../../abstract/reducers';
import { SkusAction, SkusActionTypes } from './skus.actions';

export const SKUS_FEATURE_KEY = 'Skus';

export interface SkusState extends AbstractState<SKU> {
  csvCreated?: boolean;
}

export interface SkusPartialState {
  readonly [SKUS_FEATURE_KEY]: SkusState;
}

export const skusInitialState: SkusState = {
  list: [],
  loaded: false
};

export function skusReducer(
  state: SkusState = skusInitialState,
  action: SkusAction
): SkusState {
  switch (action.type) {
    case SkusActionTypes.LoadSkusSuccess: {
      state = setLoadSuccessState(state, action);
      break;
    }

    case SkusActionTypes.LoadSingleCustomerSuccess: {
      state = setLoadSingleSuccessState(state, action);
      break;
    }

    case SkusActionTypes.SaveCustomerSuccess: {
      state = setSaveState(state, action);
      break;
    }

    case SkusActionTypes.SelectSkus: {
      state = setSelectedState(state, action);
      break;
    }

    case SkusActionTypes.DeleteSkusSuccess: {
      state = setDeleteSelectedState(state, action);
      break;
    }

    case SkusActionTypes.ResetSavedCustomer: {
      state = setResetSavedState(state, action);
      break;
    }

    case SkusActionTypes.ResetDeletedCustomer: {
      state = setResetDeletedState(state, action);
      break;
    }
  }
  return state;
}
