import { Observable } from 'rxjs';

export class BasicTableColumn<T> {
  id: string;
  title: string;
  type: 'text' | 'link' | 'icon' | 'date' = 'text';
  sortField?: string;
  value?(row: T): any | Observable<any>; // return cell value
  routerLink?(row: T): string[]; // return routes link
  asyncValue?: boolean;
}
