import { Component, Input } from '@angular/core';

import { BasicTableColumn } from './basic-table-column';

@Component({
  selector: 'doe-table-cell',
  template: `
    <ng-container [ngSwitch]="col.type">
      <ng-container *ngSwitchCase="'icon'">
        <mat-icon>
          {{ col.asyncValue ? (col.value(row) | async) : col.value(row) }}
        </mat-icon>
      </ng-container>
      <ng-container *ngSwitchCase="'link'">
        <a [routerLink]="col.routerLink(row)">
          {{ col.asyncValue ? (col.value(row) | async) : col.value(row) }}
        </a>
      </ng-container>
      <ng-container *ngSwitchCase="'date'">
        {{
          col.asyncValue
            ? (col.value(row) | async | date: 'medium')
            : (col.value(row) | date: 'medium')
        }}
      </ng-container>
      <ng-container *ngSwitchDefault>
        {{ col.asyncValue ? (col.value(row) | async) : col.value(row) }}
      </ng-container>
    </ng-container>
  `
})
export class BasicTableCellComponent {
  @Input() col: BasicTableColumn<any>;
  @Input() row: any;
}
