import { Injectable } from '@angular/core';
import { NgrxFacadeDAO } from '@doe/client/dao';
import { APIFindOptions, ApiQueryResponse, SKU } from '@doe/types';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import {
  DeleteSkus,
  DeleteSelectedSkus,
  LoadSkus,
  LoadSingleCustomer,
  SaveCustomer,
  SelectSkus
} from './skus.actions';
import { SkusPartialState } from './skus.reducer';
import { skusQuery } from './skus.selectors';

@Injectable()
export class SkusFacade implements NgrxFacadeDAO<SKU> {
  constructor(private store: Store<SkusPartialState>) {}

  results$: Observable<ApiQueryResponse<SKU>> = this.store.select(
    skusQuery.featureSelector
  );
  loaded$: Observable<boolean> = this.store.select(skusQuery.getLoaded);
  single$?: Observable<SKU> = this.store.select(skusQuery.getSingle);
  singleLoaded$?: Observable<boolean> = this.store.select(
    skusQuery.getSingleLoaded
  );
  error$?: Observable<any> = this.store.select(skusQuery.getError);
  deleteSelected$?: Observable<any> = this.store.select(skusQuery.getDeleted);
  selected$: Observable<SKU[]> = this.store.select(skusQuery.getSelected);
  saved$: Observable<boolean> = this.store.select(skusQuery.getSaved);
  csvCreated$: Observable<boolean> = this.store.select(skusQuery.csvCreated);

  load(conditions?: any, options?: APIFindOptions) {
    this.store.dispatch(new LoadSkus({ conditions, options }));
  }

  loadSingle(conditions: any) {
    this.store.dispatch(new LoadSingleCustomer({ conditions }));
  }

  save(data: SKU) {
    this.store.dispatch(new SaveCustomer(data));
  }

  delete(id: any) {
    this.store.dispatch(new DeleteSkus({ id }));
  }

  deleteSelected() {
    this.store.dispatch(new DeleteSelectedSkus());
  }

  setSelected(selected: SKU[]) {
    this.store.dispatch(new SelectSkus(selected));
  }
}
