import { Store } from '@doe/types';

import { NgrxFacadeDAO } from '../../../ngrx-facade-dao.interface';
import { NgrxFacadeDataSource } from './ngrx-facade-dao-data-source';

export class StoresNgrxDataSource extends NgrxFacadeDataSource<Store> {
  constructor(facade: NgrxFacadeDAO<Store>) {
    super(facade);
  }
}
