import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Store as AppStore } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
// import { CountriesService } from '../../countries/countries.service';

import { SnackService } from '../../snack/snack.service';
import { Store } from '@doe/types';
// import { StoresService } from '../../stores/stores.service';
// import { BarcodeScannerComponent } from '../barcode-scanner/barcode-scanner.component';
import { Catalog, SKU } from '@doe/types';
import {
  CatalogsFacade,
  StoresFacade,
  SkusFacade,
  AuthFacade
} from '@doe/client/ngrx';
import { CountriesService } from '../../countries/countries.service';
// import { CatalogService } from '../catalog.service';

@Component({
  selector: 'app-catalog-search',
  templateUrl: './catalog-search.component.html',
  styleUrls: ['./catalog-search.component.scss']
})
export class CatalogSearchComponent implements OnInit, OnDestroy {
  catalog: Catalog;
  skus: SKU[] = [];
  searchText = '';
  modelFilterControl = new FormControl();
  sizeFilterControl = new FormControl([]);
  colorFilterControl = new FormControl([]);
  familyFilterControl = new FormControl([]);
  lineFilterControl = new FormControl([]);
  storeFilterControl = new FormControl([]);
  seasonFilterControl = new FormControl([]);

  stores: Store[] = [];
  selectedModel: any;
  selectedColor: any;
  selectedSize: any;

  // visible controls:

  modelVisible = true;
  colorVisible = false;
  sizeVisible = false;
  availabilityVisible = false;

  authSub: Subscription;
  storeSub: Subscription;
  catalogSub: Subscription;

  availabilitySub: Subscription;
  availabilities: any[] = [];
  userStore: Store;
  searchRunning: boolean;

  searchSub: Subscription;

  constructor(
    private auth: AuthFacade,
    private catalogFacade: CatalogsFacade,
    private storesFacade: StoresFacade,
    private skusFacade: SkusFacade,
    private countries: CountriesService,
    private modal: MatDialog,
    private route: ActivatedRoute,
    private snack: SnackService
  ) {}

  get sizes(): any[] {
    return this.catalog && this.catalog.sizes
      ? this.catalog.sizes.sort((a, b) => (a.position > b.position ? 1 : -1))
      : [];
  }

  get displaySizes(): any[] {
    if (!this.selectedModel || !this.selectedColor) {
      return [];
    }
    const filtered = this.sizeFilterControl.value || [];
    return filtered.length === 0
      ? this.selectedColor.sizes
      : this.selectedColor.sizes.filter(s => {
          return this.sizeFilterControl.value.includes(s._id);
        });
  }

  get colors(): any[] {
    return this.catalog && this.catalog.colors
      ? this.catalog.colors.sort((a, b) =>
          a.description > b.description ? 1 : -1
        )
      : [];
  }

  get displayColors(): any[] {
    if (!this.selectedModel) {
      return [];
    }
    const filtered = this.colorFilterControl.value || [];
    return filtered.length === 0
      ? this.selectedModel.colors
      : this.selectedModel.colors.filter(c => {
          return this.colorFilterControl.value.includes(c._id);
        });
  }

  get families(): any[] {
    return this.catalog && this.catalog.families
      ? this.catalog.families.sort((a, b) =>
          a.description > b.description ? 1 : -1
        )
      : [];
  }

  get lines(): any[] {
    return this.catalog && this.catalog.lines
      ? this.catalog.lines.sort((a, b) =>
          a.description > b.description ? 1 : -1
        )
      : [];
  }

  get seasons(): any[] {
    return this.catalog && this.catalog.seasons
      ? this.catalog.seasons.sort((a, b) =>
          a.description > b.description ? 1 : -1
        )
      : [];
  }

  ngOnInit() {
    this.catalogFacade.load();
    this.storesFacade.load({});
    let userId;
    this.authSub = this.auth.user$.subscribe(user => {
      userId = user.store;
    });
    this.storeSub = this.storesFacade.results$.subscribe(res => {
      res.list.forEach(store => {
        if (store._id === userId) {
          this.userStore = store;
        }
        this.stores = res.list;
      });
    });

    this.catalogSub = this.catalogFacade.results$.subscribe(results => {
      this.catalog = results.list[0];
    });

    this.modelFilterControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(300)
      )
      .subscribe(val => {
        this.searchByText(val);
      });
  }

  ngOnDestroy() {
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
    if (this.catalogSub) {
      this.catalogSub.unsubscribe();
    }
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }
  // change view controls:

  changeViewModel() {
    this.modelVisible = false;
    this.colorVisible = true;
    this.sizeVisible = false;
    this.availabilityVisible = false;
  }
  changeViewColor() {
    this.colorVisible = false;
    this.sizeVisible = true;
    this.availabilityVisible = false;
    this.modelVisible = false;
  }
  changeViewAval() {
    this.sizeVisible = false;
    this.availabilityVisible = true;
    this.modelVisible = false;
    this.colorVisible = false;
  }
  viewAllResults() {
    this.modelVisible = true;
    this.colorVisible = false;
    this.availabilityVisible = false;
    this.sizeVisible = false;
  }
  goToSize() {
    this.availabilityVisible = false;
    this.sizeVisible = true;
  }
  isModelInUserStore(model: any, color?: any, size?: any): boolean {
    const isSizeVisible = (sz: any) =>
      !this.sizeFilterControl.value ||
      this.sizeFilterControl.value.length === 0 ||
      this.sizeFilterControl.value.includes(sz._id);
    if (model && color && size) {
      return (
        isSizeVisible(size) &&
        (size.availabilities &&
          size.availabilities.some(
            a => a.warehouse === this.userStore.catalog.warehouseId
          ))
      );
    } else if (model && color) {
      return color.sizes.some(
        sz =>
          isSizeVisible(sz) &&
          sz.availabilities &&
          sz.availabilities.some(
            a => a.warehouse === this.userStore.catalog.warehouseId
          )
      );
    } else if (model) {
      return model.colors.some(col => {
        return col.sizes.some(
          sz =>
            isSizeVisible(sz) &&
            sz.availabilities &&
            sz.availabilities.some(
              a => a.warehouse === this.userStore.catalog.warehouseId
            )
        );
      });
    }
    return false;
  }

  private shouldSearch(): boolean {
    if (
      (this.modelFilterControl.value &&
        this.modelFilterControl.value.length > 0) ||
      (this.storeFilterControl.value &&
        this.storeFilterControl.value.length > 0) ||
      (this.colorFilterControl.value &&
        this.colorFilterControl.value.length > 0) ||
      (this.sizeFilterControl.value &&
        this.sizeFilterControl.value.length > 0) ||
      (this.familyFilterControl.value &&
        this.familyFilterControl.value.length > 0) ||
      (this.lineFilterControl.value && this.lineFilterControl.value.length > 0)
    ) {
      return true;
    }
    return false;
  }

  private filterCatalog() {
    if (this.searchSub) {
      this.searchSub.unsubscribe();
    }

    if (!this.shouldSearch()) {
      this.skus = [];
      return;
    }

    this.searchRunning = true;

    const remodel = new RegExp(`${this.modelFilterControl.value || '.*'}`);
    console.log('userstore', this.userStore.catalog.warehouseId);
    const conditions = {
      _id: { $regex: remodel.source, $options: 'gi' },
      catalog: this.catalog._id
    };

    if (this.colorFilterControl.value && this.colorFilterControl.value.length) {
      conditions['colors._id'] = { $in: this.colorFilterControl.value };
    }

    if (this.sizeFilterControl.value && this.sizeFilterControl.value.length) {
      conditions['colors.sizes._id'] = { $in: this.sizeFilterControl.value };
    }

    if (
      this.familyFilterControl.value &&
      this.familyFilterControl.value.length
    ) {
      conditions['family'] = this.familyFilterControl.value;
    }

    if (this.lineFilterControl.value && this.lineFilterControl.value.length) {
      conditions['line'] = this.lineFilterControl.value;
    }

    if (this.storeFilterControl.value && this.storeFilterControl.value.length) {
      conditions['availableInStore'] = this.storeFilterControl.value;
    }

    if (
      this.seasonFilterControl.value &&
      this.seasonFilterControl.value.length
    ) {
      conditions['season'] = this.seasonFilterControl.value;
    }

    conditions['refStoreWarehouseId'] = this.userStore.catalog.warehouseId;
    this.skusFacade.load(conditions);

    this.searchSub = this.skusFacade.results$.subscribe(results => {
      const skus = results.list;
      console.log(skus);
      this.skus = skus || [];
      this.selectModel(this.skus[0]);
      this.searchRunning = false;
    });
  }

  searchByText(searchText: string) {
    if (this.searchText !== searchText) {
      this.clearFiltersAndSearch();
      this.searchText = searchText;
      this.filterCatalog();
    }
  }

  clearSelectedFilters() {
    this.selectedModel = null;
    this.selectedSize = null;
    this.selectedColor = null;
  }

  clearFiltersAndSearch(event?) {
    this.clearSelectedFilters();
    this.filterCatalog();
  }

  selectModel(model) {
    this.selectedModel = model;
    if (model) {
      this.selectColor(this.displayColors[0]);
    }
  }

  selectColor(color) {
    this.selectedColor = color;
    if (color) {
      this.selectSize(color.sizes[0]);
    }
  }

  selectSize(size) {
    this.selectedSize = size;
    this.availabilities = this.selectedSize.availabilities || [];
  }

  getStoreName(warehouse: string): string {
    const store = this.stores.find(
      (s: any) => s.catalog.warehouseId === warehouse
    );
    return store ? store.name : '';
  }

  getStoreCity(warehouse: string): string {
    const store = this.stores.find(
      (s: any) => s.catalog.warehouseId === warehouse
    );
    return store ? store.city : '';
  }

  getStoreCountry(warehouse: string): string {
    const store = this.stores.find(
      (s: any) => s.catalog.warehouseId === warehouse
    );
    return store ? this.countries.getNameByCountryCode(store.country) : '';
  }

  isUserStore(warehouseId: string): boolean {
    return this.userStore.catalog.warehouseId === warehouseId;
  }

  onStoreSelectionChanged(event) {
    if (event.value && event.value.includes('select-all')) {
      if (this.storeFilterControl.value.length - 1 === this.stores.length) {
        this.storeFilterControl.setValue([this.userStore.catalog.warehouseId]);
      } else {
        this.storeFilterControl.setValue(
          this.stores.map(s => s.catalog.warehouseId)
        );
      }
    }
    console.log(event);
    this.clearFiltersAndSearch(event);
  }

  onSizeSelectionChanged(event) {
    if (event.value && event.value.includes('select-all')) {
      if (this.sizeFilterControl.value.length - 1 === this.sizes.length) {
        this.sizeFilterControl.reset();
      } else {
        this.sizeFilterControl.setValue(this.sizes.map(s => s.id));
      }
    }
    this.clearFiltersAndSearch(event);
  }

  onColorSelectionChanged(event) {
    if (event.value && event.value.includes('select-all')) {
      if (this.colorFilterControl.value.length - 1 === this.colors.length) {
        this.colorFilterControl.reset();
      } else {
        this.colorFilterControl.setValue(this.colors.map(s => s.id));
      }
    }
    this.clearFiltersAndSearch(event);
  }

  onLineSelectionChanged(event) {
    if (event.value && event.value.includes('select-all')) {
      if (this.lineFilterControl.value.length - 1 === this.lines.length) {
        this.lineFilterControl.reset();
      } else {
        this.lineFilterControl.setValue(this.lines.map(s => s.id));
      }
    }
    this.clearFiltersAndSearch(event);
  }

  onFamilySelectionChanged(event) {
    if (event.value && event.value.includes('select-all')) {
      if (this.familyFilterControl.value.length - 1 === this.families.length) {
        this.familyFilterControl.reset();
      } else {
        this.familyFilterControl.setValue(this.families.map(s => s.id));
      }
    }
    this.clearFiltersAndSearch(event);
  }

  onSeasonSelectionChanged(event) {
    if (event.value && event.value.includes('select-all')) {
      if (this.seasonFilterControl.value.length - 1 === this.seasons.length) {
        this.seasonFilterControl.reset();
      } else {
        this.seasonFilterControl.setValue(this.seasons.map(s => s.id));
      }
    }
    this.clearFiltersAndSearch(event);
  }
}
