import { Injectable } from '@angular/core';
import { DAOService } from '../dao-service';
import { Store } from '@doe/types';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StoresService extends DAOService<Store> {
  constructor(http: HttpClient) {
    super(http, '/api/stores');
  }
  getStoreWithForm(storeId: any) {
    return this.http.get<Store>(`${this.baseUrl}/${storeId}/form`);
  }

  testConnection(integration: any = {}): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/testint`, {
      type: integration.type,
      options: integration.options
    });
  }

  createCsv(conditions, filename) {
    conditions['filename'] = filename;
    return this.http
      .post<any[]>(`${this.baseUrl}/createcsv`, conditions)
      .pipe(take(1));
  }
}
