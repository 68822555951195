import * as jwtDecode from 'jwt-decode';
import { AuthAction, AuthActionTypes } from './auth.actions';
import { Store } from '@ngrx/store';

export const AUTH_FEATURE_KEY = 'auth';

export interface AuthState {
  isAuthenticated?: boolean;
  user?: any;
  company?: any;
  store?: any;
  token?: string;
  attemptLoginFailed?: boolean;
  error?: any;
}

export interface AuthPartialState {
  readonly [AUTH_FEATURE_KEY]: AuthState;
}

const authToken = localStorage.getItem('doe-token');
export const authInitialState: AuthState = {
  isAuthenticated: !!authToken,
  user: authToken ? jwtDecode(authToken) : null,
  token: authToken
};

export function authReducer(
  state: AuthState = authInitialState,
  action: AuthAction
): AuthState {
  switch (action.type) {
    case AuthActionTypes.LoginSuccess || AuthActionTypes.LoadSuccess: {
      state = {
        isAuthenticated: true,
        user: jwtDecode(action.payload),
        token: action.payload
      };
      break;
    }
    case AuthActionTypes.LoginError: {
      state = {
        isAuthenticated: false,
        error: action.payload
      };
      break;
    }
    case AuthActionTypes.Logout: {
      state = {
        isAuthenticated: false
      };
      break;
    }
    case AuthActionTypes.CompanyLoaded: {
      state = {
        ...state,
        company: action.payload
      };
      break;
    }
    case AuthActionTypes.StoreLoaded: {
      state = {
        ...state,
        store: action.payload
      };
      break;
    }
    case AuthActionTypes.ResetAuth: {
      state = { isAuthenticated: false };
      break;
    }
  }

  return state;
}
