import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AbstractTableComponent } from './abstract/abstract-table-component';

@Component({
  selector: 'doe-table',
  templateUrl: './basic-table.component.html',
  styleUrls: ['./basic-table.component.scss']
})
export class BasicTableComponent extends AbstractTableComponent<any> {
  constructor(translate: TranslateService) {
    super(translate);
  }

  // onSearch(cond) {
  //   const conditions = {
  //     name: { $regex: new RegExp(`${cond.name}.*`).source, $options: 'gi' },
  //     email: { $regex: new RegExp(`${cond.email}.*`).source, $options: 'gi' }
  //   };
  //   this.conditions.next(conditions);
  // }
}
