import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatRadioModule,
  MatSelectModule
} from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { SignaturePadModule } from 'angular2-signaturepad';
import { NgxSummernoteModule } from 'ngx-summernote';

import { CountriesModule } from '../../countries/countries.module';
import { PipesModule } from '../../pipes/pipes.module';
import { DynamicFieldComponent } from './dynamic-field/dynamic-field.component';
import { SysDatModule } from '../sysdat/sysdat.module';

const MATERIAL_MODULES = [
  MatFormFieldModule,
  MatInputModule,
  MatCheckboxModule,
  MatSelectModule,
  MatRadioModule,
  MatIconModule,
  MatButtonModule,
  FlexLayoutModule
];

@NgModule({
  declarations: [DynamicFieldComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ...MATERIAL_MODULES,
    CountriesModule,
    SysDatModule,
    NgxSummernoteModule,
    SignaturePadModule,
    TranslateModule.forChild(),
    PipesModule
  ],
  exports: [DynamicFieldComponent]
})
export class DynamicFieldsModule {}
