import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { DAOService } from '@doe/client/dao';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

export class AbstractDetailResolver<T> implements Resolve<T> {
  constructor(
    protected dao: DAOService<T>,
    protected router: Router,
    protected fallbackRouting: string[] = ['/']
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<T> {
    const id = route.paramMap.get('id');
    return this.dao.findOne(id).pipe(
      take(1),
      map(obj => {
        console.log(obj);
        if (obj) {
          return obj;
        } else {
          this.router.navigate(this.fallbackRouting);
          return null;
        }
      }),
      catchError(err => {
        this.router.navigate(this.fallbackRouting);
        return of(err);
      })
    );
  }
}
