import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { PermissionsFacade } from '@doe/client/ngrx';
import { map, tap, startWith } from 'rxjs/operators';
import { SnackService } from '../../snack/snack.service';

@Injectable({
  providedIn: 'root'
})
export class IsAdminGuard implements CanActivate {
  constructor(
    private router: Router,
    private permissionsFacade: PermissionsFacade,
    private snack: SnackService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return combineLatest(
      this.permissionsFacade.loaded$,
      this.permissionsFacade.isAdmin$
    ).pipe(
      startWith([]),
      map(([loaded, isAdmin]) => {
        if (loaded === undefined && isAdmin === undefined) {
          return true;
        }

        if (!isAdmin) {
          this.snack.open('Not authorized', { translateMessage: false });
          this.router.navigate(['/']);
        }
        return isAdmin;
      })
    );
  }
}
