import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  SKUS_FEATURE_KEY,
  skusInitialState,
  skusReducer
} from './+state/skus.reducer';
import { SkusEffects } from './+state/skus.effects';
import { SkusFacade } from './+state/skus.facade';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(SKUS_FEATURE_KEY, skusReducer, {
      initialState: skusInitialState
    }),
    EffectsModule.forFeature([SkusEffects])
  ],
  providers: [SkusFacade]
})
export class NgrxSkusModule {}
