import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AuthEffects } from './+state/auth.effects';
import { authInitialState, authReducer } from './+state/auth.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forRoot(
      { auth: authReducer },
      { initialState: { auth: authInitialState } }
    ),
    EffectsModule.forRoot([AuthEffects])
  ]
})
export class AuthRootStoreModule {}
