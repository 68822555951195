import {
  LoadAction,
  LoadSuccessAction,
  LoadSingleSuccessAction,
  SaveAction,
  SelectAction,
  DeleteSelectedAction,
  ResetSavedAction,
  StateErrorAction
} from './actions';

export interface AbstractState<T> {
  loaded: boolean;
  list?: T[];
  totalCount?: number;
  hasMore?: boolean;
  maxQueryLimit?: number;
  selected?: any[];
  single?: T;
  singleLoaded?: boolean;
  saved?: boolean;
  deleted?: boolean;
  error?: any;
}

export function setLoadSuccessState<T>(
  state: AbstractState<T>,
  action: LoadSuccessAction<T>
): AbstractState<T> {
  return {
    list: action.payload.list,
    totalCount: action.payload.totalCount,
    hasMore: action.payload.hasMore,
    maxQueryLimit: action.payload.maxQueryLimit,
    loaded: true,
    deleted: false,
    saved: false,
    selected: [],
    single: null,
    singleLoaded: false
  };
}

export function setLoadSingleSuccessState<T>(
  state: AbstractState<T>,
  action: LoadSingleSuccessAction<T>
): AbstractState<T> {
  return {
    ...state,
    single: action.payload,
    loaded: true
  };
}

export function setSaveState<T>(
  state: AbstractState<T>,
  action: SaveAction<T>
): AbstractState<T> {
  return {
    ...state,
    saved: !!action.payload,
    single: action.payload,
    singleLoaded: true
  };
}

export function setSelectedState<T>(
  state: AbstractState<T>,
  action: SelectAction<T>
): AbstractState<T> {
  return {
    ...state,
    selected: action.payload
  };
}

export function setDeleteSelectedState<T>(
  state: AbstractState<T>,
  action: DeleteSelectedAction
): AbstractState<T> {
  return {
    ...state,
    selected: [],
    deleted: true
  };
}

export function setResetSavedState<T>(
  state: AbstractState<T>,
  action: ResetSavedAction
): AbstractState<T> {
  return {
    ...state,
    saved: false
  };
}

export function setResetDeletedState<T>(
  state: AbstractState<T>,
  action: ResetSavedAction
): AbstractState<T> {
  return {
    ...state,
    deleted: false
  };
}

export function setErrorState<T>(
  state: AbstractState<T>,
  action: StateErrorAction
): AbstractState<T> {
  return {
    ...state,
    error: action.payload
  };
}
