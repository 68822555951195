import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormsService } from '@doe/client/dao';
import { Observable, Subscription } from 'rxjs';
import { FieldBase } from '@doe/types';
import { ErrorStateMatcher, MatSnackBar } from '@angular/material';
import {
  ControlContainer,
  FormGroupDirective,
  FormGroup
} from '@angular/forms';
import { startWith, map, count } from 'rxjs/operators';

@Component({
  selector: 'doe-sysdat-countries',
  templateUrl: './sysdat-countries.component.html',
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class SysDatCountriesComponent implements OnInit, OnDestroy {
  @Input() controlName: string;
  @Input() placeholder;
  @Input() required = false;
  @Input() errorStateMatcher: any;

  countries: any[] = [];
  countries$: Observable<any>;
  parentForm: FormGroup;
  sub: Subscription;

  constructor(
    private formService: FormsService,
    private parent: FormGroupDirective,
    private snack: MatSnackBar
  ) {
    this.parentForm = this.parent.form;
    this.sub = this.formService.getCountries().subscribe(
      countries => {
        if (countries && countries.length > 0) {
          this.countries = countries;
          // Autocompleter:
          const countryControl = this.parentForm.get('country');
          this.countries$ = countryControl.valueChanges.pipe(
            startWith(''),
            map(searchText =>
              searchText
                ? this.filterObservable(searchText)
                : this.countries.slice()
            )
          );
        } else {
          if (countries.length == 0) {
            this.snack.open('No countries found', 'Ok');
          }
        }
      },
      err => {
        if (err) {
          this.snack.open('Error retrieving countries', 'Ok');
        }
      }
    );
  }

  displayFn(code: string): string {
    if (code && this.countries && this.countries.length > 0) {
      return this.countries.find(country => country.code === code).name;
    }
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.sub ? this.sub.unsubscribe() : null;
  }

  filterObservable(searchText: string): string[] {
    const filterValue = searchText.toLowerCase();
    return this.countries.filter(
      country => country.name.toLowerCase().indexOf(filterValue) === 0
    );
  }
}
