import { Injectable } from '@angular/core';
import { FormsService } from '@doe/client/dao';
import { ApiQueryResponse, Form } from '@doe/types';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { AbstractEffects } from '../../abstract/effects';
import {
  DeleteFormsSuccess,
  DeleteSelectedForms,
  LoadForms,
  LoadFormsSuccess,
  LoadSingleForm,
  LoadSingleFormsuccess,
  FormsActionTypes,
  FormsError,
  ResetDeletedForm,
  ResetSavedForm,
  SaveForm,
  SaveFormsuccess
} from './forms.actions';
import { FORMS_FEATURE_KEY, FormsPartialState } from './forms.reducer';
import { map } from 'rxjs/operators';

@Injectable()
export class FormsEffects extends AbstractEffects<FormsPartialState, Form> {
  constructor(
    protected actions$: Actions,
    protected dataPersistence: DataPersistence<FormsPartialState>,
    protected FormsService: FormsService
  ) {
    super(actions$, dataPersistence, FormsService, FORMS_FEATURE_KEY);
  }

  @Effect()
  load$ = this.loadEffect(
    FormsActionTypes.LoadForms,
    (
      action: LoadForms,
      state: FormsPartialState,
      res: ApiQueryResponse<Form>
    ) => new LoadFormsSuccess(res),
    (action: LoadForms, error) => new FormsError(error)
  );

  @Effect()
  loadSingle$ = this.loadSingleEffect(
    FormsActionTypes.LoadSingleForm,
    (action: LoadSingleForm, state: FormsPartialState, Form: Form) =>
      new LoadSingleFormsuccess(Form),
    (action: LoadSingleForm, error) => new FormsError(error)
  );

  @Effect()
  save$ = this.saveEffect(
    FormsActionTypes.SaveForm,
    (action: SaveForm, state: FormsPartialState, Form: Form) =>
      new SaveFormsuccess(Form),
    (action: SaveForm, error) => new FormsError(error)
  );

  @Effect()
  saveSuccess$ = this.saveSuccessEffect(
    FormsActionTypes.SaveFormsuccess,
    (action: SaveFormsuccess) => new ResetSavedForm(),
    (action: SaveFormsuccess, error) => new FormsError(error)
  );

  @Effect()
  deleteSelected$ = this.deleteSelectedEffect(
    FormsActionTypes.DeleteSelectedForms,
    (action: DeleteSelectedForms, state: FormsPartialState, res) =>
      new DeleteFormsSuccess(),
    (action: DeleteSelectedForms, error) => new FormsError(error)
  );

  @Effect()
  delete$ = this.deleteEffect(
    FormsActionTypes.DeleteForms,
    (action: DeleteSelectedForms, state: FormsPartialState, res) =>
      new DeleteFormsSuccess(),
    (action: DeleteSelectedForms, error) => new FormsError(error)
  );

  // @Effect()
  // deleteSuccess$ = this.deleteSuccessEffect(
  //   FormsActionTypes.DeleteFormsSuccess,
  //   (action: DeleteFormsSuccess) => new ResetDeletedForm(),
  //   (action: DeleteFormsSuccess, error) => new FormsError(error)
  // );

  // @Effect()
  // createCsv$ = this.dataPersistence.fetch(FormsActionTypes.CreateCSV, {
  //   run: (action: CreateCSV, state: FormsPartialState) => {
  //     return this.FormsService.createCsv(action.payload.conditions, action.payload.filename).pipe(
  //       map(res => new CSVCreated())
  //     );
  //   },
  //   onError: (action: CreateCSV, error) => {
  //     console.log('Error', error);
  //     return new FormsError(error);
  //   }
  // });
}
