import { ApplicationLog } from '@doe/types';

import {
  AbstractState,
  setDeleteSelectedState,
  setLoadSingleSuccessState,
  setLoadSuccessState,
  setSaveState,
  setSelectedState,
  setResetSavedState,
  setResetDeletedState
} from '../../abstract/reducers';
import { AppLogsAction, AppLogsActionTypes } from './app-logs.actions';

export const APPLOGS_FEATURE_KEY = 'AppLogs';

export interface AppLogsState extends AbstractState<ApplicationLog> {}

export interface AppLogsPartialState {
  readonly [APPLOGS_FEATURE_KEY]: AppLogsState;
}

export const appLogsInitialState: AppLogsState = {
  list: [],
  loaded: false
};

export function appLogsReducer(
  state: AppLogsState = appLogsInitialState,
  action: AppLogsAction
): AppLogsState {
  switch (action.type) {
    case AppLogsActionTypes.LoadAppLogsSuccess: {
      state = setLoadSuccessState(state, action);
      break;
    }

    case AppLogsActionTypes.LoadSingleAppLogsuccess: {
      state = setLoadSingleSuccessState(state, action);
      break;
    }

    case AppLogsActionTypes.SaveAppLogsuccess: {
      state = setSaveState(state, action);
      break;
    }

    case AppLogsActionTypes.SelectAppLogs: {
      state = setSelectedState(state, action);
      break;
    }

    case AppLogsActionTypes.DeleteAppLogsSuccess: {
      state = setDeleteSelectedState(state, action);
      break;
    }

    case AppLogsActionTypes.ResetSavedAppLog: {
      state = setResetSavedState(state, action);
      break;
    }

    case AppLogsActionTypes.ResetDeletedAppLog: {
      state = setResetDeletedState(state, action);
      break;
    }

    case AppLogsActionTypes.ResetSavedAppLog: {
      state = {
        ...state,
        saved: false
      };
      break;
    }
  }
  return state;
}
