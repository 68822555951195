import { Injectable } from '@angular/core';
import { UsersService, TemporaryLinksService } from '@doe/client/dao'; //
import { Actions, Effect, ofType } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, take, mapTo } from 'rxjs/operators';

import {
  DeleteUsersSuccess,
  DeleteSelectedUsers,
  LoadUsers,
  LoadUsersSuccess,
  LoadSingleUser,
  LoadSingleUsersuccess,
  UsersActionTypes,
  UsersError,
  ResetDeletedUser,
  ResetSavedUser,
  SaveUser,
  SaveUsersuccess,
  GenerateChangePasswordLink,
  GenerateChangePasswordLinkSuccess,
  ValidateChangePasswordLink, //
  ValidateChangePasswordLinkSuccess, //
  ChangePassword, //
  ChangePasswordSuccess //
} from './users.actions';
import { USERS_FEATURE_KEY, UsersPartialState } from './users.reducer';
import { User, ApiQueryResponse } from '@doe/types';
import { AbstractEffects } from '../../abstract/effects';

@Injectable()
export class UsersEffects extends AbstractEffects<UsersPartialState, User> {
  constructor(
    protected actions$: Actions,
    protected dataPersistence: DataPersistence<UsersPartialState>,
    protected users: UsersService,
    protected temporaryLinks: TemporaryLinksService
  ) {
    super(actions$, dataPersistence, users, USERS_FEATURE_KEY);
  }

  @Effect()
  load$ = this.loadEffect(
    UsersActionTypes.LoadUsers,
    (
      action: LoadUsers,
      state: UsersPartialState,
      res: ApiQueryResponse<User>
    ) => new LoadUsersSuccess(res),
    (action: LoadUsers, error) => new UsersError(error)
  );

  @Effect()
  loadSingle$ = this.loadSingleEffect(
    UsersActionTypes.LoadSingleUser,
    (action: LoadSingleUser, state: UsersPartialState, User: User) =>
      new LoadSingleUsersuccess(User),
    (action: LoadSingleUser, error) => new UsersError(error)
  );

  @Effect()
  save$ = this.saveEffect(
    UsersActionTypes.SaveUser,
    (action: SaveUser, state: UsersPartialState, User: User) =>
      new SaveUsersuccess(User),
    (action: SaveUser, error) => new UsersError(error)
  );

  @Effect()
  saveSuccess$ = this.saveSuccessEffect(
    UsersActionTypes.SaveUsersuccess,
    (action: SaveUsersuccess) => new ResetSavedUser(),
    (action: SaveUsersuccess, error) => new UsersError(error)
  );

  @Effect()
  deleteSelected$ = this.deleteSelectedEffect(
    UsersActionTypes.DeleteSelectedUsers,
    (action: DeleteSelectedUsers, state: UsersPartialState, res) =>
      new DeleteUsersSuccess(),
    (action: DeleteSelectedUsers, error) => new UsersError(error)
  );

  @Effect()
  delete$ = this.deleteEffect(
    UsersActionTypes.DeleteUsers,
    (action: DeleteSelectedUsers, state: UsersPartialState, res) =>
      new DeleteUsersSuccess(),
    (action: DeleteSelectedUsers, error) => new UsersError(error)
  );

  // @Effect()
  // deleteSuccess$ = this.deleteSuccessEffect(
  //   UsersActionTypes.DeleteUsersSuccess,
  //   (action: DeleteUsersSuccess) => new ResetDeletedUser(),
  //   (action: DeleteUsersSuccess, error) => new UsersError(error)
  // );

  @Effect()
  generatePasswordLink = this.dataPersistence.optimisticUpdate(
    UsersActionTypes.GenerateChangePasswordLink,
    {
      run: (action: GenerateChangePasswordLink, state: UsersPartialState) => {
        return this.users
          .generateChangePasswordLink(action.payload.userId)
          .pipe(
            take(1),
            map(success => new GenerateChangePasswordLinkSuccess(success))
          );
      },
      undoAction: (action: GenerateChangePasswordLink, err: any) => {
        console.log('Error', err);
        return new UsersError(err);
      }
    }
  );

  @Effect()
  validateChangePasswordLink = this.dataPersistence.fetch(
    UsersActionTypes.ValidateChangePasswordLink,
    {
      run: (action: ValidateChangePasswordLink, state: UsersPartialState) => {
        return this.temporaryLinks
          .validateChangePasswordLink(action.payload.validityUUID)
          .pipe(
            take(1),
            map(success => new ValidateChangePasswordLinkSuccess(success))
          );
      },
      onError: (action: ValidateChangePasswordLink, err: any) => {
        console.log('Error', err);
        return new UsersError(err);
      }
    }
  );

  @Effect()
  changePassword = this.dataPersistence.pessimisticUpdate(
    UsersActionTypes.ChangePassword,
    {
      run: (action: ChangePassword, state: UsersPartialState) => {
        if (!state.Users.passwordChanged) {
          return this.users
            .changePassword(
              action.payload.userId,
              action.payload.oldPassword,
              action.payload.newPassword
            )
            .pipe(
              take(1),
              map(success => new ChangePasswordSuccess(success))
            );
        } else {
          return new ChangePasswordSuccess(false);
        }
      },
      onError: (action: ChangePassword, err: any) => {
        console.log('Error', err);
        return new UsersError(err);
      }
    }
  );

  @Effect() resetSaved$ = this.actions$.pipe(
    ofType(UsersActionTypes.SaveUsersuccess),
    mapTo(new ResetSavedUser())
  );
}
