import { SysDatCountriesComponent } from './countries/sysdat-countries.component';
import { SysDatRegionsComponent } from './regions/sysdat-regions.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../../pipes/pipes.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  MatSelectModule,
  MatInputModule,
  MatFormFieldModule,
  MatAutocompleteModule
} from '@angular/material';

const MATERIAL_MODULES = [
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatAutocompleteModule
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    TranslateModule,
    ...MATERIAL_MODULES,
    PipesModule
  ],
  declarations: [SysDatCountriesComponent, SysDatRegionsComponent],
  exports: [SysDatRegionsComponent, SysDatCountriesComponent]
})
export class SysDatModule {}
