import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'doe-confirm-dialog',
  templateUrl: 'confirm-dialog.component.html',
  styleUrls: ['confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  title = 'Please confirm';
  message = 'Are you sure you want to proceed?';
  proceedButton = 'Yes';
  cancelButton = 'No';

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    if (this.data) {
      if (this.data.title) {
        this.title = this.data.title;
      }
      if (this.data.message) {
        this.message = this.data.message;
      }
      this.proceedButton = this.data.proceedBtn || 'Yes';
      this.cancelButton = this.data.cancelBtn || 'No';
    }
  }
}
