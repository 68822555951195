import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material';

@Component({
  selector: 'doe-confirm-bottom-sheet',
  templateUrl: './confirm-bottom-sheet.component.html',
  styleUrls: ['./confirm-bottom-sheet.component.scss']
})
export class ConfirmBottomSheetComponent implements OnInit {
  title = 'Please confirm';
  message = 'Are you sure you want to proceed?';
  proceedButton = 'Yes';
  cancelButton = 'No';

  constructor(
    private bottomSheetRef: MatBottomSheetRef<ConfirmBottomSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) private data: any
  ) {}

  ngOnInit() {
    if (this.data) {
      if (this.data.title) {
        this.title = this.data.title;
      }
      if (this.data.message) {
        this.message = this.data.message;
      }
      this.proceedButton = this.data.proceedBtn || 'Yes';
      this.cancelButton = this.data.cancelBtn || 'No';
    }
  }
}
