import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  COMPANIES_FEATURE_KEY,
  companiesInitialState,
  companiesReducer
} from './+state/companies.reducer';
import { CompaniesEffects } from './+state/companies.effects';
import { CompaniesFacade } from './+state/companies.facade';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(COMPANIES_FEATURE_KEY, companiesReducer, {
      initialState: companiesInitialState
    }),
    EffectsModule.forFeature([CompaniesEffects])
  ],
  providers: [CompaniesFacade]
})
export class NgrxCompaniesModule {}
