import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Customer } from '@doe/types';

import { DAOService } from '../dao-service';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomersService extends DAOService<Customer> {
  constructor(http: HttpClient) {
    super(http, '/api/customers');
  }

  createCsv(conditions, filename) {
    conditions['filename'] = filename;
    return this.http
      .post<any[]>(`${this.baseUrl}/createcsv`, conditions)
      .pipe(take(1));
  }
}
