import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { UsersPartialState } from './users.reducer';
import { usersQuery } from './users.selectors';
import {
  DeleteUsers,
  DeleteSelectedUsers,
  LoadUsers,
  LoadSingleUser,
  SaveUser,
  SelectUsers,
  GenerateChangePasswordLink,
  ValidateChangePasswordLink,
  ChangePassword
} from './users.actions';
import { NgrxFacadeDAO } from '@doe/client/dao';
import { User, APIFindOptions, ApiQueryResponse } from '@doe/types';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class UsersFacade implements NgrxFacadeDAO<User> {
  results$: Observable<ApiQueryResponse<User>> = this.User.select(
    usersQuery.featureSelector
  );
  loaded$: Observable<boolean> = this.User.select(usersQuery.getLoaded);
  single$?: Observable<User> = this.User.select(usersQuery.getSingle);
  singleLoaded$?: Observable<boolean> = this.User.select(
    usersQuery.getSingleLoaded
  );
  error$?: Observable<any> = this.User.select(usersQuery.getError);
  deleteSelected$?: Observable<any> = this.User.select(usersQuery.getDeleted);
  selected$: Observable<User[]> = this.User.select(usersQuery.getSelected);
  saved$: Observable<boolean> = this.User.select(usersQuery.getSaved);
  ////
  changePasswordLinkGenerated$ = this.User.pipe(
    select(usersQuery.getChangePasswordLinkGenerated)
  );
  changePasswordLinkValid$ = this.User.pipe(
    select(usersQuery.getChangePasswordLinkValid)
  );
  passwordChanged$ = this.User.pipe(select(usersQuery.getPasswordChanged));

  constructor(private User: Store<UsersPartialState>) {}

  load(conditions?: any, options?: APIFindOptions) {
    this.User.dispatch(
      new LoadUsers({
        conditions,
        options
      })
    );
  }

  loadSingle(conditions: any) {
    this.User.dispatch(
      new LoadSingleUser({
        conditions
      })
    );
  }

  save(data: User) {
    this.User.dispatch(new SaveUser(data));
  }

  delete(id: any) {
    this.User.dispatch(
      new DeleteUsers({
        id
      })
    );
  }

  deleteSelected() {
    this.User.dispatch(new DeleteSelectedUsers());
  }

  setSelected(selected: User[]) {
    this.User.dispatch(new SelectUsers(selected));
  }
  ////
  generateChangePasswordLink(userId: any) {
    this.User.dispatch(
      new GenerateChangePasswordLink({
        userId
      })
    );
  }
  ////
  validateChangePasswordLink(validityUUID: any) {
    this.User.dispatch(
      new ValidateChangePasswordLink({
        validityUUID
      })
    );
  }
  ////
  changePassword(userId: any, oldPassword: string, newPassword: string) {
    console.log('CHANGE PASSWORD');
    this.User.dispatch(
      new ChangePassword({
        userId,
        oldPassword,
        newPassword
      })
    );
  }
}
