import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Company, Store, APIFindOptions, ApiQueryResponse } from '@doe/types';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { EMPTY, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

import {
  AuthActionTypes,
  CompanyLoaded,
  LoadError,
  LoadSuccess,
  Login,
  LoginError,
  LoginSuccess,
  Logout,
  StoreLoaded,
  ResetAuth
} from './auth.actions';
import { AuthPartialState } from './auth.reducer';
import { StoresService } from '@doe/client/dao';

@Injectable()
export class AuthEffects {
  constructor(
    private http: HttpClient,
    private storeService: StoresService,
    private actions$: Actions,
    private dataPersistence: DataPersistence<AuthPartialState>
  ) {}

  @Effect()
  login$ = this.dataPersistence.fetch(AuthActionTypes.Login, {
    run: (action: Login, state: AuthPartialState) => {
      localStorage.removeItem('doe-token');
      return this.http
        .post<{ token: string }>('/auth/signin', action.payload)
        .pipe(
          take(1),
          map(res => {
            if (res && res.token) {
              localStorage.setItem('doe-token', res.token);
              return new LoginSuccess(res.token);
            } else {
              return new LoginError(
                new Error('Unable to login: no token received!')
              );
            }
          })
        );
    },
    onError: (action: Login, error) => {
      console.log('Error', error);
      return new LoginError(error);
    }
  });

  @Effect()
  load$ = this.dataPersistence.fetch(AuthActionTypes.Load, {
    run: (action: Login, state: AuthPartialState) => {
      try {
        const token = localStorage.getItem('doe-token');
        if (token) {
          return new LoadSuccess(token);
        } else {
          return new ResetAuth();
        }
      } catch (error) {
        return new LoadError(error);
      }
    },

    onError: (action: Login, error) => {
      console.log('Error', error);
      return new LoadError(error);
    }
  });

  @Effect()
  logout$ = this.dataPersistence.fetch(AuthActionTypes.Logout, {
    run: (action: Logout, state: AuthPartialState) => {
      localStorage.removeItem('doe-token');
      return new ResetAuth();
    },
    onError: (action: Logout, error) => {
      console.log('Lgout error', error);
    }
  });

  @Effect()
  loadCompany = this.dataPersistence.fetch(AuthActionTypes.LoadCompany, {
    run: (action: Login, state: AuthPartialState) => {
      try {
        if (state.auth.user && state.auth.user.company) {
          return this.http
            .get<Company>(`/api/companies/${state.auth.user.company}`)
            .pipe(
              take(1),
              map(company => new CompanyLoaded(company))
            );
        } else {
          return EMPTY;
        }
      } catch (error) {
        return new LoadError(error);
      }
    },

    onError: (action: Login, error) => {
      console.log('Error', error);
      return new LoadError(error);
    }
  });

  @Effect()
  loadStore = this.dataPersistence.fetch(AuthActionTypes.LoadStore, {
    run: (action: Login, state: AuthPartialState) => {
      try {
        if (state.auth.user && state.auth.user.store) {
          return this.http
            .post<ApiQueryResponse<Store>>(
              `/api/stores/search`,
              { _id: state.auth.user.store },
              { params: { populate: ['boardingForm'] } }
            )
            .pipe(
              take(1),
              map(store => new StoreLoaded(store.list[0]))
            );
        } else {
          return EMPTY;
        }
      } catch (error) {
        return new LoadError(error);
      }
    },

    onError: (action: Login, error) => {
      console.log('Error', error);
      return new LoadError(error);
    }
  });
}
