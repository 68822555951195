import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AuthFacade } from '../../auth/+state/auth.facade';
import {
  LoadPermissions,
  PermissionsActionTypes,
  PermissionsLoaded,
  PermissionsLoadError
} from './permissions.actions';
import { PermissionsPartialState } from './permissions.reducer';

@Injectable()
export class PermissionsEffects {
  @Effect()
  loadPermissions$ = this.dataPersistence.fetch(
    PermissionsActionTypes.LoadPermissions,
    {
      run: (action: LoadPermissions, state: PermissionsPartialState) => {
        return this.authFacade.auth$.pipe(
          switchMap(auth => {
            if (auth.isAuthenticated) {
              return this.http
                .get(`/api/users/${auth.user._id}/permissions`)
                .pipe(map((perms: any) => new PermissionsLoaded(perms)));
            } else {
              return of(
                new PermissionsLoadError(new Error('Not authenticated'))
              );
            }
          })
        );
      },
      onError: (action: LoadPermissions, error) => {
        console.error('Error', error);
        return new PermissionsLoadError(error);
      }
    }
  );

  constructor(
    private http: HttpClient,
    private actions$: Actions,
    private dataPersistence: DataPersistence<PermissionsPartialState>,
    private authFacade: AuthFacade
  ) {}
}
