import { Injectable } from '@angular/core';
import { NgrxFacadeDAO } from '@doe/client/dao';
import { APIFindOptions, ApiQueryResponse, Catalog } from '@doe/types';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import {
  DeleteCatalogs,
  DeleteSelectedCatalogs,
  LoadCatalogs,
  LoadSingleCatalog,
  SaveCatalog,
  SelectCatalogs
} from './catalogs.actions';
import { CatalogsPartialState } from './catalogs.reducer';
import { catalogsQuery } from './catalogs.selectors';

@Injectable()
export class CatalogsFacade implements NgrxFacadeDAO<Catalog> {
  constructor(private store: Store<CatalogsPartialState>) {}

  results$: Observable<ApiQueryResponse<Catalog>> = this.store.select(
    catalogsQuery.featureSelector
  );
  loaded$: Observable<boolean> = this.store.select(catalogsQuery.getLoaded);
  single$?: Observable<Catalog> = this.store.select(catalogsQuery.getSingle);
  singleLoaded$?: Observable<boolean> = this.store.select(
    catalogsQuery.getSingleLoaded
  );
  error$?: Observable<any> = this.store.select(catalogsQuery.getError);
  deleteSelected$?: Observable<any> = this.store.select(
    catalogsQuery.getDeleted
  );
  selected$: Observable<Catalog[]> = this.store.select(
    catalogsQuery.getSelected
  );
  saved$: Observable<boolean> = this.store.select(catalogsQuery.getSaved);

  load(conditions?: any, options?: APIFindOptions) {
    this.store.dispatch(new LoadCatalogs({ conditions, options }));
  }

  loadSingle(conditions: any) {
    this.store.dispatch(new LoadSingleCatalog({ conditions }));
  }

  save(data: Catalog) {
    this.store.dispatch(new SaveCatalog(data));
  }

  delete(id: any) {
    this.store.dispatch(new DeleteCatalogs({ id }));
  }

  deleteSelected() {
    this.store.dispatch(new DeleteSelectedCatalogs());
  }

  setSelected(selected: Catalog[]) {
    this.store.dispatch(new SelectCatalogs(selected));
  }
}
