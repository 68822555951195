import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { BasicCardComponent } from './basic-card.component';

@NgModule({
  declarations: [BasicCardComponent],
  imports: [CommonModule, TranslateModule, FlexLayoutModule, MatCardModule],
  exports: [BasicCardComponent]
})
export class BasicCardModule {}
