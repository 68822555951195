import { User } from '@doe/types';

import { NgrxFacadeDAO } from '../../../ngrx-facade-dao.interface';
import { NgrxFacadeDataSource } from './ngrx-facade-dao-data-source';

export class UsersNgrxDataSource extends NgrxFacadeDataSource<User> {
  constructor(facade: NgrxFacadeDAO<User>) {
    super(facade);
  }
}
