import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { NgModule, Optional } from '@angular/core';
import {
  MissingTranslationHandler,
  TranslateCompiler,
  TranslateLoader,
  TranslateModule
} from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';

import {
  createHttpTranslateLoader,
  DefaultMissingTranslationHandler
} from './helpers';

@NgModule({
  imports: [
    HttpClientModule,
    TranslateModule.forChild({
      isolate: true
    })
  ],
  exports: [TranslateModule]
})
export class I18nLazyModule {
  static forChild(
    @Optional()
    config: {
      compiler?: any;
      loaderFactory?: Function;
      missingTranslationHandler?: any;
    } = {}
  ): ModuleWithProviders {
    const loader = [];

    if (config && config.hasOwnProperty('loaderFactory')) {
      loader.push({
        provide: TranslateLoader,
        useFactory: config.loaderFactory
          ? config.loaderFactory
          : createHttpTranslateLoader,
        deps: [HttpClient]
      });
    }

    return {
      ngModule: I18nLazyModule,
      providers: [
        {
          provide: TranslateCompiler,
          useClass: config.compiler || TranslateMessageFormatCompiler
        },
        {
          provide: MissingTranslationHandler,
          useClass:
            config.missingTranslationHandler || DefaultMissingTranslationHandler
        },
        ...loader
      ]
    };
  }
}
