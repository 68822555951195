import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatFormFieldModule,
  MatInputModule
} from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';

import { CountriesAutocompleteComponent } from './countries-autocomplete/countries-autocomplete.component';
import { CountryNamePipe } from './country-name.pipe';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    TranslateModule
  ],
  declarations: [CountriesAutocompleteComponent, CountryNamePipe],
  exports: [CountriesAutocompleteComponent, CountryNamePipe]
})
export class CountriesModule {}
