import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  PROCESSES_FEATURE_KEY,
  processesInitialState,
  processesReducer
} from './+state/processes.reducer';
import { ProcessesEffects } from './+state/processes.effects';
import { ProcessesFacade } from './+state/processes.facade';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(PROCESSES_FEATURE_KEY, processesReducer, {
      initialState: processesInitialState
    }),
    EffectsModule.forFeature([ProcessesEffects])
  ],
  providers: [ProcessesFacade]
})
export class NgrxProcessesModule {}
