import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthFacade, CustomersFacade } from '@doe/client/ngrx';
import { Customer, FieldBase, FieldControlType, Form, Store } from '@doe/types';
import { TranslateService } from '@ngx-translate/core';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  Subscription
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { FormComponentCanDeactivate } from '../../../guards/data-loss/component-can-deactivate';
import { SnackService } from '../../../snack/snack.service';

@Component({
  selector: 'doe-form-renderer',
  templateUrl: './form-renderer.component.html',
  styleUrls: ['./form-renderer.component.scss']
})
export class FormRendererComponent extends FormComponentCanDeactivate
  implements OnInit, OnDestroy {
  @Input() boardingForm: Form;
  @Input() highlightField = new FieldBase<any>();
  @Input() preview: boolean;
  @Input() customer: Customer;
  direction = '';
  title: string;
  subtitle: string;
  form: FormGroup;
  fieldsNotOverwrite = [];
  lang: string;
  storeForm: Form;
  formLangs: string[];
  store$: Observable<string> = this.authFacade.auth$.pipe(
    map(auth => auth.store),
    switchMap(store => {
      if (store) {
        if (typeof store === 'string') {
          return of(store);
        } else {
          return of((<Store>store)._id);
        }
      } else {
        return this.route.queryParamMap.pipe(
          map(params => params.get('store'))
        );
      }
    })
  );

  private saveSub: Subscription;
  private dataSub: Subscription;
  private reloadFormStore = new BehaviorSubject(false);

  constructor(
    private route: ActivatedRoute,
    private snack: SnackService,
    private translate: TranslateService,
    private authFacade: AuthFacade,
    private customerFacade: CustomersFacade,
    private router: Router
  ) {
    super();
    this.authFacade.loadStore();
    this.authFacade.loadCompany();

    this.saveSub = this.customerFacade.saved$.subscribe(res => {
      if (res) {
        this.snack.open('Thank you for being registered!').subscribe();
        this.form.reset();
        this.router
          .navigate(['/store/regform/', this.boardingForm._id], {
            queryParamsHandling: 'preserve'
          })
          .then(() => this.reloadFormStore.next(true));
      }
    });
  }

  ngOnInit() {
    this.lang = this.translate.currentLang;
    if (this.boardingForm) {
      this.initForm(this.boardingForm);
      if (this.customer) {
        this.form.setControl('store', new FormControl());
        this.form.patchValue(this.customer);
        if (this.form.controls['consent.agreementAcceptance']) {
          this.form.controls['consent.agreementAcceptance'].setValue(
            this.customer.consent.agreementAcceptance
          );
        }
        if (this.form.controls['consent.dataProfiling']) {
          this.form.controls['consent.dataProfiling'].setValue(
            this.customer.consent.dataProfiling
          );
        }
        if (this.form.controls['consent.email']) {
          this.form.controls['consent.email'].setValue(
            this.customer.consent.email
          );
        }
        if (this.form.controls['consent.postmail']) {
          this.form.controls['consent.postmail'].setValue(
            this.customer.consent.postmail
          );
        }
        if (this.form.controls['consent.sms']) {
          this.form.controls['consent.sms'].setValue(this.customer.consent.sms);
        }
        if (this.form.controls['consent.telephone']) {
          this.form.controls['consent.telephone'].setValue(
            this.customer.consent.telephone
          );
        }
        if (this.form.controls['consent.usePersonalData']) {
          this.form.controls['consent.usePersonalData'].setValue(
            this.customer.consent.usePersonalData
          );
        }
        if (this.form.controls['consent.ofAge']) {
          this.form.controls['consent.ofAge'].setValue(
            this.customer.consent.ofAge
          );
        }
        if (this.form.controls['consent.thirdPartData']) {
          this.form.controls['consent.thirdPartData'].setValue(
            this.customer.consent.thirdPartData
          );
        }
        if (this.form.controls['consent.image']) {
          this.form.controls['consent.image'].setValue(
            this.customer.consent.image
          );
        }
      }
    } else {
      this.dataSub = combineLatest(this.store$, this.route.data).subscribe(
        ([storeId, data]) => {
          if (data.form && !this.form) {
            this.initForm(data.form || new Form());
          }
          if (this.customer) {
            this.form.patchValue(this.customer);
          } else {
            this.form.get('store').setValue(storeId);
          }
        }
      );
    }
    this.listenToCheckboxChanges();
  }

  ngOnDestroy() {
    if (this.saveSub) {
      this.saveSub.unsubscribe();
    }
    if (this.dataSub) {
      this.dataSub.unsubscribe();
    }
  }

  // ngOnChanges(changes) {
  //   // if (this.preview && changes.boardingForm && !changes.boardingForm.firstChange) {
  //   //   const currentValue: Form = changes.boardingForm.currentValue;
  //   //   const previousValue: Form = changes.boardingForm.previousValue;

  //   //   if (currentValue && previousValue) {
  //   //     if (!_.isEqual(currentValue, previousValue)) {
  //   //       this.initForm(currentValue)
  //   //     }
  //   //   }
  //   // }
  // }

  initForm(boardingForm?: Form) {
    this.boardingForm = boardingForm;
    this.formLangs = boardingForm.languages;
    this.form = this.dynamicFormToFormGroup(boardingForm.fields);
    this.form.setControl(
      'buttonPosition',
      new FormControl(boardingForm.buttonPosition)
    );
    this.form.setControl('_id', new FormControl(''));
    this.form.setControl('store', new FormControl(''));
  }

  submit() {
    if (this.form.valid) {
      if (!this.preview) {
        const data = this.form.getRawValue();
        if (!this.customer) {
          delete data['_id'];
        }

        delete data['static'];
        delete data['buttonPosition'];

        this.customerFacade.save(data);
      }
    } else {
      this.snack
        .open(
          'Something is still missing! Please check the form is correctly filled'
        )
        .subscribe();
    }
  }

  listenToCheckboxChanges() {
    if (!this.boardingForm) {
      return;
    }

    this.boardingForm.fields.forEach(f => {
      if (f.key === 'email' && f['consentDependent'] === true) {
        if (this.form.controls['consent.email']) {
          this.form.controls['consent.email'].valueChanges.subscribe(d => {
            if (d) {
              this.form.controls['email'].setValidators([Validators.required]);
              this.boardingForm.fields.forEach(field => {
                if (field.key === 'email') {
                  field.required = true;
                }
              });
            } else {
              this.form.controls['email'].setValidators(null);
              this.boardingForm.fields.forEach(field => {
                if (field.key === 'email') {
                  field.required = false;
                }
              });
            }
            this.form.controls['email'].updateValueAndValidity();
          });
        }
      }
      if (f.key === 'telephone' && f['consentDependent'] === true) {
        let onSms;
        let onTelephone;
        if (
          this.form.controls['consent.sms'] &&
          this.form.controls['consent.telephone']
        ) {
          this.form.controls['consent.sms'].valueChanges.subscribe(d => {
            if (d) {
              onSms = true;
            } else {
              onSms = false;
            }
            if (onTelephone === true || onSms === true) {
              this.form.controls['telephone'].setValidators([
                Validators.required
              ]);
              this.boardingForm.fields.forEach(field => {
                if (field.key === 'telephone') {
                  field.required = true;
                }
              });
            } else {
              this.form.controls['telephone'].setValidators(null);
              this.boardingForm.fields.forEach(field => {
                if (field.key === 'telephone') {
                  field.required = false;
                }
              });
            }
            this.form.controls['telephone'].updateValueAndValidity();
          });
          this.form.controls['consent.telephone'].valueChanges.subscribe(d => {
            if (d) {
              onTelephone = true;
            } else {
              onTelephone = false;
            }
            if (onTelephone === true || onSms === true) {
              this.form.controls['telephone'].setValidators([
                Validators.required
              ]);
              this.boardingForm.fields.forEach(field => {
                if (field.key === 'telephone') {
                  field.required = true;
                }
              });
            } else {
              this.form.controls['telephone'].setValidators(null);
              this.boardingForm.fields.forEach(field => {
                if (field.key === 'telephone') {
                  field.required = false;
                }
              });
            }
          });
        }

        if (
          this.form.controls['consent.sms'] &&
          this.form.controls['consent.telephone'] === undefined
        ) {
          this.form.controls['consent.sms'].valueChanges.subscribe(d => {
            if (d) {
              onSms = true;
            } else {
              onSms = false;
            }
            if (onSms === true) {
              this.form.controls['telephone'].setValidators([
                Validators.required
              ]);
              this.boardingForm.fields.forEach(field => {
                if (field.key === 'telephone') {
                  field.required = true;
                }
              });
            } else {
              this.form.controls['telephone'].setValidators(null);
              this.boardingForm.fields.forEach(field => {
                if (field.key === 'telephone') {
                  field.required = false;
                }
              });
            }
            this.form.controls['telephone'].updateValueAndValidity();
          });
        }

        if (
          this.form.controls['consent.telephone'] &&
          this.form.controls['consent.sms'] === undefined
        ) {
          this.form.controls['consent.telephone'].valueChanges.subscribe(d => {
            if (d) {
              onTelephone = true;
            } else {
              onTelephone = false;
            }
            if (onTelephone === true) {
              this.form.controls['telephone'].setValidators([
                Validators.required
              ]);
              this.boardingForm.fields.forEach(field => {
                if (field.key === 'telephone') {
                  field.required = true;
                }
              });
            } else {
              this.form.controls['telephone'].setValidators(null);
              this.boardingForm.fields.forEach(field => {
                if (field.key === 'telephone') {
                  field.required = false;
                }
              });
            }
            this.form.controls['telephone'].updateValueAndValidity();
          });
        }
      }
      if (
        f.key === 'address' ||
        f.key === 'postalCode' ||
        f.key === 'city' ||
        f.key === 'region' ||
        f.key === 'country'
      ) {
        if (this.form.controls['consent.postmail']) {
          if (f['consentDependent'] === true) {
            this.form.controls['consent.postmail'].valueChanges.subscribe(d => {
              if (d) {
                this.form.controls['address'].setValidators([
                  Validators.required
                ]);
                this.form.controls['postalCode'].setValidators([
                  Validators.required
                ]);
                this.form.controls['city'].setValidators([Validators.required]);
                this.form.controls['region'].setValidators([
                  Validators.required
                ]);
                this.form.controls['country'].setValidators([
                  Validators.required
                ]);
                this.boardingForm.fields.forEach(field => {
                  if (
                    field.key === 'address' ||
                    field.key === 'postalCode' ||
                    field.key === 'city' ||
                    field.key === 'region' ||
                    field.key === 'country'
                  ) {
                    field.required = true;
                  }
                });
              } else {
                this.form.controls['address'].setValidators(null);
                this.form.controls['postalCode'].setValidators(null);
                this.form.controls['city'].setValidators(null);
                this.form.controls['region'].setValidators(null);
                this.form.controls['country'].setValidators(null);
                this.boardingForm.fields.forEach(field => {
                  if (
                    field.key === 'address' ||
                    field.key === 'postalCode' ||
                    field.key === 'city' ||
                    field.key === 'region' ||
                    field.key === 'country'
                  ) {
                    field.required = false;
                  }
                });
              }
              this.form.controls['address'].updateValueAndValidity();
            });
          }
        }
      }
    });
  }

  onChangeLang(lang) {
    if (lang) {
      this.lang = lang;
      this.translate.use(lang);
    }
    if (lang === 'ar') {
      this.direction = 'rtl';
    } else {
      this.direction = '';
    }
  }

  dynamicFormToFormGroup(formFields: FieldBase<any>[], lang: string = 'en') {
    const group: any = {};
    formFields.forEach(field => {
      if (field.key) {
        if (field.key.startsWith('consent')) {
          const value = field.value || false;
          group[field.key] = field.required
            ? new FormControl(value, Validators.required)
            : new FormControl(value);
        } else {
          const value =
            field.controlType === FieldControlType.Static
              ? field.staticText[lang]
              : field.value;
          group[field.key] = field.required
            ? new FormControl(value || '', Validators.required)
            : new FormControl(value || '');
        }
      }
    });
    return new FormGroup(group);
  }
}
