import { createFeatureSelector, createSelector } from '@ngrx/store';

import { createDefaultQuerySelectors } from '../../abstract/selectors';
import { USERS_FEATURE_KEY, UsersState } from './users.reducer';

const getUsersState = createFeatureSelector<UsersState>(USERS_FEATURE_KEY);
const getChangePasswordLinkGenerated = createSelector(
  getUsersState,
  (state: UsersState) => state.changePasswordLinkGenerated
);

const getChangePasswordLinkValid = createSelector(
  getUsersState,
  (state: UsersState) => state.changePasswordLinkValid
);

const getPasswordChanged = createSelector(
  getUsersState,
  (state: UsersState) => state.passwordChanged
);

const usersQueryDef = createDefaultQuerySelectors(getUsersState);

export const usersQuery = {
  ...usersQueryDef,
  getChangePasswordLinkGenerated,
  getChangePasswordLinkValid,
  getPasswordChanged
};
