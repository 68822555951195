import { Injectable } from '@angular/core';
import { AppLogsService, TemporaryLinksService } from '@doe/client/dao'; //
import { Actions, Effect, ofType } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, take, mapTo } from 'rxjs/operators';

import {
  DeleteAppLogsSuccess,
  DeleteSelectedAppLogs,
  LoadAppLogs,
  LoadAppLogsSuccess,
  LoadSingleAppLog,
  LoadSingleAppLogsuccess,
  AppLogsActionTypes,
  AppLogsError,
  ResetDeletedAppLog,
  ResetSavedAppLog,
  SaveAppLog,
  SaveAppLogsuccess
} from './app-logs.actions';
import { APPLOGS_FEATURE_KEY, AppLogsPartialState } from './app-logs.reducer';
import { ApplicationLog, ApiQueryResponse } from '@doe/types';
import { AbstractEffects } from '../../abstract/effects';

@Injectable()
export class AppLogsEffects extends AbstractEffects<
  AppLogsPartialState,
  ApplicationLog
> {
  constructor(
    protected actions$: Actions,
    protected dataPersistence: DataPersistence<AppLogsPartialState>,
    protected users: AppLogsService,
    protected temporaryLinks: TemporaryLinksService
  ) {
    super(actions$, dataPersistence, users, APPLOGS_FEATURE_KEY);
  }

  @Effect()
  load$ = this.loadEffect(
    AppLogsActionTypes.LoadAppLogs,
    (
      action: LoadAppLogs,
      state: AppLogsPartialState,
      res: ApiQueryResponse<ApplicationLog>
    ) => new LoadAppLogsSuccess(res),
    (action: LoadAppLogs, error) => new AppLogsError(error)
  );

  @Effect()
  loadSingle$ = this.loadSingleEffect(
    AppLogsActionTypes.LoadSingleAppLog,
    (
      action: LoadSingleAppLog,
      state: AppLogsPartialState,
      AppLog: ApplicationLog
    ) => new LoadSingleAppLogsuccess(AppLog),
    (action: LoadSingleAppLog, error) => new AppLogsError(error)
  );

  @Effect()
  save$ = this.saveEffect(
    AppLogsActionTypes.SaveAppLog,
    (action: SaveAppLog, state: AppLogsPartialState, AppLog: ApplicationLog) =>
      new SaveAppLogsuccess(AppLog),
    (action: SaveAppLog, error) => new AppLogsError(error)
  );

  @Effect()
  saveSuccess$ = this.saveSuccessEffect(
    AppLogsActionTypes.SaveAppLogsuccess,
    (action: SaveAppLogsuccess) => new ResetSavedAppLog(),
    (action: SaveAppLogsuccess, error) => new AppLogsError(error)
  );

  @Effect()
  deleteSelected$ = this.deleteSelectedEffect(
    AppLogsActionTypes.DeleteSelectedAppLogs,
    (action: DeleteSelectedAppLogs, state: AppLogsPartialState, res) =>
      new DeleteAppLogsSuccess(),
    (action: DeleteSelectedAppLogs, error) => new AppLogsError(error)
  );

  @Effect()
  delete$ = this.deleteEffect(
    AppLogsActionTypes.DeleteAppLogs,
    (action: DeleteSelectedAppLogs, state: AppLogsPartialState, res) =>
      new DeleteAppLogsSuccess(),
    (action: DeleteSelectedAppLogs, error) => new AppLogsError(error)
  );

  // @Effect()
  // deleteSuccess$ = this.deleteSuccessEffect(
  //   AppLogsActionTypes.DeleteAppLogsSuccess,
  //   (action: DeleteAppLogsSuccess) => new ResetDeletedAppLog(),
  //   (action: DeleteAppLogsSuccess, error) => new AppLogsError(error)
  // );
  ////

  @Effect() resetSaved$ = this.actions$.pipe(
    ofType(AppLogsActionTypes.SaveAppLogsuccess),
    mapTo(new ResetSavedAppLog())
  );
}
