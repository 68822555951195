import { Injectable } from '@angular/core';
import { NgrxFacadeDAO } from '@doe/client/dao';
import { APIFindOptions, ApiQueryResponse, File } from '@doe/types';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import {
  DeleteServerFiles,
  DeleteSelectedServerFiles,
  LoadServerFiles,
  LoadSingleServerFile,
  SaveServerFile,
  SelectServerFiles,
  DownloadSelectedServerFile
} from './server-files.actions';
import { ServerFilesPartialState } from './server-files.reducer';
import { serverFilesQuery } from './server-files.selectors';

@Injectable()
export class ServerFilesFacade implements NgrxFacadeDAO<File> {
  constructor(private store: Store<ServerFilesPartialState>) {}

  results$: Observable<ApiQueryResponse<File>> = this.store.select(
    serverFilesQuery.featureSelector
  );
  loaded$: Observable<boolean> = this.store.select(serverFilesQuery.getLoaded);
  single$?: Observable<File> = this.store.select(serverFilesQuery.getSingle);
  singleLoaded$?: Observable<boolean> = this.store.select(
    serverFilesQuery.getSingleLoaded
  );
  error$?: Observable<any> = this.store.select(serverFilesQuery.getError);
  deleteSelected$?: Observable<any> = this.store.select(
    serverFilesQuery.getDeleted
  );
  selected$: Observable<File[]> = this.store.select(
    serverFilesQuery.getSelected
  );
  saved$: Observable<boolean> = this.store.select(serverFilesQuery.getSaved);
  fileToDownload$: Observable<any> = this.store.select(
    serverFilesQuery.getFileToDownload
  );

  load(conditions?: any, options?: APIFindOptions) {
    this.store.dispatch(new LoadServerFiles({ conditions, options }));
  }

  loadSingle(conditions: any) {
    this.store.dispatch(new LoadSingleServerFile({ conditions }));
  }

  save(data: File) {
    this.store.dispatch(new SaveServerFile(data));
  }

  delete(id: any) {
    this.store.dispatch(new DeleteServerFiles({ id }));
  }

  deleteSelected() {
    this.store.dispatch(new DeleteSelectedServerFiles());
  }

  setSelected(selected: File[]) {
    this.store.dispatch(new SelectServerFiles(selected));
  }

  downloadSelectedFile() {
    this.store.dispatch(new DownloadSelectedServerFile());
  }
}
