import { Catalog } from '@doe/types';

import {
  AbstractState,
  setDeleteSelectedState,
  setLoadSingleSuccessState,
  setLoadSuccessState,
  setSaveState,
  setSelectedState,
  setResetSavedState,
  setResetDeletedState,
  setErrorState
} from '../../abstract/reducers';
import { CatalogsAction, CatalogsActionTypes } from './catalogs.actions';

export const CATALOGS_FEATURE_KEY = 'catalogs';

export interface CatalogsState extends AbstractState<Catalog> {}

export interface CatalogsPartialState {
  readonly [CATALOGS_FEATURE_KEY]: CatalogsState;
}

export const catalogsInitialState: CatalogsState = {
  list: [],
  loaded: false
};

export function catalogsReducer(
  state: CatalogsState = catalogsInitialState,
  action: CatalogsAction
): CatalogsState {
  switch (action.type) {
    case CatalogsActionTypes.LoadCatalogsSuccess: {
      state = setLoadSuccessState(state, action);
      break;
    }

    case CatalogsActionTypes.LoadSingleCatalogSuccess: {
      state = setLoadSingleSuccessState(state, action);
      break;
    }

    case CatalogsActionTypes.SaveCatalogSuccess: {
      state = setSaveState(state, action);
      break;
    }

    case CatalogsActionTypes.SelectCatalogs: {
      state = setSelectedState(state, action);
      break;
    }

    case CatalogsActionTypes.DeleteCatalogsSuccess: {
      state = setDeleteSelectedState(state, action);
      break;
    }

    case CatalogsActionTypes.ResetSavedCatalog: {
      state = setResetSavedState(state, action);
      break;
    }

    case CatalogsActionTypes.ResetDeletedCatalog: {
      state = setResetDeletedState(state, action);
      break;
    }

    case CatalogsActionTypes.CatalogsError: {
      state = setErrorState(state, action);
      break;
    }
  }
  return state;
}
