import { SKU } from '@doe/types';

import {
  DeleteAction,
  DeleteSelectedAction,
  DeleteSuccessAction,
  LoadAction,
  LoadSingleAction,
  LoadSingleSuccessAction,
  LoadSuccessAction,
  ResetSavedAction,
  SaveAction,
  SelectAction,
  StateErrorAction,
  ResetDeletedAction
} from '../../abstract/actions';
import { Action } from '@ngrx/store';

export enum SkusActionTypes {
  LoadSkus = '[Skus] Load Skus',
  LoadSkusSuccess = '[Skus] Skus Loaded',
  SkusError = '[Skus] Skus Load Error',
  LoadSingleCustomer = '[Skus] Load single Sku',
  LoadSingleCustomerSuccess = '[Skus] Load single Sku success',
  SaveCustomer = '[Proceses] Save Sku',
  SaveCustomerSuccess = '[Proceses] Save Sku success',
  SelectSkus = '[Skus] Select Skus',
  DeleteSelectedSkus = '[Skus] Delete selected',
  DeleteSelectedSkusSuccess = '[Skus] Delete selected success',
  DeleteSkus = '[Skus] Delete Skus based on conditions',
  DeleteSkusSuccess = '[Skus] Delete Skus success',
  ResetSavedCustomer = '[Skus] Reset saved state',
  ResetDeletedCustomer = '[Skus] Reset deleted state'
}

export class SkusError extends StateErrorAction {
  readonly type = SkusActionTypes.SkusError;
}

export class LoadSkus extends LoadAction {
  readonly type = SkusActionTypes.LoadSkus;
}

export class LoadSkusSuccess extends LoadSuccessAction<SKU> {
  readonly type = SkusActionTypes.LoadSkusSuccess;
}

export class LoadSingleCustomer extends LoadSingleAction<SKU> {
  readonly type = SkusActionTypes.LoadSingleCustomer;
}

export class LoadSingleCustomerSuccess extends LoadSingleSuccessAction<SKU> {
  readonly type = SkusActionTypes.LoadSingleCustomerSuccess;
}

export class SaveCustomer extends SaveAction<SKU> {
  readonly type = SkusActionTypes.SaveCustomer;
}

export class SaveCustomerSuccess extends SaveAction<SKU> {
  readonly type = SkusActionTypes.SaveCustomerSuccess;
}

export class SelectSkus extends SelectAction<SKU> {
  readonly type = SkusActionTypes.SelectSkus;
}

export class DeleteSelectedSkus extends DeleteSelectedAction {
  readonly type = SkusActionTypes.DeleteSelectedSkus;
}

export class DeleteSkus extends DeleteAction {
  readonly type = SkusActionTypes.DeleteSkus;
}

export class DeleteSkusSuccess extends DeleteSuccessAction {
  readonly type = SkusActionTypes.DeleteSkusSuccess;
}

export class ResetSavedCustomer extends ResetSavedAction {
  readonly type = SkusActionTypes.ResetSavedCustomer;
}

export class ResetDeletedCustomer extends ResetDeletedAction {
  readonly type = SkusActionTypes.ResetDeletedCustomer;
}

export type SkusAction =
  | LoadSkus
  | LoadSkusSuccess
  | SkusError
  | LoadSingleCustomer
  | LoadSingleCustomerSuccess
  | SaveCustomer
  | SaveCustomerSuccess
  | SelectSkus
  | DeleteSelectedSkus
  | DeleteSkus
  | DeleteSkusSuccess
  | ResetSavedCustomer
  | ResetDeletedCustomer;

export const fromSkusActions = {
  LoadSkus,
  LoadSkusSuccess,
  SkusError,
  LoadSingleCustomer,
  LoadSingleCustomerSuccess,
  SaveCustomer,
  SaveCustomerSuccess,
  SelectSkus,
  DeleteSelectedSkus,
  DeleteSkus,
  DeleteSkusSuccess,
  ResetSavedCustomer,
  ResetDeletedCustomer
};
