import { File } from '@doe/types';

import {
  DeleteAction,
  DeleteSelectedAction,
  DeleteSuccessAction,
  LoadAction,
  LoadSingleAction,
  LoadSingleSuccessAction,
  LoadSuccessAction,
  ResetSavedAction,
  SaveAction,
  SelectAction,
  StateErrorAction,
  ResetDeletedAction
} from '../../abstract/actions';
import { Action } from '@ngrx/store';

export enum ServerFilesActionTypes {
  LoadServerFiles = '[Files] Load Files',
  LoadServerFilesSuccess = '[Files] Files Loaded',
  ServerFilesError = '[Files] Files Load Error',
  LoadSingleServerFile = '[Files] Load single process',
  LoadSingleServerFileSuccess = '[Files] Load single process success',
  SaveServerFile = '[Proceses] Save process',
  SaveServerFileSuccess = '[Proceses] Save process success',
  SelectServerFiles = '[Files] Select processes',
  DeleteSelectedServerFiles = '[Files] Delete selected',
  DeleteServerFiles = '[Files] Delete processes based on conditions',
  DeleteServerFilesSuccess = '[Files] Delete processes success',
  ResetSavedServerFile = '[Files] Reset saved state',
  ResetDeletedServerFile = '[Files] Reset deleted state',
  DownloadSelectedServerFile = '[Files] Download selected',
  DownloadSelectedServerFileSuccess = '[Files] Download selected success'
}

export class ServerFilesError extends StateErrorAction {
  readonly type = ServerFilesActionTypes.ServerFilesError;
}

export class LoadServerFiles extends LoadAction {
  readonly type = ServerFilesActionTypes.LoadServerFiles;
}

export class LoadServerFilesSuccess extends LoadSuccessAction<File> {
  readonly type = ServerFilesActionTypes.LoadServerFilesSuccess;
}

export class LoadSingleServerFile extends LoadSingleAction<File> {
  readonly type = ServerFilesActionTypes.LoadSingleServerFile;
}

export class LoadSingleServerFileSuccess extends LoadSingleSuccessAction<File> {
  readonly type = ServerFilesActionTypes.LoadSingleServerFileSuccess;
}

export class SaveServerFile extends SaveAction<File> {
  readonly type = ServerFilesActionTypes.SaveServerFile;
}

export class SaveServerFileSuccess extends SaveAction<File> {
  readonly type = ServerFilesActionTypes.SaveServerFileSuccess;
}

export class SelectServerFiles extends SelectAction<File> {
  readonly type = ServerFilesActionTypes.SelectServerFiles;
}

export class DeleteSelectedServerFiles extends DeleteSelectedAction {
  readonly type = ServerFilesActionTypes.DeleteSelectedServerFiles;
}

export class DeleteServerFiles extends DeleteAction {
  readonly type = ServerFilesActionTypes.DeleteServerFiles;
}

export class DeleteServerFilesSuccess extends DeleteSuccessAction {
  readonly type = ServerFilesActionTypes.DeleteServerFilesSuccess;
}

export class ResetSavedServerFile extends ResetSavedAction {
  readonly type = ServerFilesActionTypes.ResetSavedServerFile;
}

export class ResetDeletedServerFile extends ResetDeletedAction {
  readonly type = ServerFilesActionTypes.ResetDeletedServerFile;
}

export class DownloadSelectedServerFile implements Action {
  readonly type = ServerFilesActionTypes.DownloadSelectedServerFile;
}

export class DownloadSelectedServerFileSuccess implements Action {
  readonly type = ServerFilesActionTypes.DownloadSelectedServerFileSuccess;
  constructor(public payload: { file: Blob; name: string }) {}
}

export type ServerFilesAction =
  | LoadServerFiles
  | LoadServerFilesSuccess
  | ServerFilesError
  | LoadSingleServerFile
  | LoadSingleServerFileSuccess
  | SaveServerFile
  | SaveServerFileSuccess
  | SelectServerFiles
  | DeleteSelectedServerFiles
  | DeleteServerFiles
  | DeleteServerFilesSuccess
  | ResetSavedServerFile
  | ResetDeletedServerFile
  | DownloadSelectedServerFile
  | DownloadSelectedServerFileSuccess;

export const fromServerFilesActions = {
  LoadServerFiles,
  LoadServerFilesSuccess,
  ServerFilesError,
  LoadSingleServerFile,
  LoadSingleServerFileSuccess,
  SaveServerFile,
  SaveServerFileSuccess,
  SelectServerFiles,
  DeleteSelectedServerFiles,
  DeleteServerFiles,
  DeleteServerFilesSuccess,
  ResetSavedServerFile,
  ResetDeletedServerFile,
  DownloadSelectedServerFile,
  DownloadSelectedServerFileSuccess
};
