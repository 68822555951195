import { Injectable } from '@angular/core';
import { DAOService } from '../dao-service';
import { File } from '@doe/types';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ServerFilesService extends DAOService<File> {
  constructor(http: HttpClient) {
    super(http, '/api/files');
  }

  downloadServerFile(fileId: any) {
    const conditions = {
      _id: fileId
    };
    return this.http.post<any>(`${this.baseUrl}/downloadfile`, conditions, {
      responseType: 'blob' as 'json'
    });
  }
}
