import { NgForm, FormGroup } from '@angular/forms';
import { HostListener } from '@angular/core';

export abstract class ComponentCanDeactivate {
  abstract canDeactivate(): boolean;

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    return true;
    // if (!environment.production) { return true; }
    // if (!this.canDeactivate()) {
    //     $event.returnValue = true;
    // }
  }
}

export abstract class FormComponentCanDeactivate extends ComponentCanDeactivate {
  abstract get form(): NgForm | FormGroup;

  canDeactivate(): boolean {
    if (!this.form) {
      return true;
    }
    return this.form.pristine && !this.form.dirty;
  }
}
