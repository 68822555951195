import { Action } from '@ngrx/store';

export enum PermissionsActionTypes {
  LoadPermissions = '[Permissions] Load Permissions',
  PermissionsLoaded = '[Permissions] Permissions Loaded',
  PermissionsLoadError = '[Permissions] Permissions Load Error',
  ResetPermissions = '[Permissions] Reset permissions'
}

export class LoadPermissions implements Action {
  readonly type = PermissionsActionTypes.LoadPermissions;
}

export class PermissionsLoadError implements Action {
  readonly type = PermissionsActionTypes.PermissionsLoadError;
  constructor(public payload: any) {}
}

export class PermissionsLoaded implements Action {
  readonly type = PermissionsActionTypes.PermissionsLoaded;
  constructor(public payload: { roles: string[]; permissions: any[] }) {}
}

export class ResetPermissions implements Action {
  readonly type = PermissionsActionTypes.ResetPermissions;
}

export type PermissionsAction =
  | LoadPermissions
  | PermissionsLoaded
  | PermissionsLoadError
  | ResetPermissions;

export const fromPermissionsActions = {
  LoadPermissions,
  PermissionsLoaded,
  PermissionsLoadError,
  ResetPermissions
};
