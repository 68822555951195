import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasicSearchComponent } from './basic-search.component';
import {
  MatCardModule,
  MatFormFieldModule,
  MatGridListModule,
  MatButtonModule,
  MatExpansionModule,
  MatIconModule,
  MatDividerModule
} from '@angular/material';
import { DynamicFieldsModule } from '../dynamic-fields/dynamic-fields.module';
import { ReactiveFormsModule } from '@angular/forms';
import {
  NgrxStoresModule,
  NgrxUsersModule,
  NgrxCustomersModule,
  NgrxApplicationLogsModule,
  NgrxCompaniesModule
} from '@doe/client/ngrx';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [BasicSearchComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatFormFieldModule,
    MatGridListModule,
    MatButtonModule,
    MatExpansionModule,
    MatIconModule,
    MatDividerModule,
    DynamicFieldsModule,
    ReactiveFormsModule,
    NgrxStoresModule,
    NgrxUsersModule,
    NgrxCustomersModule,
    NgrxCompaniesModule,
    NgrxApplicationLogsModule,
    FlexLayoutModule,
    TranslateModule
  ],
  exports: [BasicSearchComponent]
})
export class BasicSearchModule {}
