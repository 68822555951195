import { HttpClient } from '@angular/common/http';
import { SKU, APIFindOptions, ApiQueryResponse } from '@doe/types';

import { DAOService } from '../dao-service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SkuService extends DAOService<SKU> {
  constructor(http: HttpClient) {
    super(http, '/api/skus');
  }

  find(
    conditions?: any,
    options: APIFindOptions = {}
  ): Observable<ApiQueryResponse<SKU>> {
    // options.limit = 50;
    return super.find(conditions, options).pipe(tap(res => res));
  }
}
