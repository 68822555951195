import { File } from '@doe/types';

import {
  AbstractState,
  setDeleteSelectedState,
  setLoadSingleSuccessState,
  setLoadSuccessState,
  setSaveState,
  setSelectedState,
  setResetSavedState,
  setResetDeletedState
} from '../../abstract/reducers';
import {
  ServerFilesAction,
  ServerFilesActionTypes
} from './server-files.actions';

export const SERVERFILES_FEATURE_KEY = 'server-files';

export interface ServerFilesState extends AbstractState<File> {
  fileToDownload?: { file: Blob; name: string };
}

export interface ServerFilesPartialState {
  readonly [SERVERFILES_FEATURE_KEY]: ServerFilesState;
}

export const serverFilesInitialState: ServerFilesState = {
  list: [],
  loaded: false
};

export function serverFilesReducer(
  state: ServerFilesState = serverFilesInitialState,
  action: ServerFilesAction
): ServerFilesState {
  switch (action.type) {
    case ServerFilesActionTypes.LoadServerFilesSuccess: {
      state = setLoadSuccessState(state, action);
      break;
    }

    case ServerFilesActionTypes.LoadSingleServerFileSuccess: {
      state = setLoadSingleSuccessState(state, action);
      break;
    }

    case ServerFilesActionTypes.SaveServerFileSuccess: {
      state = setSaveState(state, action);
      break;
    }

    case ServerFilesActionTypes.SelectServerFiles: {
      state = setSelectedState(state, action);
      break;
    }

    case ServerFilesActionTypes.DeleteServerFilesSuccess: {
      state = setDeleteSelectedState(state, action);
      break;
    }

    case ServerFilesActionTypes.ResetSavedServerFile: {
      state = setResetSavedState(state, action);
      break;
    }

    case ServerFilesActionTypes.ResetDeletedServerFile: {
      state = setResetDeletedState(state, action);
      break;
    }

    case ServerFilesActionTypes.DownloadSelectedServerFileSuccess: {
      state = {
        ...state,
        fileToDownload: action.payload
      };
      break;
    }
  }
  return state;
}
