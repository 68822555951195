import { AbstractState } from './reducers';
import { createSelector, MemoizedSelector } from '@ngrx/store';

export function createGetLoadedSelector<T>(
  featureSelector: MemoizedSelector<any, AbstractState<T>>
): MemoizedSelector<any, boolean> {
  return createSelector(
    featureSelector,
    (state: AbstractState<T>) => state.loaded
  );
}

export function createGetSingleLoadedSelector<T>(
  featureSelector: MemoizedSelector<any, AbstractState<T>>
): MemoizedSelector<any, boolean> {
  return createSelector(
    featureSelector,
    (state: AbstractState<T>) => state.singleLoaded
  );
}

export function createGetErrorSelector(
  featureSelector: MemoizedSelector<any, AbstractState<any>>
): MemoizedSelector<any, boolean> {
  return createSelector(
    featureSelector,
    (state: AbstractState<any>) => state.error
  );
}

export function createGetAllSelector<T>(
  featureSelector: MemoizedSelector<any, AbstractState<T>>,
  getLoadedSelector: MemoizedSelector<any, boolean>
): MemoizedSelector<any, T[]> {
  return createSelector(
    featureSelector,
    getLoadedSelector,
    (state: AbstractState<T>, isLoaded) => {
      return isLoaded ? state.list : [];
    }
  );
}

export function createGetSelected<T>(
  featureSelector: MemoizedSelector<any, AbstractState<T>>
): MemoizedSelector<any, T[]> {
  return createSelector(
    featureSelector,
    (state: AbstractState<T>) => state.selected || []
  );
}

export function createGetSingleSelector<T>(
  featureSelector: MemoizedSelector<any, AbstractState<T>>,
  getSingleLoadedSelector: MemoizedSelector<any, boolean>
): MemoizedSelector<any, T> {
  return createSelector(
    featureSelector,
    getSingleLoadedSelector,
    (state: AbstractState<T>, isLoaded) => (isLoaded ? state.single : undefined)
  );
}

export function createGetDeletedSelector<T>(
  featureSelector: MemoizedSelector<any, AbstractState<T>>
): MemoizedSelector<any, boolean> {
  return createSelector(
    featureSelector,
    (state: AbstractState<T>) => state.deleted
  );
}

export function createGetSavedSelector<T>(
  featureSelector: MemoizedSelector<any, AbstractState<T>>
): MemoizedSelector<any, boolean> {
  return createSelector(
    featureSelector,
    (state: AbstractState<T>) => state.saved
  );
}

export function createDefaultQuerySelectors<T>(
  featureSelector: MemoizedSelector<any, AbstractState<T>>
): any {
  const getLoaded = createGetLoadedSelector<T>(featureSelector);
  const getError = createGetErrorSelector(featureSelector);
  const getAll = createGetAllSelector<T>(featureSelector, getLoaded);
  const getSingleLoaded = createGetSingleLoadedSelector<T>(featureSelector);
  const getSingle = createGetSingleSelector<T>(
    featureSelector,
    getSingleLoaded
  );
  const getDeleted = createGetDeletedSelector<T>(featureSelector);
  const getSelected = createGetSelected<T>(featureSelector);
  const getSaved = createGetSavedSelector<T>(featureSelector);

  return {
    featureSelector,
    getLoaded,
    getError,
    getAll,
    getSingle,
    getSingleLoaded,
    getDeleted,
    getSelected,
    getSaved
  };
}
