import { Form } from '@doe/types';

import {
  AbstractState,
  setDeleteSelectedState,
  setLoadSingleSuccessState,
  setLoadSuccessState,
  setSaveState,
  setSelectedState,
  setResetSavedState,
  setResetDeletedState
} from '../../abstract/reducers';
import { FormsAction, FormsActionTypes } from './forms.actions';

export const FORMS_FEATURE_KEY = 'Forms';

export interface FormsState extends AbstractState<Form> {
  csvCreated?: boolean;
}

export interface FormsPartialState {
  readonly [FORMS_FEATURE_KEY]: FormsState;
}

export const formsInitialState: FormsState = {
  list: [],
  loaded: false
};

export function formsReducer(
  state: FormsState = formsInitialState,
  action: FormsAction
): FormsState {
  switch (action.type) {
    case FormsActionTypes.LoadFormsSuccess: {
      state = setLoadSuccessState(state, action);
      break;
    }

    case FormsActionTypes.LoadSingleFormsuccess: {
      state = setLoadSingleSuccessState(state, action);
      break;
    }

    case FormsActionTypes.SaveFormsuccess: {
      state = setSaveState(state, action);
      break;
    }

    case FormsActionTypes.SelectForms: {
      state = setSelectedState(state, action);
      break;
    }

    case FormsActionTypes.DeleteFormsSuccess: {
      state = setDeleteSelectedState(state, action);
      break;
    }

    case FormsActionTypes.ResetSavedForm: {
      state = setResetSavedState(state, action);
      break;
    }

    case FormsActionTypes.ResetDeletedForm: {
      state = setResetDeletedState(state, action);
      break;
    }
  }
  return state;
}
