import { Component, OnInit, Input } from '@angular/core';
import { Observable, of } from 'rxjs';

export interface SideNavListItem {
  type?: undefined | 'divider';
  title$?: Observable<{ key: string; params?: string }>;
  routerLink$?: Observable<string>;
  routerLinkActiveOptions?: undefined | { exact: boolean };
  icon?: string;
  showIf$?: Observable<boolean>;
}

@Component({
  selector: 'doe-side-nav-list',
  templateUrl: './side-nav-list.component.html',
  styleUrls: ['./side-nav-list.component.scss']
})
export class SideNavListComponent implements OnInit {
  @Input() items: SideNavListItem[] = [];

  constructor() {}

  ngOnInit() {}
}
