import { createFeatureSelector } from '@ngrx/store';

import { createDefaultQuerySelectors } from '../../abstract/selectors';
import { COMPANIES_FEATURE_KEY, CompaniesState } from './companies.reducer';

const getCompaniesState = createFeatureSelector<CompaniesState>(
  COMPANIES_FEATURE_KEY
);

export const companiesQuery = createDefaultQuerySelectors(getCompaniesState);
