import { Company } from './company';
import { MongoDBDocument } from './mongodb-document';
import { Store } from './store';
import { IntegrationLog } from './integration-log';

export interface Customer extends MongoDBDocument {
  createdAt?: number;
  updatedAt?: number;
  externalId?: string;
  company?: string | Company;
  store?: string | Store;
  firstname?: string;
  lastname?: string;
  address?: string;
  streetNumber?: string;
  postalCode?: string;
  city?: string;
  region?: string;
  country?: string;
  telephone?: string;
  email?: string;
  title?: string;
  birthDate?: Date;
  signature?: any;
  passportNumber?: string;
  fullname?: string;
  customerType?: string;
  consent?: {
    agreementAcceptance?: boolean;
    email?: boolean;
    sms?: boolean;
    telephone?: boolean;
    dataProfiling?: boolean;
    usePersonalData?: boolean;
    postmail?: boolean;
    thirdPartData?: boolean;
    ofAge?: boolean;
    image?: boolean;
  };
  lastReplicationLog?: IntegrationLog;
}

export enum StandardCustomerKeys {
  firstname = 'firstname',
  lastname = 'lastname',
  address = 'address',
  streetNumber = 'streetNumber',
  postalCode = 'postalCode',
  city = 'city',
  region = 'region',
  country = 'country',
  telephone = 'telephone',
  email = 'email',
  signature = 'signature',
  title = 'title',
  customerType = 'customerType',
  // passportNumber = 'passportNumber',
  // birthDate = 'birthDate',
  'consent.agreementAcceptance' = 'consent.agreementAcceptance',
  'consent.email' = 'consent.email',
  'consent.sms' = 'consent.sms',
  'consent.telephone' = 'consent.telephone',
  'consent.dataProfiling' = 'consent.dataProfiling',
  'consent.usePersonalData' = 'consent.usePersonalData',
  'consent.postmail' = 'consent.postmail',
  'consent.thirdPartData' = 'consent.thirdPartData',
  'consent.ofAge' = 'consent.ofAge',
  'consent.image' = 'consent.image'
}

export enum IntegrationType {
  FTP = 'ftp',
  DB = 'db'
}
