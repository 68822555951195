import { Action } from '@ngrx/store';
import { APIFindOptions, User, ApiQueryResponse } from '@doe/types';
import {
  DeleteAction,
  DeleteSelectedAction,
  DeleteSuccessAction,
  LoadAction,
  LoadSingleAction,
  LoadSingleSuccessAction,
  LoadSuccessAction,
  ResetSavedAction,
  SaveAction,
  SelectAction,
  StateErrorAction,
  ResetDeletedAction
} from '../../abstract/actions';

export enum UsersActionTypes {
  LoadUsers = '[Users] Load Users',
  LoadUsersSuccess = '[Users] Users Loaded',
  UsersError = '[Users] Users Load Error',
  LoadSingleUser = '[Users] Load single User',
  LoadSingleUsersuccess = '[Users] Load single User success',
  SaveUser = '[Proceses] Save User',
  SaveUsersuccess = '[Proceses] Save User success',
  SelectUsers = '[Users] Select Users',
  DeleteSelectedUsers = '[Users] Delete selected',
  DeleteSelectedUsersSuccess = '[Users] Delete selected success',
  DeleteUsers = '[Users] Delete Users based on conditions',
  DeleteUsersSuccess = '[Users] Delete Users success',
  ResetSavedUser = '[Users] Reset saved state',
  ResetDeletedUser = '[Users] Reset deleted state',
  GenerateChangePasswordLink = '[Users] Generate new change password link', //
  GenerateChangePasswordLinkSuccess = '[Users] Generate new change password link success', //
  ValidateChangePasswordLink = '[Users] Validate change password link', //
  ValidateChangePasswordLinkSuccess = '[Users] Validate change password link success', //
  ChangePassword = '[Users] Change password', //
  ChangePasswordSuccess = '[Users] Change password success' //
}

export class UsersError extends StateErrorAction {
  readonly type = UsersActionTypes.UsersError;
}

export class LoadUsers extends LoadAction {
  readonly type = UsersActionTypes.LoadUsers;
}

export class LoadUsersSuccess extends LoadSuccessAction<User> {
  readonly type = UsersActionTypes.LoadUsersSuccess;
}

export class LoadSingleUser extends LoadSingleAction<User> {
  readonly type = UsersActionTypes.LoadSingleUser;
}

export class LoadSingleUsersuccess extends LoadSingleSuccessAction<User> {
  readonly type = UsersActionTypes.LoadSingleUsersuccess;
}

export class SaveUser extends SaveAction<User> {
  readonly type = UsersActionTypes.SaveUser;
}

export class SaveUsersuccess extends SaveAction<User> {
  readonly type = UsersActionTypes.SaveUsersuccess;
}

export class SelectUsers extends SelectAction<User> {
  readonly type = UsersActionTypes.SelectUsers;
}

export class DeleteSelectedUsers extends DeleteSelectedAction {
  readonly type = UsersActionTypes.DeleteSelectedUsers;
}

export class DeleteUsers extends DeleteAction {
  readonly type = UsersActionTypes.DeleteUsers;
}

export class DeleteUsersSuccess extends DeleteSuccessAction {
  readonly type = UsersActionTypes.DeleteUsersSuccess;
}

export class ResetSavedUser extends ResetSavedAction {
  readonly type = UsersActionTypes.ResetSavedUser;
}

export class ResetDeletedUser extends ResetDeletedAction {
  readonly type = UsersActionTypes.ResetDeletedUser;
}
export class GenerateChangePasswordLink implements Action {
  //
  readonly type = UsersActionTypes.GenerateChangePasswordLink;
  constructor(
    public payload: {
      userId: string;
    }
  ) {}
}

export class GenerateChangePasswordLinkSuccess implements Action {
  //
  readonly type = UsersActionTypes.GenerateChangePasswordLinkSuccess;
  constructor(public payload: boolean) {}
}

export class ValidateChangePasswordLink implements Action {
  //
  readonly type = UsersActionTypes.ValidateChangePasswordLink;
  constructor(
    public payload: {
      validityUUID: string;
    }
  ) {}
}

export class ValidateChangePasswordLinkSuccess implements Action {
  //
  readonly type = UsersActionTypes.ValidateChangePasswordLinkSuccess;
  constructor(public payload: boolean) {}
}

export class ChangePassword implements Action {
  //
  readonly type = UsersActionTypes.ChangePassword;
  constructor(
    public payload: {
      userId: string;
      oldPassword: string;
      newPassword: string;
    }
  ) {}
}

export class ChangePasswordSuccess implements Action {
  //
  readonly type = UsersActionTypes.ChangePasswordSuccess;
  constructor(public payload: boolean) {}
}

export type UsersAction =
  | LoadUsers
  | LoadUsersSuccess
  | UsersError
  | LoadSingleUser
  | LoadSingleUsersuccess
  | SaveUser
  | SaveUsersuccess
  | SelectUsers
  | DeleteSelectedUsers
  | DeleteUsers
  | DeleteUsersSuccess
  | ResetSavedUser
  | ResetDeletedUser
  | GenerateChangePasswordLink
  | GenerateChangePasswordLinkSuccess
  | ValidateChangePasswordLink
  | ValidateChangePasswordLinkSuccess
  | ChangePassword
  | ChangePasswordSuccess
  | ResetSavedUser;

export const fromUsersActions = {
  LoadUsers,
  LoadUsersSuccess,
  UsersError,
  LoadSingleUser,
  LoadSingleUsersuccess,
  SaveUser,
  SaveUsersuccess,
  SelectUsers,
  DeleteSelectedUsers,
  DeleteUsers,
  DeleteUsersSuccess,
  ResetSavedUser,
  ResetDeletedUser,
  GenerateChangePasswordLink,
  GenerateChangePasswordLinkSuccess,
  ValidateChangePasswordLink,
  ValidateChangePasswordLinkSuccess,
  ChangePassword,
  ChangePasswordSuccess
};
