import { Form } from '@doe/types';

import {
  DeleteAction,
  DeleteSelectedAction,
  DeleteSuccessAction,
  LoadAction,
  LoadSingleAction,
  LoadSingleSuccessAction,
  LoadSuccessAction,
  ResetSavedAction,
  SaveAction,
  SelectAction,
  StateErrorAction,
  ResetDeletedAction
} from '../../abstract/actions';
import { Action } from '@ngrx/store';

export enum FormsActionTypes {
  LoadForms = '[Forms] Load Forms',
  LoadFormsSuccess = '[Forms] Forms Loaded',
  FormsError = '[Forms] Forms Load Error',
  LoadSingleForm = '[Forms] Load single Form',
  LoadSingleFormsuccess = '[Forms] Load single Form success',
  SaveForm = '[Proceses] Save Form',
  SaveFormsuccess = '[Proceses] Save Form success',
  SelectForms = '[Forms] Select Forms',
  DeleteSelectedForms = '[Forms] Delete selected',
  DeleteSelectedFormsSuccess = '[Forms] Delete selected success',
  DeleteForms = '[Forms] Delete Forms based on conditions',
  DeleteFormsSuccess = '[Forms] Delete Forms success',
  ResetSavedForm = '[Forms] Reset saved state',
  ResetDeletedForm = '[Forms] Reset deleted state'
}

export class FormsError extends StateErrorAction {
  readonly type = FormsActionTypes.FormsError;
}

export class LoadForms extends LoadAction {
  readonly type = FormsActionTypes.LoadForms;
}

export class LoadFormsSuccess extends LoadSuccessAction<Form> {
  readonly type = FormsActionTypes.LoadFormsSuccess;
}

export class LoadSingleForm extends LoadSingleAction<Form> {
  readonly type = FormsActionTypes.LoadSingleForm;
}

export class LoadSingleFormsuccess extends LoadSingleSuccessAction<Form> {
  readonly type = FormsActionTypes.LoadSingleFormsuccess;
}

export class SaveForm extends SaveAction<Form> {
  readonly type = FormsActionTypes.SaveForm;
}

export class SaveFormsuccess extends SaveAction<Form> {
  readonly type = FormsActionTypes.SaveFormsuccess;
}

export class SelectForms extends SelectAction<Form> {
  readonly type = FormsActionTypes.SelectForms;
}

export class DeleteSelectedForms extends DeleteSelectedAction {
  readonly type = FormsActionTypes.DeleteSelectedForms;
}

export class DeleteForms extends DeleteAction {
  readonly type = FormsActionTypes.DeleteForms;
}

export class DeleteFormsSuccess extends DeleteSuccessAction {
  readonly type = FormsActionTypes.DeleteFormsSuccess;
}

export class ResetSavedForm extends ResetSavedAction {
  readonly type = FormsActionTypes.ResetSavedForm;
}

export class ResetDeletedForm extends ResetDeletedAction {
  readonly type = FormsActionTypes.ResetDeletedForm;
}

export type FormsAction =
  | LoadForms
  | LoadFormsSuccess
  | FormsError
  | LoadSingleForm
  | LoadSingleFormsuccess
  | SaveForm
  | SaveFormsuccess
  | SelectForms
  | DeleteSelectedForms
  | DeleteForms
  | DeleteFormsSuccess
  | ResetSavedForm
  | ResetDeletedForm;

export const fromFormsActions = {
  LoadForms,
  LoadFormsSuccess,
  FormsError,
  LoadSingleForm,
  LoadSingleFormsuccess,
  SaveForm,
  SaveFormsuccess,
  SelectForms,
  DeleteSelectedForms,
  DeleteForms,
  DeleteFormsSuccess,
  ResetSavedForm,
  ResetDeletedForm
};
