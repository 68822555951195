import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { PermissionsPartialState } from './permissions.reducer';
import { permissionsQuery } from './permissions.selectors';
import { LoadPermissions, ResetPermissions } from './permissions.actions';

@Injectable()
export class PermissionsFacade {
  loaded$ = this.store.pipe(select(permissionsQuery.getLoaded));
  all$ = this.store.pipe(select(permissionsQuery.getAll));
  roles$ = this.store.pipe(select(permissionsQuery.getRoles));
  permissions$ = this.store.pipe(select(permissionsQuery.getPermissions));
  isSystemAdmin$ = this.store.pipe(select(permissionsQuery.isSystemAdmin));
  isAdmin$ = this.store.pipe(select(permissionsQuery.isAdmin));

  constructor(private store: Store<PermissionsPartialState>) {}

  load() {
    this.store.dispatch(new LoadPermissions());
  }

  reset() {
    this.store.dispatch(new ResetPermissions());
  }
}
