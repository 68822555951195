import { Injectable } from '@angular/core';
import { NgrxFacadeDAO } from '@doe/client/dao';
import { APIFindOptions, ApiQueryResponse, Company } from '@doe/types';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import {
  DeleteCompanies,
  DeleteSelectedCompanies,
  LoadCompanies,
  LoadSingleCompany,
  SaveCompany,
  SelectCompanies
} from './companies.actions';
import { CompaniesPartialState } from './companies.reducer';
import { companiesQuery } from './companies.selectors';

@Injectable()
export class CompaniesFacade implements NgrxFacadeDAO<Company> {
  constructor(private store: Store<CompaniesPartialState>) {}

  results$: Observable<ApiQueryResponse<Company>> = this.store.select(
    companiesQuery.featureSelector
  );
  loaded$: Observable<boolean> = this.store.select(companiesQuery.getLoaded);
  single$?: Observable<Company> = this.store.select(companiesQuery.getSingle);
  singleLoaded$?: Observable<boolean> = this.store.select(
    companiesQuery.getSingleLoaded
  );
  error$?: Observable<any> = this.store.select(companiesQuery.getError);
  deleteSelected$?: Observable<any> = this.store.select(
    companiesQuery.getDeleted
  );
  selected$: Observable<Company[]> = this.store.select(
    companiesQuery.getSelected
  );
  saved$: Observable<boolean> = this.store.select(companiesQuery.getSaved);

  load(conditions?: any, options?: APIFindOptions) {
    this.store.dispatch(new LoadCompanies({ conditions, options }));
  }

  loadSingle(conditions: any) {
    this.store.dispatch(new LoadSingleCompany({ conditions }));
  }

  save(data: Company) {
    this.store.dispatch(new SaveCompany(data));
  }

  delete(id: any) {
    this.store.dispatch(new DeleteCompanies({ id }));
  }

  deleteSelected() {
    this.store.dispatch(new DeleteSelectedCompanies());
  }

  setSelected(selected: Company[]) {
    this.store.dispatch(new SelectCompanies(selected));
  }
}
