import { CollectionViewer, SelectionModel } from '@angular/cdk/collections';
import { NgrxFacadeDAO } from '@doe/client/dao';
import { APIFindOptions, MongoDBDocument } from '@doe/types';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DOEDataSource } from '../../doe-data-source.interface';

/**
 * Material CDK DataSource implementation.
 * User ngrx facades to get data
 */

export class NgrxFacadeDataSource<T extends MongoDBDocument>
  implements DOEDataSource<T> {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private sub: Subscription;

  data: T[];
  selection: SelectionModel<T> = new SelectionModel(true, []);
  loading$ = this.loadingSubject.asObservable();
  hasMore$ = this.facade.results$.pipe(map(res => res && res.hasMore));
  totalCount$ = this.facade.results$.pipe(map(res => res && res.totalCount));
  maxQueryLimit$ = this.facade.results$.pipe(
    map(res => res && res.maxQueryLimit)
  );

  private conditions: any = {};
  private options: any = {};
  constructor(protected facade: NgrxFacadeDAO<T>) {}

  /**
   * DataSource connect
   * @param collectionViewer collectionviewer
   */
  connect(collectionViewer: CollectionViewer): Observable<T[]> {
    return this.facade.results$.pipe(
      map(res => res && res.list),
      tap(list => (this.data = list))
    );
  }

  /**
   * DataSource disconnect
   * @param collectionViewer collectionviewer
   */
  disconnect(collectionViewer: CollectionViewer): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  /**
   * Load data source
   * @param conditions conditions
   * @param options options
   */
  load(conditions: any = {}, options: APIFindOptions = {}) {
    this.selection.clear();
    this.conditions = conditions;
    this.options = options;
    this.facade.load(conditions, options);
    this.loadingSubject.next(true);
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.sub = this.facade.loaded$.subscribe(res =>
      this.loadingSubject.next(res)
    );
  }

  /**
   * Set selected items in the corresponding store
   * @param data array of T
   */
  setSelected(data: T[]) {
    // console.log("set selected", data);
    this.facade.setSelected(this.selection.selected);
  }

  /**
   * Delete currently selected items in the corresponding store
   */
  deleteSelected() {
    this.facade.deleteSelected();
    this.selection.clear();
  }

  /**
   * Delete currently items in the corresponding store
   */
  delete(data: T[]) {
    // console.log("delete", data)
    this.facade.delete(data.map(d => d._id));
  }

  /**
   * Refresh dataSource with latest conditions and options
   */
  refresh() {
    this.load(this.conditions, this.options);
  }
}
