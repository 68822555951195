import { Store } from '@doe/types';

import {
  DeleteAction,
  DeleteSelectedAction,
  DeleteSuccessAction,
  LoadAction,
  LoadSingleAction,
  LoadSingleSuccessAction,
  LoadSuccessAction,
  ResetSavedAction,
  SaveAction,
  SelectAction,
  StateErrorAction,
  ResetDeletedAction
} from '../../abstract/actions';
import { Action } from '@ngrx/store';

export enum StoresActionTypes {
  LoadStores = '[Stores] Load Stores',
  LoadStoresSuccess = '[Stores] Stores Loaded',
  StoresError = '[Stores] Stores Load Error',
  LoadSingleStore = '[Stores] Load single Store',
  LoadSingleStoresuccess = '[Stores] Load single Store success',
  SaveStore = '[Proceses] Save Store',
  SaveStoresuccess = '[Proceses] Save Store success',
  SelectStores = '[Stores] Select Stores',
  DeleteSelectedStores = '[Stores] Delete selected',
  DeleteSelectedStoresSuccess = '[Stores] Delete selected success',
  DeleteStores = '[Stores] Delete Stores based on conditions',
  DeleteStoresSuccess = '[Stores] Delete Stores success',
  ResetSavedStore = '[Stores] Reset saved state',
  ResetDeletedStore = '[Stores] Reset deleted state',
  CreateCSV = '[Stores] Create CSV (server)',
  CSVCreated = '[Stores] CSV (server) created'
}

export class StoresError extends StateErrorAction {
  readonly type = StoresActionTypes.StoresError;
}

export class LoadStores extends LoadAction {
  readonly type = StoresActionTypes.LoadStores;
}

export class LoadStoresSuccess extends LoadSuccessAction<Store> {
  readonly type = StoresActionTypes.LoadStoresSuccess;
}

export class LoadSingleStore extends LoadSingleAction<Store> {
  readonly type = StoresActionTypes.LoadSingleStore;
}

export class LoadSingleStoresuccess extends LoadSingleSuccessAction<Store> {
  readonly type = StoresActionTypes.LoadSingleStoresuccess;
}

export class SaveStore extends SaveAction<Store> {
  readonly type = StoresActionTypes.SaveStore;
}

export class SaveStoresuccess extends SaveAction<Store> {
  readonly type = StoresActionTypes.SaveStoresuccess;
}

export class SelectStores extends SelectAction<Store> {
  readonly type = StoresActionTypes.SelectStores;
}

export class DeleteSelectedStores extends DeleteSelectedAction {
  readonly type = StoresActionTypes.DeleteSelectedStores;
}

export class DeleteStores extends DeleteAction {
  readonly type = StoresActionTypes.DeleteStores;
}

export class DeleteStoresSuccess extends DeleteSuccessAction {
  readonly type = StoresActionTypes.DeleteStoresSuccess;
}

export class ResetSavedStore extends ResetSavedAction {
  readonly type = StoresActionTypes.ResetSavedStore;
}

export class ResetDeletedStore extends ResetDeletedAction {
  readonly type = StoresActionTypes.ResetDeletedStore;
}

export class CreateCSV implements Action {
  readonly type = StoresActionTypes.CreateCSV;
  constructor(public payload: { conditions: any; filename: string }) {}
}

export class CSVCreated implements Action {
  readonly type = StoresActionTypes.CSVCreated;
}

export type StoresAction =
  | LoadStores
  | LoadStoresSuccess
  | StoresError
  | LoadSingleStore
  | LoadSingleStoresuccess
  | SaveStore
  | SaveStoresuccess
  | SelectStores
  | DeleteSelectedStores
  | DeleteStores
  | DeleteStoresSuccess
  | ResetSavedStore
  | ResetDeletedStore
  | CreateCSV
  | CSVCreated;

export const fromStoresActions = {
  LoadStores,
  LoadStoresSuccess,
  StoresError,
  LoadSingleStore,
  LoadSingleStoresuccess,
  SaveStore,
  SaveStoresuccess,
  SelectStores,
  DeleteSelectedStores,
  DeleteStores,
  DeleteStoresSuccess,
  ResetSavedStore,
  ResetDeletedStore,
  CreateCSV,
  CSVCreated
};
