import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ConfirmBottomSheetComponent } from './confirm-bottom-sheet/confirm-bottom-sheet.component';
import {
  MatDialogModule,
  MatBottomSheetModule,
  MatButtonModule
} from '@angular/material';

@NgModule({
  declarations: [ConfirmDialogComponent, ConfirmBottomSheetComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatBottomSheetModule,
    MatButtonModule
  ],
  exports: [ConfirmDialogComponent, ConfirmBottomSheetComponent]
})
export class ModalsModule {}
