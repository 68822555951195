import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanDeactivate
} from '@angular/router';
import { MatDialog } from '@angular/material';
import { Observable, forkJoin, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ComponentCanDeactivate } from './component-can-deactivate';
import { DataLossModule } from './data-loss.module';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from '../../components/modals/confirm-dialog/confirm-dialog.component';

@Injectable({
  providedIn: DataLossModule
})
export class DataLossGuard implements CanDeactivate<ComponentCanDeactivate> {
  constructor(private modal: MatDialog, private translate: TranslateService) {}
  canDeactivate(
    component: ComponentCanDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    if (!component.canDeactivate() && nextState.url !== '/login') {
      return forkJoin(
        this.translate.get('Please confirm'),
        this.translate.get('All changes will be lost: do you want to proceed?')
      ).pipe(
        switchMap(texts => {
          const modal = this.modal.open(ConfirmDialogComponent, {
            data: {
              title: texts[0],
              message: texts[1]
            },
            position: { top: '100px' }
          });
          return modal.afterClosed().pipe(map(res => !!res));
        })
      );
    } else {
      return true;
    }
  }
}
