import { Injectable } from '@angular/core';
import { ProcessesService } from '@doe/client/dao';
import { ApiQueryResponse, Process } from '@doe/types';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { AbstractEffects } from '../../abstract/effects';
import {
  DeleteProcessesSuccess,
  DeleteSelectedProcesses,
  LoadProcesses,
  LoadProcessesSuccess,
  LoadSingleProcess,
  LoadSingleProcessSuccess,
  ProcessesActionTypes,
  ProcessesError,
  ResetSavedProcess,
  SaveProcess,
  SaveProcessSuccess
} from './processes.actions';
import {
  PROCESSES_FEATURE_KEY,
  ProcessesPartialState
} from './processes.reducer';

@Injectable()
export class ProcessesEffects extends AbstractEffects<
  ProcessesPartialState,
  Process
> {
  constructor(
    protected actions$: Actions,
    protected dataPersistence: DataPersistence<ProcessesPartialState>,
    protected processesService: ProcessesService
  ) {
    super(actions$, dataPersistence, processesService, PROCESSES_FEATURE_KEY);
  }

  @Effect()
  load$ = this.loadEffect(
    ProcessesActionTypes.LoadProcesses,
    (
      action: LoadProcesses,
      state: ProcessesPartialState,
      res: ApiQueryResponse<Process>
    ) => new LoadProcessesSuccess(res),
    (action: LoadProcesses, error) => new ProcessesError(error)
  );

  @Effect()
  loadSingle$ = this.loadSingleEffect(
    ProcessesActionTypes.LoadSingleProcess,
    (
      action: LoadSingleProcess,
      state: ProcessesPartialState,
      process: Process
    ) => new LoadSingleProcessSuccess(process),
    (action: LoadSingleProcess, error) => new ProcessesError(error)
  );

  @Effect()
  save$ = this.saveEffect(
    ProcessesActionTypes.SaveProcess,
    (action: SaveProcess, state: ProcessesPartialState, process: Process) =>
      new SaveProcessSuccess(process),
    (action: SaveProcess, error) => new ProcessesError(error)
  );

  @Effect()
  saveSuccess$ = this.saveSuccessEffect(
    ProcessesActionTypes.SaveProcessSuccess,
    (action: SaveProcessSuccess) => new ResetSavedProcess(),
    (action: SaveProcessSuccess, error) => new ProcessesError(error)
  );

  @Effect()
  deleteSelected$ = this.deleteSelectedEffect(
    ProcessesActionTypes.DeleteSelectedProcesses,
    (action: DeleteSelectedProcesses, state: ProcessesPartialState, res) =>
      new DeleteProcessesSuccess(),
    (action: DeleteSelectedProcesses, error) => new ProcessesError(error)
  );

  @Effect()
  delete$ = this.deleteEffect(
    ProcessesActionTypes.DeleteProcesses,
    (action: DeleteSelectedProcesses, state: ProcessesPartialState, res) =>
      new DeleteProcessesSuccess(),
    (action: DeleteSelectedProcesses, error) => new ProcessesError(error)
  );
}
