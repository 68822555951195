import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  FORMS_FEATURE_KEY,
  formsInitialState,
  formsReducer
} from './+state/forms.reducer';
import { FormsEffects } from './+state/forms.effects';
import { FormsFacade } from './+state/forms.facade';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(FORMS_FEATURE_KEY, formsReducer, {
      initialState: formsInitialState
    }),
    EffectsModule.forFeature([FormsEffects])
  ],
  providers: [FormsFacade]
})
export class NgrxFormsModule {}
