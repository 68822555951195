import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import * as jwtDecode from 'jwt-decode';
import { AuthFacade, UsersFacade } from '@doe/client/ngrx';
import { combineLatest, Subscription } from 'rxjs';

class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    return control.touched && control.parent.invalid;
  }
}

@Component({
  selector: 'doe-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss']
})
export class PasswordChangeComponent implements OnInit, OnDestroy {
  form: FormGroup;
  linkIsValid: boolean = false;
  passwordChanged: boolean = false;
  matcher = new MyErrorStateMatcher();
  token: boolean;
  private authSub: Subscription;
  private validSub: Subscription;
  private passwordChangedSub: Subscription;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private authFacade: AuthFacade,
    private usersFacade: UsersFacade
  ) {
    this.form = this.fb.group({
      _id: '',
      email: '',
      oldPassword: '',
      newPassword: this.fb.group(
        {
          password: ['', Validators.required],
          confirmation: ['', Validators.required]
        },
        { validator: this.passwordValidator }
      )
    });
  }

  ngOnInit() {
    const token = this.route.snapshot.queryParamMap.get('token');
    if (token) {
      this.token = true;
    } else {
      this.token = false;
    }

    const decodedLink = jwtDecode(token);

    this.form.get('_id').setValue(decodedLink._id);
    this.form.get('email').setValue(decodedLink.email);
    this.form.get('oldPassword').setValue(decodedLink.password);

    this.authFacade.login({
      email: decodedLink.email,
      password: decodedLink.password
    });

    this.authSub = this.authFacade.isAuthenticated$.subscribe(
      isAuthenticated => {
        if (isAuthenticated) {
          this.usersFacade.validateChangePasswordLink(decodedLink.validityUUID);
        }
      }
    );

    this.validSub = this.usersFacade.changePasswordLinkValid$.subscribe(
      valid => {
        this.linkIsValid = valid;
        if (!valid) {
          Object.keys(this.form.controls).forEach(key =>
            this.form.get(key).disable()
          );
        } else {
          Object.keys(this.form.controls).forEach(key =>
            this.form.get(key).enable()
          );
        }
      }
    );

    this.passwordChangedSub = this.usersFacade.passwordChanged$.subscribe(
      changed => {
        if (changed) {
          Object.keys(this.form.controls).forEach(key =>
            this.form.get(key).disable()
          );
          this.passwordChanged = true;
          this.authFacade.login({
            email: this.form.get('email').value,
            password: this.form.get('newPassword').get('password').value
          });
          this.router.navigate(['/login']);
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
    if (this.validSub) {
      this.validSub.unsubscribe();
    }
    if (this.passwordChangedSub) {
      this.passwordChangedSub.unsubscribe();
    }
  }

  changePassword() {
    this.usersFacade.changePassword(
      this.form.get('_id').value,
      this.form.get('oldPassword').value,
      this.form.get('newPassword').get('password').value
    );
  }

  passwordValidator(form: FormGroup) {
    const pass: string = form.get('password').value;
    const confirmPass = form.get('confirmation').value;

    let err = null;

    if (
      pass &&
      (pass.length < 8 ||
        !/[!@#$%^&*(),.?'":{}|<>;:°#§]/.test(pass) ||
        !/[A-Z][a-z]/.test(pass) ||
        !/[0-9]/.test(pass))
    ) {
      err = err
        ? Object.assign(err, { invalidPassword: true })
        : { invalidPassword: true };
    }

    if (pass !== confirmPass) {
      err = err
        ? Object.assign(err, { passwordsDoNotMatch: true })
        : { passwordsDoNotMatch: true };
    }

    return err;
  }
}
