import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarRef
} from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SnackService {
  constructor(
    private snack: MatSnackBar,
    private translate: TranslateService
  ) {}

  open(
    message: string = '',
    options: {
      action?: string;
      translateMessage?: boolean;
      config?: MatSnackBarConfig;
    } = {}
  ): Observable<MatSnackBarRef<any>> {
    const action = options.action || 'Ok';
    const config = Object.assign({ duration: 3000 }, options.config);
    if (options.translateMessage !== false) {
      return this.translate
        .get(message)
        .pipe(map(text => this.snack.open(message, action, config)));
    } else {
      return of(this.snack.open(message, action, config));
    }
  }
}
