import { Injectable } from '@angular/core';
import { NgrxFacadeDAO } from '@doe/client/dao';
import { APIFindOptions, ApiQueryResponse, Customer } from '@doe/types';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import {
  DeleteCustomers,
  DeleteSelectedCustomers,
  LoadCustomers,
  LoadSingleCustomer,
  SaveCustomer,
  SelectCustomers,
  CreateCSV
} from './customers.actions';
import { CustomersPartialState } from './customers.reducer';
import { customersQuery } from './customers.selectors';

@Injectable()
export class CustomersFacade implements NgrxFacadeDAO<Customer> {
  constructor(private store: Store<CustomersPartialState>) {}

  results$: Observable<ApiQueryResponse<Customer>> = this.store.select(
    customersQuery.featureSelector
  );
  loaded$: Observable<boolean> = this.store.select(customersQuery.getLoaded);
  single$?: Observable<Customer> = this.store.select(customersQuery.getSingle);
  singleLoaded$?: Observable<boolean> = this.store.select(
    customersQuery.getSingleLoaded
  );
  error$?: Observable<any> = this.store.select(customersQuery.getError);
  deleteSelected$?: Observable<any> = this.store.select(
    customersQuery.getDeleted
  );
  selected$: Observable<Customer[]> = this.store.select(
    customersQuery.getSelected
  );
  saved$: Observable<boolean> = this.store.select(customersQuery.getSaved);
  csvCreated$: Observable<boolean> = this.store.select(
    customersQuery.csvCreated
  );

  load(conditions?: any, options?: APIFindOptions) {
    this.store.dispatch(new LoadCustomers({ conditions, options }));
  }

  loadSingle(conditions: any) {
    this.store.dispatch(new LoadSingleCustomer({ conditions }));
  }

  save(data: Customer) {
    this.store.dispatch(new SaveCustomer(data));
  }

  delete(id: any) {
    this.store.dispatch(new DeleteCustomers({ id }));
  }

  deleteSelected() {
    this.store.dispatch(new DeleteSelectedCustomers());
  }

  setSelected(selected: Customer[]) {
    this.store.dispatch(new SelectCustomers(selected));
  }

  createCsv(conditions: any, filename: string) {
    this.store.dispatch(new CreateCSV({ conditions, filename }));
  }
}
