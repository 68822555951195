import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material';
import { ConfirmDialogComponent } from '../../components/modals/confirm-dialog/confirm-dialog.component';

@NgModule({
  imports: [CommonModule, TranslateModule, MatDialogModule],
  entryComponents: [ConfirmDialogComponent]
})
export class DataLossModule {}
