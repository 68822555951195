export enum FieldControlType {
  Textbox = 'Textbox',
  Dropdown = 'Dropdown',
  Checkbox = 'Checkbox',
  Radio = 'Radio',
  Static = 'Static',
  Signature = 'Signature',
  Country = 'Country',
  SysDatCountries = 'SysDatCountries',
  SysDatDistricts = 'SysDatDistricts'
}

export class FieldOption {
  key = '';
  value = { en: '' };

  constructor(key: string = '', value: any = { en: '' }) {
    this.key = key;
    this.value = value;
  }
}

export interface LanguageDependentString {
  [lang: string]: string;
}

export class FieldBase<T> {
  order?: number;
  controlType?: string;
  key?: string;
  label?: LanguageDependentString;
  required?: boolean;
  readonly?: boolean;
  consentDependent?: boolean;
  options?: FieldOption[];
  value?: T;
  staticText?: LanguageDependentString;
  type?: string;
  hint?: LanguageDependentString;
  colspan?: number;
  rowspan?: number;

  constructor(field: FieldBase<T> = {}) {
    this.label = field.label || { en: 'New field' };
    this.controlType = field.controlType || FieldControlType.Textbox;
    this.colspan = field.colspan || 1;
    this.rowspan = field.rowspan || 1;
    this.consentDependent = field.consentDependent || true;
    this.options = field.options || [];
    this.type = field.type || 'text';
    this.staticText = field.staticText || { en: '' };
    this.hint = field.hint || { en: '' };
  }
}

export class CustomFieldSap {
  sapField?: string;
  internalField?: string;
  constructor(field: CustomFieldSap = {}) {
    this.internalField = field.internalField || '';
    this.sapField = field.sapField || '';
  }
}
