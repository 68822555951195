import { Injectable } from '@angular/core';
import { ServerFilesService } from '@doe/client/dao';
import { ApiQueryResponse, File } from '@doe/types';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { AbstractEffects } from '../../abstract/effects';
import {
  DeleteServerFilesSuccess,
  DeleteSelectedServerFiles,
  LoadServerFiles,
  LoadServerFilesSuccess,
  LoadSingleServerFile,
  LoadSingleServerFileSuccess,
  ServerFilesActionTypes,
  ServerFilesError,
  ResetSavedServerFile,
  SaveServerFile,
  SaveServerFileSuccess,
  DownloadSelectedServerFile,
  DownloadSelectedServerFileSuccess
} from './server-files.actions';

import {
  SERVERFILES_FEATURE_KEY,
  ServerFilesPartialState
} from './server-files.reducer';
import { map, take, tap } from 'rxjs/operators';

@Injectable()
export class ServerFilesEffects extends AbstractEffects<
  ServerFilesPartialState,
  File
> {
  constructor(
    protected actions$: Actions,
    protected dataPersistence: DataPersistence<ServerFilesPartialState>,
    protected serverFilesService: ServerFilesService
  ) {
    super(
      actions$,
      dataPersistence,
      serverFilesService,
      SERVERFILES_FEATURE_KEY
    );
  }

  @Effect()
  load$ = this.loadEffect(
    ServerFilesActionTypes.LoadServerFiles,
    (
      action: LoadServerFiles,
      state: ServerFilesPartialState,
      res: ApiQueryResponse<File>
    ) => new LoadServerFilesSuccess(res),
    (action: LoadServerFiles, error) => new ServerFilesError(error)
  );

  @Effect()
  loadSingle$ = this.loadSingleEffect(
    ServerFilesActionTypes.LoadSingleServerFile,
    (
      action: LoadSingleServerFile,
      state: ServerFilesPartialState,
      process: File
    ) => new LoadSingleServerFileSuccess(process),
    (action: LoadSingleServerFile, error) => new ServerFilesError(error)
  );

  @Effect()
  save$ = this.saveEffect(
    ServerFilesActionTypes.SaveServerFile,
    (action: SaveServerFile, state: ServerFilesPartialState, process: File) =>
      new SaveServerFileSuccess(process),
    (action: SaveServerFile, error) => new ServerFilesError(error)
  );

  @Effect()
  saveSuccess$ = this.saveSuccessEffect(
    ServerFilesActionTypes.SaveServerFileSuccess,
    (action: SaveServerFileSuccess) => new ResetSavedServerFile(),
    (action: SaveServerFileSuccess, error) => new ServerFilesError(error)
  );

  @Effect()
  deleteSelected$ = this.deleteSelectedEffect(
    ServerFilesActionTypes.DeleteSelectedServerFiles,
    (action: DeleteSelectedServerFiles, state: ServerFilesPartialState, res) =>
      new DeleteServerFilesSuccess(),
    (action: DeleteSelectedServerFiles, error) => new ServerFilesError(error)
  );

  @Effect()
  delete$ = this.deleteEffect(
    ServerFilesActionTypes.DeleteServerFiles,
    (action: DeleteSelectedServerFiles, state: ServerFilesPartialState, res) =>
      new DeleteServerFilesSuccess(),
    (action: DeleteSelectedServerFiles, error) => new ServerFilesError(error)
  );

  @Effect()
  downloadSelected$ = this.dataPersistence.fetch(
    ServerFilesActionTypes.DownloadSelectedServerFile,
    {
      run: (
        action: DownloadSelectedServerFile,
        state: ServerFilesPartialState
      ) => {
        const file: File = state[SERVERFILES_FEATURE_KEY].selected[0];
        return this.serverFilesService.downloadServerFile(file._id).pipe(
          take(1),
          map(res => {
            return new DownloadSelectedServerFileSuccess({
              file: res,
              name: file.filename
            });
          })
        );
      },
      onError: (action: DownloadSelectedServerFile, error) => {
        console.log('Error', error);
        return new ServerFilesError(error);
      }
    }
  );
}
