import { User } from '@doe/types';

import {
  AbstractState,
  setDeleteSelectedState,
  setLoadSingleSuccessState,
  setLoadSuccessState,
  setSaveState,
  setSelectedState,
  setResetSavedState,
  setResetDeletedState
} from '../../abstract/reducers';
import { UsersAction, UsersActionTypes } from './users.actions';

export const USERS_FEATURE_KEY = 'Users';

export interface UsersState extends AbstractState<User> {
  changePasswordLinkGenerated?: boolean;
  changePasswordLinkValid?: boolean;
  passwordChanged?: boolean;
}

export interface UsersPartialState {
  readonly [USERS_FEATURE_KEY]: UsersState;
}

export const usersInitialState: UsersState = {
  list: [],
  loaded: false
};

export function usersReducer(
  state: UsersState = usersInitialState,
  action: UsersAction
): UsersState {
  switch (action.type) {
    case UsersActionTypes.LoadUsersSuccess: {
      state = setLoadSuccessState(state, action);
      break;
    }

    case UsersActionTypes.LoadSingleUsersuccess: {
      state = setLoadSingleSuccessState(state, action);
      break;
    }

    case UsersActionTypes.SaveUsersuccess: {
      state = setSaveState(state, action);
      break;
    }

    case UsersActionTypes.SelectUsers: {
      state = setSelectedState(state, action);
      break;
    }

    case UsersActionTypes.DeleteUsersSuccess: {
      state = setDeleteSelectedState(state, action);
      break;
    }

    case UsersActionTypes.ResetSavedUser: {
      state = setResetSavedState(state, action);
      break;
    }

    case UsersActionTypes.ResetDeletedUser: {
      state = setResetDeletedState(state, action);
      break;
    }
    case UsersActionTypes.GenerateChangePasswordLinkSuccess: {
      state = {
        ...state,
        changePasswordLinkGenerated: action.payload,
        changePasswordLinkValid: false
      };
      break;
    }

    case UsersActionTypes.ValidateChangePasswordLinkSuccess: {
      state = {
        ...state,
        changePasswordLinkValid: action.payload
      };
      break;
    }

    case UsersActionTypes.ResetSavedUser: {
      state = {
        ...state,
        saved: false
      };
      break;
    }

    case UsersActionTypes.ChangePasswordSuccess: {
      state = {
        ...state,
        passwordChanged: action.payload
      };
      break;
    }
  }
  return state;
}
