import { Injectable } from '@angular/core';
import { NgrxFacadeDAO } from '@doe/client/dao';
import { APIFindOptions, ApiQueryResponse, Store } from '@doe/types';
import { Store as NgrxStore } from '@ngrx/store';
import { Observable } from 'rxjs';

import {
  DeleteStores,
  DeleteSelectedStores,
  LoadStores,
  LoadSingleStore,
  SaveStore,
  SelectStores,
  CreateCSV
} from './stores.actions';
import { StoresPartialState } from './stores.reducer';
import { storesQuery } from './stores.selectors';

@Injectable()
export class StoresFacade implements NgrxFacadeDAO<Store> {
  constructor(private store: NgrxStore<StoresPartialState>) {}

  results$: Observable<ApiQueryResponse<Store>> = this.store.select(
    storesQuery.featureSelector
  );
  loaded$: Observable<boolean> = this.store.select(storesQuery.getLoaded);
  single$?: Observable<Store> = this.store.select(storesQuery.getSingle);
  singleLoaded$?: Observable<boolean> = this.store.select(
    storesQuery.getSingleLoaded
  );
  error$?: Observable<any> = this.store.select(storesQuery.getError);
  deleteSelected$?: Observable<any> = this.store.select(storesQuery.getDeleted);
  selected$: Observable<Store[]> = this.store.select(storesQuery.getSelected);
  saved$: Observable<boolean> = this.store.select(storesQuery.getSaved);
  csvCreated$: Observable<boolean> = this.store.select(storesQuery.csvCreated);

  load(conditions?: any, options?: APIFindOptions) {
    this.store.dispatch(new LoadStores({ conditions, options }));
  }

  loadSingle(conditions: any) {
    this.store.dispatch(new LoadSingleStore({ conditions }));
  }

  save(data: Store) {
    this.store.dispatch(new SaveStore(data));
  }

  delete(id: any) {
    this.store.dispatch(new DeleteStores({ id }));
  }

  deleteSelected() {
    this.store.dispatch(new DeleteSelectedStores());
  }

  setSelected(selected: Store[]) {
    this.store.dispatch(new SelectStores(selected));
  }

  createCsv(conditions: any, filename: string) {
    this.store.dispatch(new CreateCSV({ conditions, filename }));
  }
}
