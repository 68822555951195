import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserResumeComponent } from './user-resume.component';
import { BasicCardModule } from '../basic-card/basic-card.module';
import {
  MatFormFieldModule,
  MatIconModule,
  MatButtonModule,
  MatInputModule,
  MatCardModule,
  MatTooltipModule,
  MatCheckboxModule,
  MatSelectModule,
  MatDividerModule
} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';

const MATERIAL_MODULES = [
  MatCardModule,
  MatButtonModule,
  MatIconModule,
  MatTooltipModule,
  MatFormFieldModule,
  MatInputModule,
  MatCheckboxModule,
  MatSelectModule,
  MatDividerModule
];

@NgModule({
  declarations: [UserResumeComponent],
  imports: [
    CommonModule,
    BasicCardModule,
    FlexLayoutModule,
    TranslateModule,
    ReactiveFormsModule,
    ...MATERIAL_MODULES
  ],
  exports: [UserResumeComponent]
})
export class UserResumeModule {}
