import { Component, Input, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormGroup,
  FormGroupDirective
} from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { CountriesService, Country } from '../countries.service';

@Component({
  selector: 'doe-countries-autocomplete',
  templateUrl: './countries-autocomplete.component.html',
  styleUrls: ['./countries-autocomplete.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class CountriesAutocompleteComponent implements OnInit {
  @Input() controlName: string;
  @Input() placeholder;
  @Input() required = false;
  @Input() errorStateMatcher: any;
  @Input() appearance: string;

  parentForm: FormGroup;
  countries: Country[];
  countries$: Observable<Country[]>;

  constructor(
    private parent: FormGroupDirective,
    private countriesService: CountriesService
  ) {
    this.countries = this.countriesService.filter();
  }

  ngOnInit() {
    this.parentForm = this.parent.form;
    const countryControl = this.parentForm.get('country');

    this.countries$ = countryControl.valueChanges.pipe(
      startWith(''),
      map(searchText =>
        searchText ? this.filterCountries(searchText) : this.countries.slice()
      )
    );
  }

  displayFn(cca2: string): string {
    return this.countriesService.getNameByCountryCode(cca2);
  }

  filterCountries(searchText): Country[] {
    return this.countries.filter(
      country =>
        this.getName(country.cca2)
          .toLowerCase()
          .indexOf(searchText.toLowerCase()) === 0
    );
  }

  getName(cca2) {
    return this.countriesService.getNameByCountryCode(cca2);
  }
}
