import { Component, OnDestroy } from '@angular/core';
import { AuthFacade, PermissionsFacade } from '@doe/client/ngrx';
import { Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { HeaderLink } from '@doe/client/core';

@Component({
  selector: 'doe-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  private headerLinks = [
    new HeaderLink({
      title: 'Header.AdminAppTitle',
      url: '/admin',
      visible$: this.permissionsFacade.isAdmin$.pipe(map(isAdmin => isAdmin))
    }),
    new HeaderLink({
      title: 'Header.StoreAppTitle',
      url: '/store',
      visible$: this.permissionsFacade.isAdmin$.pipe(map(isAdmin => !isAdmin))
    })
  ];

  links$: Observable<any> = this.authFacade.isAuthenticated$.pipe(
    map(authenticated =>
      this.headerLinks.filter(link => authenticated || link.public)
    )
  );

  private permsSub: Subscription;

  constructor(
    private authFacade: AuthFacade,
    private permissionsFacade: PermissionsFacade
  ) {
    this.permsSub = this.authFacade.isAuthenticated$.subscribe(authenticated =>
      authenticated ? this.permissionsFacade.load() : ''
    );
  }

  ngOnDestroy() {
    if (this.permsSub) {
      this.permsSub.unsubscribe();
    }
  }
}
