import { Process } from '@doe/types';

import {
  DeleteAction,
  DeleteSelectedAction,
  DeleteSuccessAction,
  LoadAction,
  LoadSingleAction,
  LoadSingleSuccessAction,
  LoadSuccessAction,
  ResetSavedAction,
  SaveAction,
  SelectAction,
  StateErrorAction,
  ResetDeletedAction
} from '../../abstract/actions';

export enum ProcessesActionTypes {
  LoadProcesses = '[Processes] Load Processes',
  LoadProcessesSuccess = '[Processes] Processes Loaded',
  ProcessesError = '[Processes] Processes Load Error',
  LoadSingleProcess = '[Processes] Load single process',
  LoadSingleProcessSuccess = '[Processes] Load single process success',
  SaveProcess = '[Proceses] Save process',
  SaveProcessSuccess = '[Proceses] Save process success',
  SelectProcesses = '[Processes] Select processes',
  DeleteSelectedProcesses = '[Processes] Delete selected',
  DeleteProcesses = '[Processes] Delete processes based on conditions',
  DeleteProcessesSuccess = '[Processes] Delete processes success',
  ResetSavedProcess = '[Processes] Reset saved state',
  ResetDeletedProcess = '[Processes] Reset deleted state'
}

export class ProcessesError extends StateErrorAction {
  readonly type = ProcessesActionTypes.ProcessesError;
}

export class LoadProcesses extends LoadAction {
  readonly type = ProcessesActionTypes.LoadProcesses;
}

export class LoadProcessesSuccess extends LoadSuccessAction<Process> {
  readonly type = ProcessesActionTypes.LoadProcessesSuccess;
}

export class LoadSingleProcess extends LoadSingleAction<Process> {
  readonly type = ProcessesActionTypes.LoadSingleProcess;
}

export class LoadSingleProcessSuccess extends LoadSingleSuccessAction<Process> {
  readonly type = ProcessesActionTypes.LoadSingleProcessSuccess;
}

export class SaveProcess extends SaveAction<Process> {
  readonly type = ProcessesActionTypes.SaveProcess;
}

export class SaveProcessSuccess extends SaveAction<Process> {
  readonly type = ProcessesActionTypes.SaveProcessSuccess;
}

export class SelectProcesses extends SelectAction<Process> {
  readonly type = ProcessesActionTypes.SelectProcesses;
}

export class DeleteSelectedProcesses extends DeleteSelectedAction {
  readonly type = ProcessesActionTypes.DeleteSelectedProcesses;
}

export class DeleteProcesses extends DeleteAction {
  readonly type = ProcessesActionTypes.DeleteProcesses;
}

export class DeleteProcessesSuccess extends DeleteSuccessAction {
  readonly type = ProcessesActionTypes.DeleteProcessesSuccess;
}

export class ResetSavedProcess extends ResetSavedAction {
  readonly type = ProcessesActionTypes.ResetSavedProcess;
}

export class ResetDeletedProcess extends ResetDeletedAction {
  readonly type = ProcessesActionTypes.ResetDeletedProcess;
}

export type ProcessesAction =
  | LoadProcesses
  | LoadProcessesSuccess
  | ProcessesError
  | LoadSingleProcess
  | LoadSingleProcessSuccess
  | SaveProcess
  | SaveProcessSuccess
  | SelectProcesses
  | DeleteSelectedProcesses
  | DeleteProcesses
  | DeleteProcessesSuccess
  | ResetSavedProcess
  | ResetDeletedProcess;

export const fromProcessesActions = {
  LoadProcesses,
  LoadProcessesSuccess,
  ProcessesError,
  LoadSingleProcess,
  LoadSingleProcessSuccess,
  SaveProcess,
  SaveProcessSuccess,
  SelectProcesses,
  DeleteSelectedProcesses,
  DeleteProcesses,
  DeleteProcessesSuccess,
  ResetSavedProcess,
  ResetDeletedProcess
};
