import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators
} from '@angular/forms';
import { AuthFacade, UsersFacade } from '@doe/client/ngrx';
import { User } from '@doe/types';
import { SnackService } from '../../snack/snack.service';
import { skip, take } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'doe-user-resume',
  templateUrl: './user-resume.component.html',
  styleUrls: ['./user-resume.component.scss']
})
export class UserResumeComponent implements OnInit, OnDestroy {
  loggedUserId: string;
  user: User;
  form: FormGroup;

  authSub: Subscription;
  userSub: Subscription;
  saveSub: Subscription;
  linkSub: Subscription;

  constructor(
    private usersFacade: UsersFacade,
    private authFacade: AuthFacade,
    private fb: FormBuilder,
    private snack: SnackService
  ) {
    this.form = this.fb.group({
      _id: '',
      name: '',
      email: '',
      company: '',
      store: ''
    });
    this.authSub = this.authFacade.user$.subscribe(user => {
      this.loggedUserId = user._id;
    });
    this.usersFacade.load({ _id: this.loggedUserId });
    this.userSub = this.usersFacade.results$
      .pipe(take(2))
      .subscribe(results => {
        if (results && results.list.length > 0) {
          this.user = results.list[0];
          this.form.patchValue(this.user);
        }
      });
  }
  ngOnInit() {}

  ngOnDestroy() {
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
    if (this.linkSub) {
      this.linkSub.unsubscribe();
    }
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
    if (this.saveSub) {
      this.saveSub.unsubscribe();
    }
  }

  changePsw() {
    this.usersFacade.generateChangePasswordLink(this.loggedUserId);
    this.linkSub = this.usersFacade.changePasswordLinkGenerated$.subscribe(
      link => {
        if (link && link.success === true) {
          this.snack
            .open('An email has been sent to you with a change password link .')
            .subscribe();
        }
      }
    );
  }

  save() {
    // if (this.form.valid) {
    //   this.usersFacade.save(this.form.value);
    //   this.saveSub = this.usersFacade.saved$.pipe(skip(1)).subscribe(saved => {
    //     if (saved === true) {
    //       this.snack.open('Your informations has been saved .').subscribe();
    //     }
    //   })
    // }
  }
}
