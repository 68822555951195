import { Injectable } from '@angular/core';
import { StoresService } from '@doe/client/dao';
import { ApiQueryResponse, Store } from '@doe/types';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { AbstractEffects } from '../../abstract/effects';
import {
  DeleteStoresSuccess,
  DeleteSelectedStores,
  LoadStores,
  LoadStoresSuccess,
  LoadSingleStore,
  LoadSingleStoresuccess,
  StoresActionTypes,
  StoresError,
  ResetDeletedStore,
  ResetSavedStore,
  SaveStore,
  SaveStoresuccess,
  CreateCSV,
  CSVCreated
} from './stores.actions';
import { STORES_FEATURE_KEY, StoresPartialState } from './stores.reducer';
import { map } from 'rxjs/operators';

@Injectable()
export class StoresEffects extends AbstractEffects<StoresPartialState, Store> {
  constructor(
    protected actions$: Actions,
    protected dataPersistence: DataPersistence<StoresPartialState>,
    protected StoresService: StoresService
  ) {
    super(actions$, dataPersistence, StoresService, STORES_FEATURE_KEY);
  }

  @Effect()
  load$ = this.loadEffect(
    StoresActionTypes.LoadStores,
    (
      action: LoadStores,
      state: StoresPartialState,
      res: ApiQueryResponse<Store>
    ) => new LoadStoresSuccess(res),
    (action: LoadStores, error) => new StoresError(error)
  );

  @Effect()
  loadSingle$ = this.loadSingleEffect(
    StoresActionTypes.LoadSingleStore,
    (action: LoadSingleStore, state: StoresPartialState, Store: Store) =>
      new LoadSingleStoresuccess(Store),
    (action: LoadSingleStore, error) => new StoresError(error)
  );

  @Effect()
  save$ = this.saveEffect(
    StoresActionTypes.SaveStore,
    (action: SaveStore, state: StoresPartialState, Store: Store) =>
      new SaveStoresuccess(Store),
    (action: SaveStore, error) => new StoresError(error)
  );

  @Effect()
  saveSuccess$ = this.saveSuccessEffect(
    StoresActionTypes.SaveStoresuccess,
    (action: SaveStoresuccess) => new ResetSavedStore(),
    (action: SaveStoresuccess, error) => new StoresError(error)
  );

  @Effect()
  deleteSelected$ = this.deleteSelectedEffect(
    StoresActionTypes.DeleteSelectedStores,
    (action: DeleteSelectedStores, state: StoresPartialState, res) =>
      new DeleteStoresSuccess(),
    (action: DeleteSelectedStores, error) => new StoresError(error)
  );

  @Effect()
  delete$ = this.deleteEffect(
    StoresActionTypes.DeleteStores,
    (action: DeleteSelectedStores, state: StoresPartialState, res) =>
      new DeleteStoresSuccess(),
    (action: DeleteSelectedStores, error) => new StoresError(error)
  );

  // @Effect()
  // deleteSuccess$ = this.deleteSuccessEffect(
  //   StoresActionTypes.DeleteStoresSuccess,
  //   (action: DeleteStoresSuccess) => new ResetDeletedStore(),
  //   (action: DeleteStoresSuccess, error) => new StoresError(error)
  // );

  @Effect()
  createCsv$ = this.dataPersistence.fetch(StoresActionTypes.CreateCSV, {
    run: (action: CreateCSV, state: StoresPartialState) => {
      return this.StoresService.createCsv(
        action.payload.conditions,
        action.payload.filename
      ).pipe(map(res => new CSVCreated()));
    },
    onError: (action: CreateCSV, error) => {
      console.log('Error', error);
      return new StoresError(error);
    }
  });
}
