import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { AuthPartialState } from './auth.reducer';
import { authQuery } from './auth.selectors';
import { Login, Logout, LoadCompany, LoadStore, Load } from './auth.actions';

@Injectable()
export class AuthFacade {
  constructor(private store: Store<AuthPartialState>) {}

  auth$ = this.store.pipe(select(authQuery.getAuthState));
  isAuthenticated$ = this.store.pipe(select(authQuery.getIsAuthenticated));
  error$ = this.store.pipe(select(authQuery.getError));
  user$ = this.store.pipe(select(authQuery.getUser));
  company$ = this.store.pipe(select(authQuery.getCompany));

  load() {
    this.store.dispatch(new Load());
  }

  login(credentials) {
    this.store.dispatch(new Login(credentials));
  }

  logout() {
    this.store.dispatch(new Logout());
  }

  loadCompany() {
    this.store.dispatch(new LoadCompany());
  }

  loadStore() {
    this.store.dispatch(new LoadStore());
  }
}
