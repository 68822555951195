import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getName } from 'i18n-iso-countries';

import COUNTRIES from './countries';

export interface Country {
  name?: {
    common?: string;
    official?: string;
    native?: {
      nld?: {
        official?: string;
        common?: string;
      };
      pap?: {
        official?: string;
        common?: string;
      };
    };
  };
  tld?: string[];
  cca2?: string;
  ccn3?: string;
  cca3?: string;
  cioc?: string;
  independent?: boolean;
  status?: string;
  currency?: string[];
  callingCode?: string[];
  capital?: string[];
  altSpellings?: string[];
  region?: string;
  subregion?: string;
  languages?: {
    nld?: string;
    pap?: string;
  };
  translations?: {
    [langCode: string]: {
      official: string;
      common: string;
    };
  };
  latlng?: number[];
  demonym?: string;
  landlocked?: boolean;
  borders?: string[];
  area?: number;
  flag?: string;
}

@Injectable({
  providedIn: 'root'
})
export class CountriesService {
  private get countries(): Country[] {
    return <Country[]>COUNTRIES;
  }

  constructor(private translate: TranslateService) {}

  filter(searchText?: string): Country[] {
    const re = new RegExp(searchText || '', 'gi');
    return this.countries.filter(country => {
      const name = this.getCountryNameForCurrentLang(country);
      return re.test(name) || re.test(country.cca2);
    });
  }

  findByCountryCode(cca2: string): Country {
    return this.countries.find(
      c => c.cca2.toLowerCase() === cca2.toLowerCase()
    );
  }

  getNameByCountryCode(cca2: string): string {
    if (!cca2) {
      return undefined;
    }
    const country = this.findByCountryCode(cca2);
    return this.getCountryNameForCurrentLang(country);
  }

  getCountryFlag(cca2: string) {
    const country = this.findByCountryCode(cca2);
    return country.flag;
  }

  private getCountryNameForCurrentLang(country: Country) {
    if (!country) {
      return '';
    }

    if (country.cca2 === 'n.a.') {
      return 'n.a.';
    }

    let name: string;
    try {
      const lang = this.translate.currentLang || 'en';
      switch (lang) {
        case 'jp':
          name = getName(country.cca2, 'ja');
          break;
        default:
          name = getName(country.cca2, lang);
          break;
      }
    } catch (err) {
      name = country.name.common;
    }

    return name;
  }
}
