import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  OnDestroy,
  OnChanges
} from '@angular/core';

import { FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';

import { ObservableMedia } from '@angular/flex-layout';
import { Subscription, Observable } from 'rxjs';
import { FieldBase, FieldControlType, StandardCustomerKeys } from '@doe/types';
import { FormsService } from '@doe/client/dao';

export class FieldErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'doe-dynamic-field',
  templateUrl: './dynamic-field.component.html',
  styleUrls: ['./dynamic-field.component.scss']
})
export class DynamicFieldComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() field: FieldBase<any>;
  @Input() form: FormGroup;
  @Input() highlight: boolean;
  @Input() lang: string;
  @Input() appearance = 'outline';
  @Input() preview = false;
  @Output() fieldCheckedChange = new EventEmitter<Object>();

  @ViewChild(SignaturePad) signaturePad: SignaturePad;

  matcher = new FieldErrorStateMatcher();
  mediaSub: Subscription;
  signaturePadOptions = {
    minWidth: 1,
    canvasWidth: 700,
    canvasHeight: 40
  };

  get standardCustomerKeys() {
    return Object.keys(StandardCustomerKeys);
  }
  get isValid() {
    return (
      this.standardCustomerKeys.includes(this.field.key) ||
      this.field.type === FieldControlType.Static
    );
  }

  private signatureValSub: Subscription;

  constructor(
    private translate: TranslateService,
    private mediaObserver: ObservableMedia,
    private formsService: FormsService
  ) {}

  ngOnInit() {
    if (!this.lang) {
      this.lang = this.translate.currentLang || 'en';
    }

    if (this.form) {
      const sign = this.form.get('signature');
      if (sign) {
        this.signatureValSub = sign.valueChanges.subscribe(val => {
          if (!val && this.signaturePad) {
            this.signaturePad.clear();
          }
        });
      }
    }
  }

  ngAfterViewInit() {
    if (this.field.controlType === 'Signature' && this.signaturePad) {
      this.signaturePad.clear();
      this.signaturePad.set('canvasHeight', 200);
      this.mediaSub = this.mediaObserver.subscribe(media => {
        if (media.mqAlias === 'xs') {
          this.signaturePad.set('canvasWidth', 320);
        } else if (media.mqAlias === 'sm') {
          this.signaturePad.set('canvasWidth', 620);
        } else {
          this.signaturePad.set('canvasWidth', 1200);
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.signatureValSub) {
      this.signatureValSub.unsubscribe();
    }
  }

  ngOnChanges(changes) {
    if (changes.form.currentValue) {
      if (!changes.form.currentValue.value.signature && this.signaturePad) {
        this.signaturePad.clear();
      }
    }
  }

  drawComplete() {
    const signature = this.signaturePad.toDataURL();
    this.form.get(this.field.key).setValue(signature);
  }

  drawStart() {
    // console.log('begin drawing');
  }
}
