import { Company } from '@doe/types';

import {
  DeleteAction,
  DeleteSelectedAction,
  DeleteSuccessAction,
  LoadAction,
  LoadSingleAction,
  LoadSingleSuccessAction,
  LoadSuccessAction,
  ResetSavedAction,
  SaveAction,
  SelectAction,
  StateErrorAction,
  ResetDeletedAction
} from '../../abstract/actions';

export enum CompaniesActionTypes {
  LoadCompanies = '[Companies] Load Companies',
  LoadCompaniesSuccess = '[Companies] Companies Loaded',
  CompaniesError = '[Companies] Companies Load Error',
  LoadSingleCompany = '[Companies] Load single Company',
  LoadSingleCompaniesuccess = '[Companies] Load single Company success',
  SaveCompany = '[Proceses] Save Company',
  SaveCompaniesuccess = '[Proceses] Save Company success',
  SelectCompanies = '[Companies] Select Companies',
  DeleteSelectedCompanies = '[Companies] Delete selected',
  DeleteSelectedCompaniesSuccess = '[Companies] Delete selected success',
  DeleteCompanies = '[Companies] Delete Companies based on conditions',
  DeleteCompaniesSuccess = '[Companies] Delete Companies success',
  ResetSavedCompany = '[Companies] Reset saved state',
  ResetDeletedCompany = '[Companies] Reset deleted state'
}

export class CompaniesError extends StateErrorAction {
  readonly type = CompaniesActionTypes.CompaniesError;
}

export class LoadCompanies extends LoadAction {
  readonly type = CompaniesActionTypes.LoadCompanies;
}

export class LoadCompaniesSuccess extends LoadSuccessAction<Company> {
  readonly type = CompaniesActionTypes.LoadCompaniesSuccess;
}

export class LoadSingleCompany extends LoadSingleAction<Company> {
  readonly type = CompaniesActionTypes.LoadSingleCompany;
}

export class LoadSingleCompaniesuccess extends LoadSingleSuccessAction<
  Company
> {
  readonly type = CompaniesActionTypes.LoadSingleCompaniesuccess;
}

export class SaveCompany extends SaveAction<Company> {
  readonly type = CompaniesActionTypes.SaveCompany;
}

export class SaveCompaniesuccess extends SaveAction<Company> {
  readonly type = CompaniesActionTypes.SaveCompaniesuccess;
}

export class SelectCompanies extends SelectAction<Company> {
  readonly type = CompaniesActionTypes.SelectCompanies;
}

export class DeleteSelectedCompanies extends DeleteSelectedAction {
  readonly type = CompaniesActionTypes.DeleteSelectedCompanies;
}

export class DeleteCompanies extends DeleteAction {
  readonly type = CompaniesActionTypes.DeleteCompanies;
}

export class DeleteCompaniesSuccess extends DeleteSuccessAction {
  readonly type = CompaniesActionTypes.DeleteCompaniesSuccess;
}

export class ResetSavedCompany extends ResetSavedAction {
  readonly type = CompaniesActionTypes.ResetSavedCompany;
}

export class ResetDeletedCompany extends ResetDeletedAction {
  readonly type = CompaniesActionTypes.ResetDeletedCompany;
}

export type CompaniesAction =
  | LoadCompanies
  | LoadCompaniesSuccess
  | CompaniesError
  | LoadSingleCompany
  | LoadSingleCompaniesuccess
  | SaveCompany
  | SaveCompaniesuccess
  | SelectCompanies
  | DeleteSelectedCompanies
  | DeleteCompanies
  | DeleteCompaniesSuccess
  | ResetSavedCompany
  | ResetDeletedCompany;

export const fromCompaniesActions = {
  LoadCompanies,
  LoadCompaniesSuccess,
  CompaniesError,
  LoadSingleCompany,
  LoadSingleCompaniesuccess,
  SaveCompany,
  SaveCompaniesuccess,
  SelectCompanies,
  DeleteSelectedCompanies,
  DeleteCompanies,
  DeleteCompaniesSuccess,
  ResetSavedCompany,
  ResetDeletedCompany
};
