import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AUTH_FEATURE_KEY, AuthState } from './auth.reducer';

// Lookup the 'Auth' feature state managed by NgRx
const getAuthState = createFeatureSelector<AuthState>(AUTH_FEATURE_KEY);

const getIsAuthenticated = createSelector(
  getAuthState,
  (state: AuthState) => state.isAuthenticated
);

const getError = createSelector(
  getAuthState,
  (state: AuthState) => state.error
);

const getUser = createSelector(
  getAuthState,
  (state: AuthState) => state.user
);

const getCompany = createSelector(
  getAuthState,
  getIsAuthenticated,
  (state: AuthState, isAuthenticated) =>
    isAuthenticated ? state.company : undefined
);

export const authQuery = {
  getError,
  getAuthState,
  getIsAuthenticated,
  getUser,
  getCompany
};
