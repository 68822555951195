import { ModuleWithProviders, NgModule } from '@angular/core';
import { ExternalUrlRoutingModule } from '../../external-url-routing.module';
import { AuthFacade } from '@doe/client/ngrx';
import { SnackModule } from '../../snack/snack.module';

export class AuthGuardConfig {
  loginRouterLink = '/login';
  external?: boolean;
  localStorageAuthTokenName = 'doe-token';
}

@NgModule({
  imports: [ExternalUrlRoutingModule.forRoot(), SnackModule]
})
export class AuthGuardModule {
  static forRoot(config: AuthGuardConfig): ModuleWithProviders {
    return {
      ngModule: AuthGuardModule,
      providers: [{ provide: AuthGuardConfig, useValue: config }, AuthFacade]
    };
  }

  static forChild(config?: AuthGuardConfig): ModuleWithProviders {
    return {
      ngModule: AuthGuardModule,
      providers: [
        { provide: AuthGuardConfig, useValue: config || new AuthGuardConfig() },
        AuthFacade
      ]
    };
  }
}
