import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatDividerModule,
  MatCardModule,
  MatIconModule,
  MatFormFieldModule,
  MatSelectModule,
  MatOptionModule,
  MatProgressSpinnerModule,
  MatListModule,
  MatInputModule,
  MatButtonModule
} from '@angular/material';
import { CatalogSearchComponent } from './catalog-search.component';
import { BasicCardModule } from '../basic-card/basic-card.module';
import { BasicSearchModule } from '../basic-search/basic-search.module';
import { BasicTableModule } from '../basic-table/basic-table.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import {
  NgrxApplicationLogsModule,
  NgrxCompaniesModule,
  NgrxCustomersModule,
  NgrxUsersModule,
  NgrxStoresModule,
  NgrxSkusModule
} from '@doe/client/ngrx';
import { ReactiveFormsModule } from '@angular/forms';
import { ScrollDispatchModule } from '@angular/cdk/scrolling';
const MATERIAL_MODULES = [
  MatDividerModule,
  MatCardModule,
  MatIconModule,
  MatFormFieldModule,
  MatSelectModule,
  MatOptionModule,
  MatProgressSpinnerModule,
  MatListModule,
  MatInputModule,
  MatButtonModule
];
@NgModule({
  declarations: [CatalogSearchComponent],
  imports: [
    CommonModule,
    ScrollDispatchModule,
    BasicCardModule,
    BasicSearchModule,
    BasicTableModule,
    NgrxStoresModule,
    NgrxUsersModule,
    NgrxCustomersModule,
    NgrxCompaniesModule,
    NgrxSkusModule,
    NgrxApplicationLogsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    TranslateModule,
    ...MATERIAL_MODULES
  ]
})
export class CatalogSearchModule {}
