import { Injectable } from '@angular/core';
import { NgrxFacadeDAO } from '@doe/client/dao';
import { APIFindOptions, ApiQueryResponse, Store, Form } from '@doe/types';
import { Store as NgrxStore } from '@ngrx/store';
import { Observable } from 'rxjs';

import {
  DeleteForms,
  DeleteSelectedForms,
  LoadForms,
  LoadSingleForm,
  SaveForm,
  SelectForms
} from './forms.actions';
import { FormsPartialState } from './forms.reducer';
import { formsQuery } from './forms.selectors';

@Injectable()
export class FormsFacade implements NgrxFacadeDAO<Form> {
  constructor(private Form: NgrxStore<FormsPartialState>) {}

  results$: Observable<ApiQueryResponse<Form>> = this.Form.select(
    formsQuery.featureSelector
  );
  loaded$: Observable<boolean> = this.Form.select(formsQuery.getLoaded);
  single$?: Observable<Form> = this.Form.select(formsQuery.getSingle);
  singleLoaded$?: Observable<boolean> = this.Form.select(
    formsQuery.getSingleLoaded
  );
  error$?: Observable<any> = this.Form.select(formsQuery.getError);
  deleteSelected$?: Observable<any> = this.Form.select(formsQuery.getDeleted);
  selected$: Observable<Form[]> = this.Form.select(formsQuery.getSelected);
  saved$: Observable<boolean> = this.Form.select(formsQuery.getSaved);
  csvCreated$: Observable<boolean> = this.Form.select(formsQuery.csvCreated);

  load(conditions?: any, options?: APIFindOptions) {
    this.Form.dispatch(new LoadForms({ conditions, options }));
  }

  loadSingle(conditions: any) {
    this.Form.dispatch(new LoadSingleForm({ conditions }));
  }

  save(data: Form) {
    this.Form.dispatch(new SaveForm(data));
  }

  delete(id: any) {
    this.Form.dispatch(new DeleteForms({ id }));
  }

  deleteSelected() {
    this.Form.dispatch(new DeleteSelectedForms());
  }

  setSelected(selected: Form[]) {
    this.Form.dispatch(new SelectForms(selected));
  }
}
