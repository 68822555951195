import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { PermissionsEffects } from './+state/permissions.effects';
import {
  permissionsInitialState,
  permissionsReducer
} from './+state/permissions.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forRoot(
      { auth: permissionsReducer },
      { initialState: { auth: permissionsInitialState } }
    ),
    EffectsModule.forRoot([PermissionsEffects])
  ]
})
export class PermissionsRootStoreModule {}
