import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  STORES_FEATURE_KEY,
  storesInitialState,
  storesReducer
} from './+state/stores.reducer';
import { StoresEffects } from './+state/stores.effects';
import { StoresFacade } from './+state/stores.facade';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(STORES_FEATURE_KEY, storesReducer, {
      initialState: storesInitialState
    }),
    EffectsModule.forFeature([StoresEffects])
  ],
  providers: [StoresFacade]
})
export class NgrxStoresModule {}
