import { Injectable, Optional } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { AuthFacade, AuthState } from '@doe/client/ngrx';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { AuthGuardConfig } from './auth-guard.module';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authFacade: AuthFacade,
    @Optional() private config: AuthGuardConfig
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authFacade.auth$.pipe(
      map((auth: AuthState) => {
        if (!auth.isAuthenticated) {
          if (this.config && this.config.loginRouterLink) {
            if (this.config.external) {
              this.router.navigate([
                '/externalUrlRedirect',
                { externalUrl: this.config.loginRouterLink }
              ]);
            } else {
              this.router.navigate([this.config.loginRouterLink]);
            }
          } else {
            this.router.navigate(['/login']);
          }
        } else {
          if (auth.user && !!auth.user.needPasswordChange) {
            this.router.navigate([`/change-password`]);
            return false;
          }
        }
        return auth.isAuthenticated;
      })
    );
  }
}
