import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User, TemporaryLink } from '@doe/types';

import { DAOService } from '../dao-service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TemporaryLinksService extends DAOService<TemporaryLink> {
  constructor(http: HttpClient) {
    super(http, '/api/temporarylinks');
  }

  validateChangePasswordLink(id: any): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseUrl}/${id}/validate`);
  }
}
