import { Customer } from '@doe/types';

import {
  AbstractState,
  setDeleteSelectedState,
  setLoadSingleSuccessState,
  setLoadSuccessState,
  setSaveState,
  setSelectedState,
  setResetSavedState,
  setResetDeletedState
} from '../../abstract/reducers';
import { CustomersAction, CustomersActionTypes } from './customers.actions';

export const CUSTOMERS_FEATURE_KEY = 'Customers';

export interface CustomersState extends AbstractState<Customer> {
  csvCreated?: boolean;
}

export interface CustomersPartialState {
  readonly [CUSTOMERS_FEATURE_KEY]: CustomersState;
}

export const customersInitialState: CustomersState = {
  list: [],
  loaded: false
};

export function customersReducer(
  state: CustomersState = customersInitialState,
  action: CustomersAction
): CustomersState {
  switch (action.type) {
    case CustomersActionTypes.LoadCustomersSuccess: {
      state = setLoadSuccessState(state, action);
      break;
    }

    case CustomersActionTypes.LoadSingleCustomerSuccess: {
      state = setLoadSingleSuccessState(state, action);
      break;
    }

    case CustomersActionTypes.SaveCustomerSuccess: {
      state = setSaveState(state, action);
      break;
    }

    case CustomersActionTypes.SelectCustomers: {
      state = setSelectedState(state, action);
      break;
    }

    case CustomersActionTypes.DeleteCustomersSuccess: {
      state = setDeleteSelectedState(state, action);
      break;
    }

    case CustomersActionTypes.ResetSavedCustomer: {
      state = setResetSavedState(state, action);
      break;
    }

    case CustomersActionTypes.ResetDeletedCustomer: {
      state = setResetDeletedState(state, action);
      break;
    }

    case CustomersActionTypes.CSVCreated: {
      state = {
        ...state,
        csvCreated: true
      };
      break;
    }
  }
  return state;
}
