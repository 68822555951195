import { createFeatureSelector, createSelector } from '@ngrx/store';

import { createDefaultQuerySelectors } from '../../abstract/selectors';
import { SKUS_FEATURE_KEY, SkusState } from './skus.reducer';

const getSkusState = createFeatureSelector<SkusState>(SKUS_FEATURE_KEY);

const csvCreated = createSelector(
  getSkusState,
  (state: SkusState) => state.csvCreated
);

export const skusQuery = createDefaultQuerySelectors(getSkusState);

skusQuery.csvCreated = csvCreated;
