import { createFeatureSelector, createSelector } from '@ngrx/store';

import { createDefaultQuerySelectors } from '../../abstract/selectors';
import { FORMS_FEATURE_KEY, FormsState } from './forms.reducer';
import { customersQuery } from '../../customers/+state/customers.selectors';

const getFormsState = createFeatureSelector<FormsState>(FORMS_FEATURE_KEY);

const csvCreated = createSelector(
  getFormsState,
  (state: FormsState) => state.csvCreated
);

export const formsQuery = createDefaultQuerySelectors(getFormsState);

formsQuery.csvCreated = csvCreated;
