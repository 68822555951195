import { APIFindOptions, ApiQueryResponse } from '@doe/types';
import { Action } from '@ngrx/store';

abstract class AbstractAction implements Action {
  abstract readonly type;
}

export abstract class LoadAction extends AbstractAction {
  constructor(public payload: { conditions: any; options: APIFindOptions }) {
    super();
  }
}

export abstract class StateErrorAction extends AbstractAction {
  constructor(public payload: any) {
    super();
  }
}

export abstract class LoadSuccessAction<T> extends AbstractAction {
  constructor(public payload: ApiQueryResponse<T>) {
    super();
  }
}

export abstract class LoadSingleAction<T> extends AbstractAction {
  constructor(public payload: { conditions: any }) {
    super();
  }
}

export abstract class LoadSingleSuccessAction<T> extends AbstractAction {
  constructor(public payload: T) {
    super();
  }
}

export abstract class SaveAction<T> extends AbstractAction {
  constructor(public payload: T) {
    super();
  }
}

export abstract class SaveSuccessAction<T> extends AbstractAction {
  constructor(public payload: T) {
    super();
  }
}

export abstract class SelectAction<T> extends AbstractAction {
  constructor(public payload: T[]) {
    super();
  }
}

export abstract class DeleteAction extends AbstractAction {
  constructor(public payload: { id: any }) {
    super();
  }
}
export abstract class DeleteSelectedAction extends AbstractAction {}
export abstract class DeleteSuccessAction extends AbstractAction {}

export abstract class ResetDeletedAction extends AbstractAction {}
export abstract class ResetSavedAction extends AbstractAction {}
