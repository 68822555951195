import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthFacade, PermissionsFacade, UsersFacade } from '@doe/client/ngrx';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'doe-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  form: FormGroup = this.fb.group({
    email: ['', Validators.required],
    password: ['', Validators.required],
    locale: this.translate.currentLang || 'en'
  });

  isAuthenticated$: Observable<boolean> = this.authFacade.isAuthenticated$;
  isAdmin$: Observable<boolean> = this.permissionsFacade.isAdmin$;

  authError$: Observable<any> = this.authFacade.error$.pipe(
    map(err => {
      if (err) {
        return err.error ? err.error.message : err.message;
      } else {
        return null;
      }
    })
  );

  private permissionsSub: Subscription;
  private authSub: Subscription;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private router: Router,
    private authFacade: AuthFacade,
    private permissionsFacade: PermissionsFacade,
    private usersFacade: UsersFacade
  ) {
    this.authSub = this.isAuthenticated$.subscribe(isAuthenticated =>
      isAuthenticated ? this.permissionsFacade.load() : null
    );
    this.permissionsSub = this.permissionsFacade.loaded$
      .pipe(
        switchMap(loaded => (loaded ? this.permissionsFacade.isAdmin$ : EMPTY))
      )
      .subscribe(admin => {
        if (admin === true) {
          this.router.navigate(['/admin']);
        } else if (admin === false) {
          this.router.navigate(['/store']);
        }
      });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.permissionsSub) {
      this.permissionsSub.unsubscribe();
    }
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
  }

  login() {
    if (this.form.valid) {
      this.authFacade.login(this.form.value);
    }
  }

  changeLang(event) {
    this.translate.use(event.value);
  }
}
