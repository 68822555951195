import {
  PermissionsAction,
  PermissionsActionTypes
} from './permissions.actions';

export const PERMISSIONS_FEATURE_KEY = 'permissions';

export interface PermissionsState {
  roles?: string[];
  permissions?: any[];
  error?: any; // last none error (if any)
  loaded?: boolean;
}

export interface PermissionsPartialState {
  readonly [PERMISSIONS_FEATURE_KEY]: PermissionsState;
}

export const permissionsInitialState: PermissionsState = {
  loaded: false
};

export function permissionsReducer(
  state: PermissionsState = permissionsInitialState,
  action: PermissionsAction
): PermissionsState {
  switch (action.type) {
    case PermissionsActionTypes.PermissionsLoaded: {
      const payload = action.payload;
      state = {
        roles: payload.roles,
        permissions: payload.permissions,
        loaded: true
      };
      break;
    }
    case PermissionsActionTypes.ResetPermissions: {
      state = {
        roles: [],
        permissions: [],
        loaded: false
      };
    }
  }
  return state;
}
