import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  CATALOGS_FEATURE_KEY,
  catalogsInitialState,
  catalogsReducer
} from './+state/catalogs.reducer';
import { CatalogsEffects } from './+state/catalogs.effects';
import { CatalogsFacade } from './+state/catalogs.facade';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(CATALOGS_FEATURE_KEY, catalogsReducer, {
      initialState: catalogsInitialState
    }),
    EffectsModule.forFeature([CatalogsEffects])
  ],
  providers: [CatalogsFacade]
})
export class NgrxCatalogsModule {}
